import React from 'react';
import { Skeleton, Divider } from 'antd';

const TopicCardSkeletonLoading = () => {

    return (
      <div style={{width: 250, margin: '0 10px',}}>
        <Skeleton.Image style={{width: 250, height: 180}}/>
        <Divider />
        <Skeleton active></Skeleton>
      </div>
    );
}

export default TopicCardSkeletonLoading