import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Form, Button, Col, Row, Checkbox, Radio, Input, Space } from "antd";
import {
  BookFilled,
  UserOutlined,
  SafetyCertificateFilled,
} from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";

const SchoolInfo = (props) => {
  // console.log("Props School >>>>", props);
  const previous = (e) => {
    props.prevStep();
  };

  const next = (e) => {
    props.nextStep();
  };

  const [optionValue, setOptionValue] = useState(1);
  const [infoState, setInfoState] = useState("isInter");

  const handleChange = (e) => {
    props.setInfoState((prev) => ({
      ...prev,
      status: e.target.value,
    }));
  };

  useEffect(() => {
    console.log("infoState", infoState);
    console.log("radio checked", optionValue);
    if (optionValue === 1) {
      return setInfoState("isInter");
    } else if (optionValue === 2) {
      return setInfoState("isJob");
    } else if (optionValue === 3) {
      return setInfoState("isMaster");
    }
  }, [optionValue, infoState]);

  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={0} className="queue-simple">
        <Form key="a" onFinish={next}>
          <h4 style={{ textAlign: "center", margin: 20 }}>
            Tell us about your current school year
          </h4>
          <Form.Item
            initialValue={props.infoState.studentGrade}
            name="grade"
            rules={[
              { required: true, message: "Please fill in this required field" },
            ]}
          >
            <Input
              maxLength={100}
              prefix={<BookFilled style={{ marginRight: 5 }} />}
              placeholder="What grade/class are you currently in?*"
              onChange={(e) =>
                props.setInfoState({
                  ...props.infoState,
                  studentGrade: e.target.value,
                })
              }
            />
          </Form.Item>

          <Form.Item
            initialValue={props.infoState.schoolName}
            name="school"
            rules={[
              { required: true, message: "Please fill in this required field" },
            ]}
          >
            <Input
              maxLength={100}
              prefix={<SafetyCertificateFilled style={{ marginRight: 5 }} />}
              placeholder="Which educational institute are you currently in?"
              onChange={(e) =>
                props.setInfoState({
                  ...props.infoState,
                  schoolName: e.target.value,
                })
              }
            />
          </Form.Item>

          <Radio.Group
            name={infoState}
            onChange={handleChange}
            defaultValue={1}
          >
            <Space direction="vertical">
              <Radio
                name={infoState}
                value={1}
                checked={props.infoState.isInter}
              >
                I have completed my Intermediate/A levels and would apply for a
                Bachelor’s degree.
              </Radio>
              <Radio name={infoState} value={2} checked={props.infoState.isJob}>
                I have completed my Bachelor’s degree and would like to apply
                for a job in future.
              </Radio>
              <Radio
                name={infoState}
                value={3}
                checked={props.infoState.isMaster}
              >
                I have completed my Bachelor’s Degree and would like to apply
                for a post graduate/Master’s degree.
              </Radio>
            </Space>
          </Radio.Group>

          {/* <Form.Item
            style={{ marginBottom: 0 }}
            name="isInter"
            // valuePropName="checked"
          >
            <Checkbox
              checked={props.infoState.isInter}
              onChange={(e) =>
                props.setInfoState({
                  ...props.infoState,
                  isInter: e.target.checked,
                })
              }
              style={{ color: "gray" }}
            >
              I have completed my Intermediate/A levels and would apply for a
              Bachelor’s degree.
            </Checkbox>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0 }}
            name="forJob"
            // valuePropName="checked"
          >
            <Checkbox
              checked={props.infoState.isJob}
              onChange={(e) =>
                props.setInfoState({
                  ...props.infoState,
                  isJob: e.target.checked,
                })
              }
              style={{ color: "gray" }}
            >
              I have completed my Bachelor’s degree and would like to apply for
              a job in future.
            </Checkbox>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0 }}
            name="forMaster"
            // valuePropName="checked"
          >
            <Checkbox
              checked={props.infoState.isMaster}
              onChange={(e) =>
                props.setInfoState({
                  ...props.infoState,
                  isMaster: e.target.checked,
                })
              }
              style={{ color: "gray" }}
            >
              I have completed my Bachelor’s Degree and would like to apply for
              a post graduate/Master’s degree.
            </Checkbox>
          </Form.Item> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <Button
              size="large"
              onClick={previous}
              style={{ backgroundColor: "lightgray" }}
            >
              {" "}
              Previous
            </Button>
            <Button size="large" htmlType="submit" type="primary">
              Next{" "}
            </Button>
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default SchoolInfo;
