import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Typography, Avatar } from "antd";
import styled from "styled-components";
import ReactPlayer from "react-player";
import moment from "moment";
import "./courseCard.css";
import { CardButton } from "../components/button/cardButton";
import swal from "sweetalert";
import { useMutation, useQuery, useLazyQuery } from "react-apollo";
import Mutations from "../appollo_client/Mutations";
import Queries from "../appollo_client/Queries";
import { client } from "../appollo_client/client";
import { useDispatch, useSelector } from "react-redux";
import {
  getRegisteredCourses,
  updateRegisteredCourses,
  getCourseCardId,
} from "../redux/actions/registeredCourses";

const CardWrapper = styled(Card)`
  box-shadow: 1px 0 6px 1px #ccc;
  .ant-card-actions {
    border-radius: 10px;
  }
`;

const CoursesCard = (props) => {
  let dispatch = useDispatch();
  const { history, data, registeredCourse } = props;
  const { courseImageURL, description, courseName, _id, createdAt } =
    data.courseId;

  const courseStatus = data?.status;
  console.log("courseStatus", courseStatus);

  const courseHandle = () => {
    history.push("weekly-topics", { courseId: _id, title: courseName });
  };

  const [registeredStatus, setRegisteredStatus] = useState("");
  console.log(registeredStatus, "res");

  // const [registeredCourseData, setRegisteredCourseData] = useState();

  const [registerCourse] = useMutation(Mutations.REGISTER_COURSE, {
    client: client,
  });

  // const { data: registeredCoursesData, error: errors } = useQuery(
  //   Queries.GET_REGISTERED_COURSES,
  //   {
  //     client: client,
  //     onCompleted: () => {
  //       dispatch(updateRegisteredCourses(registeredCoursesData));
  //     },
  //     onError() {
  //       console.log(errors);
  //     },
  //   }
  // );

  const [getRegisteredData, { data: registeredCoursesData, error: errors }] =
    useLazyQuery(Queries.GET_USER_APPROVED_COURSES, {
      client: client,
      onCompleted: () => {
        console.log(registeredCoursesData, "registeredCoursesData");
        dispatch(updateRegisteredCourses(registeredCoursesData));
      },
      onError() {
        console.log(errors, "registeredCoursesData");
      },
    });

  useEffect(() => {
    getRegisteredData({
      client: client,
    });
  }, []);

  const handleCourseRegister = (courseId) => {
    swal({
      title: "",
      text: "Are you sure you want to register for this course?",
      icon: "info",
      buttons: ["No", "Yes"],
      successMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const call = async () => {
          try {
            const res = await registerCourse({
              variables: {
                courseId,
              },
            });
            swal("You have successfully applied for this course ", {
              icon: "success",
            });
            setRegisteredStatus(res.data.registerCourse.status);
            dispatch(getRegisteredCourses(res.data.registerCourse));
          } catch (error) {
            console.log("Err > ", error);
          }
        };
        call();
      }
    });
  };

  // const object =
  //   registeredCourseData && registeredCourseData?.getRegisteredCourses;

  // const [status, setStatus] = useState();

  // useEffect(() => {
  //   registeredCourseData.getRegisteredCourses.forEach((item) =>
  //     setStatus(item?.status)
  //   );
  // }, []);

  // console.log(registeredCourseData.getRegisteredCourses, "status");

  // const registeredCourse = useSelector(
  //   (state) => state.registeredCourse.status[0]?.getRegisteredCourses
  // );
  // console.log(registeredCourse, "registeredCourseReducer");

  console.log(_id, registeredCourse, "_id");

  // dispatch(getCourseCardId(data));

  return (
    <CardWrapper
      // onClick={courseHandle}
      className="my-3"
      hoverable
      bodyStyle={{ padding: 10 }}
      style={{
        width: 340,
        borderRadius: "10px",
        margin: "0 11px",
      }}
      cover={<ReactPlayer url={courseImageURL} width="340px" height="200px" />}
      actions={[
        <Typography.Text level={3} className="mb-0">
          <Row justify="space-between">
            <Col span={8} style={{ marginTop: 5 }}>
              {moment(createdAt).format("Do MMM YYYY")}
            </Col>
            {(courseStatus || registeredStatus) === "rejected" && (
              <Col span={8}>
                <CardButton>Rejected</CardButton>
              </Col>
            )}
            {(courseStatus || registeredStatus) === "applied" && (
              <Col span={8}>
                <CardButton>Applied</CardButton>
              </Col>
            )}
            {(courseStatus || registeredStatus) !== "applied" &&
              (courseStatus || registeredStatus) !== "rejected" && (
                <CardButton
                  style={{ marginRight: 20 }}
                  onClick={() => handleCourseRegister(_id)}
                >
                  Register
                </CardButton>
              )}
          </Row>
        </Typography.Text>,
      ]}
    >
      <Card.Meta
        style={{ height: 100 }}
        title={courseName}
        description={description}
      />
    </CardWrapper>
  );
};

CoursesCard.defaultProps = {
  registeredCourseData: {
    getRegisteredCourses: [],
  },
  data: {
    courseImageURL: "",
  },
};

export default CoursesCard;
