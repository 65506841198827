import { USER } from "../constants/userConstants";

// ====================USER PROFILE DATA====================
const userProfileData = (data) => {
  return {
    type: USER.USER_PROFILE_DATA,
    payLoad: data,
  };
};

// ====================USER APPLICATION STATUS====================
const userApplicationStatus = (data) => {
  // console.log("UserApplicationStatusFromActionCreator", data);
  return {
    type: USER.USER_APPLICATION_STATUS,
    payLoad: data,
  };
};

// ====================USER PROFILE DATA BY ID====================
const userProfileDataById = (data) => {
  return {
    type: USER.USER_PROFILE_DATA_BY_ID,
    payLoad: data,
  };
};

// =================== USER_RESET_PASSWORD_EMAIL==================
const userResetPasswordEmail = (data) => {
  return {
    type: USER.USER_RESET_PASSWORD_EMAIL,
    payLoad: data,
  };
};

export const prsonalityType = (data) => {
  return {
    type: USER.PERSONALITY_TYPE,
    payload: data,
  };
};

export const bioData = (data) => {
  return {
    type: USER.BIO_DATA,
    payload: data,
  };
};

export const userCoverImage = (data) => {
  return {
    type: USER.SET_USER_COVER_IMAGE,
    payload: data,
  };
};

export {
  userProfileData,
  userResetPasswordEmail,
  userProfileDataById,
  userApplicationStatus,
};
