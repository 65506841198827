import React, { useState, useEffect, useRef } from "react";
import { Card, Modal } from "antd";
import { SelectOutlined } from "@ant-design/icons";
import styled from "styled-components";
import ReactPlayer from "react-player";
import CardSkeletonLoading from "./CardSkeletonLoading";
import "./VideoCard.css";
import { connect } from "react-redux";

const VideoCard = (props) => {
  const { portfolioUrl, topicUrl, portfolioTitle } = props.data;
  const [videoModal, setVideoModal] = useState(false);

  console.log("VideoDatas", props.data);

  const [loading, setLoading] = useState(false);

  const [play, setPlay] = useState(false);
  const [play2, setPlay2] = useState(false);

  console.log("videoData", loading);

  return (
    <>
      {loading ? (
        <CardSkeletonLoading />
      ) : (
        <VideoCardWrapper
          className="my-3"
          hoverable
          style={{ width: 340, margin: "0 11px", borderRadius: "10px" }}
          cover={
            <ReactPlayer
              style={{ position: "relative" }}
              onPlay={() => videoModal && alert("asdf")}
              controls
              playing={videoModal}
              // url={videoData && videoData?.url}
              url={portfolioUrl}
              width="340px"
              height="300px"
              playing={play}
              onPlay={() => setPlay(true)}
            />
          }
        >
          <Card.Meta
            title={<span>{portfolioTitle} </span>}
            avatar={
              <span
                onClick={() => {
                  setPlay(false);
                  setVideoModal(true);
                }}
              >
                <SelectOutlined />
              </span>
            }
          />
        </VideoCardWrapper>
      )}
      <VideoModal
        width={800}
        style={{ height: "500px" }}
        visible={videoModal}
        onCancel={() => {
          setPlay2(false);
          setVideoModal(false);
        }}
        footer={[]}
      >
        <ReactPlayer
          controls
          url={portfolioUrl}
          // url={videoData && videoData.url}
          width="100%"
          height="500px"
          playing={play2}
          onPlay={() => setPlay2(true)}
        />
      </VideoModal>
    </>
  );
};

const mapState = (state) => ({
  videoCache: state.videoReducer,
});

const mapDispatch = (dispatch) => ({
  saveVideoData: (data) => dispatch({ type: "SAVE_VIDEO_DATA", payload: data }),
});

export default connect(mapState, mapDispatch)(VideoCard);

const VideoCardWrapper = styled(Card)`
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;

  box-shadow: 2px 2px 8px 2px #ccc;

  .ant-card-body {
    padding: 20px;
    border-bottom-left-radius: 10px;
  }
  .ant-card-meta-title:hover {
    color: #efa718;
  }
  .ant-card-cover div video {
    position: static !important;
  }
`;
const VideoModal = styled(Modal)`
  .ant-modal-body {
    padding: 25px 0 0 0;
  }
  .ant-modal-body div video {
    position: static !important;
  }
  .ant-modal-close-x {
    width: 30px;
    height: 25px;
    line-height: 20px;
  }
`;
