import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import {
  InstagramOutlined,
  FacebookOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { Layout, Tooltip, Typography, Col } from "antd";
import styled from "styled-components";
import moment from "moment";

const MainFooter = () => {
  return (
    <div style={{ backgroundColor: "#292929" }}>
      <ContainerWrapper
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={5} style={{ color: "gray", marginBottom: 0 }}>
          Emerge © {moment().format("YYYY")}. All Rights Reserved.
        </Typography.Title>
        <SocialIcons style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip placement="top" title="Facebook">
            <a
              href="https://www.facebook.com/LetsEmergeForward"
              target="_blank"
            >
              {" "}
              <FacebookOutlined />
            </a>
          </Tooltip>
          <Tooltip placement="top" title="Twitter">
            <a href="https://twitter.com/EmergeForward" target="_blank">
              <TwitterOutlined style={{ margin: "0 10px" }} />
            </a>
          </Tooltip>
          <Tooltip placement="top" title="Youtube">
            <a
              href="https://www.youtube.com/channel/UCFkPtfpMV_z1QVxvpGheNIg/featured"
              target="_blank"
            >
              <YoutubeOutlined style={{ marginRight: 10 }} />
            </a>
          </Tooltip>
          <Tooltip placement="top" title="Instagram">
            <a href="https://www.instagram.com/emergeforward/" target="_blank">
              <InstagramOutlined />
            </a>
          </Tooltip>
        </SocialIcons>
      </ContainerWrapper>
    </div>
    //  {/* </FooterWrapper> */}
  );
};

export default MainFooter;

const SocialIcons = styled.div`
  .anticon {
    color: gray;
    font-size: 25px;
    transition: ease 1s;
  }
  .anticon:hover {
    color: #efa718;
    transition: ease 1s;
    cursor: pointer;
  }
`;
const FooterWrapper = styled.div`
  .ant-layout-footer {
    padding: 10px 5px;
  }
`;
const ContainerWrapper = styled(Container)`
  padding: 20px 0;
  @media (max-width: 530px) {
    display: flex;
    flex-direction: column;
  }
`;
