import React, { useState, useEffect } from "react";
import { Card, Button, Modal, Divider, Spin, Radio, Row, Col } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Queries from "../appollo_client/Queries";
import Mutations from "../appollo_client/Mutations";
import { client } from "../appollo_client/client";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import QueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import swal from "sweetalert";
import ShowResultAfterTest from "./ShowResultAfterTest";
import Timer from "react-compound-timer";
import "./TakeTestModal.css";

const TakeTestModal = (props) => {
  // console.log(props, "props take test");
  const { firstName } = props.userProfileData && props.userProfileData;
  const { _id, week, topicName } =
    props.takeTestModal && props.takeTestModal.data;
  const [testData, setTestData] = useState("");
  const [nextQuestion, setNextQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [questionsIds, setQuestionsIds] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectBg, setSelectBg] = useState("#e3e8e6");
  const [selectBorder, setSelectBorder] = useState(
    "1px solid rgb(227, 232, 230)"
  );
  const [check, setCheck] = useState();
  const [selectIndex, setSelectIndex] = useState("");
  const [resultData, setResultData] = useState("");
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  console.log("testData", testData);
  console.log("selectOptions", selectOptions);
  console.log("questionsIds", questionsIds);

  useEffect(() => {}, [
    selectedAnswer,
    resultData,
    questionsIds,
    selectOptions,
  ]);

  // =============get test result query===========
  const { data, loading, error } = useQuery(Queries.GET_TEST_QUESTIONS, {
    client: client,
    variables: { courseId: _id, week: week },
    onCompleted: () => {
      setTestData(data.getTestQuestions);
    },
    onError: () => {
      console.log(error, "error in get test query");
    },
  });
  // console.log(testData, "TEST DATA");

  // ==============handel next question===========
  const {
    question,
    choices,
    _id: questionId,
  } = testData.length !== 0 && testData[nextQuestion];
  const nextQuestionHandle = () => {
    setQuestionsIds([...questionsIds, questionId]);
    setQuestions(() => {
      const new_array = questions;
      new_array.push(questionId);
      return new_array;
    });
    setSelectOptions([...selectOptions, selectedAnswer]);
    setAnswers(() => {
      const new_array = answers;
      new_array.push(selectedAnswer);
      return new_array;
    });
    setSelectedAnswer("");
    setSelectIndex("#e8e8e6");
    setNextQuestion(nextQuestion + 1);
  };

  // ============submit test answer mutation=========
  const [submitTestAnswers, { loading: submitLoading, error: submitError }] =
    useMutation(Mutations.SUBMIT_TEST_ANSWERS, { client: client });

  console.log(selectOptions, questionsIds, "function k bahar data==="); //4

  const handleSubmit = async () => {
    // setQuestionsIds([...questionsIds, questionId]);
    // setSelectOptions([...selectOptions, selectedAnswer]);
    // console.log(selectOptions, questionsIds, "bahar data==="); //3
    // console.log("Length of test data", testData.length); //3
    setQuestions(() => {
      const new_array = questions;
      new_array.push(questionId);
      return new_array;
    });

    setAnswers(() => {
      const new_array = answers;
      new_array.push(selectedAnswer);
      return new_array;
    });

    console.log("Length of question id", questions.length); //3

    if (testData.length === questionsIds.length + 1) {
      try {
        console.log(selectOptions, questionsIds, "data==="); //3
        const res = await submitTestAnswers({
          variables: { answers: answers, questionIds: questions },
        });
        // if(res.data.submitTestAnswers){
        setResultData(res.data.submitTestAnswers);
        // }
        console.log(res, "res in submit test answers");
        setAnswers([]);
        setQuestions([]);
      } catch (error) {
        console.log("Error >>", error);
      }
    } else {
      console.log("okay");
    }
  };

  const handleChange = (value, index) => {
    setSelectIndex(index);
    setSelectBg("#F6A017");
    setSelectBorder("3px solid rgb(246, 160, 23)");
    setCheck(<CheckCircleOutlined style={{ color: "green", fontSize: 24 }} />);
    setSelectedAnswer(value);
    console.log("handleTestData", testData);
    // if (questionsIds.length - 1) handleSubmit();
  };

  // ===========handle cancel quiz ===============
  const handleCancelQuiz = () => {
    if (testData.length === 0) {
      props.setTakeTestModal({ ...props.takeTestModal, modalBolean: false });
    } else {
      swal({
        title: "Are you sure?",
        text: "Are you sure you want to cancel the test?",
        icon: "warning",
        buttons: ["Cancel", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setNextQuestion(0);
          setQuestionsIds([]);
          setSelectOptions([]);
          setAnswers([]);
          setQuestions([]);
          setSelectedAnswer("");
          setSelectBg("#e3e8e6");
          props.setTakeTestModal({
            ...props.takeTestModal,
            modalBolean: false,
          });
        }
      });
    }
  };

  // ===================Hanlde ok quiz==================
  const handleOkQuiz = () => {
    props.fetchGetTestResult();
    setResultData("");
    setNextQuestion(0);
    setQuestionsIds([]);
    setSelectOptions([]);
    setSelectedAnswer("");
    setSelectBg("#e3e8e6");
    setSelectBorder("1px solid #e3e8e6");
    setCheck();
    props.setTakeTestModal({ ...props.takeTestModal, modalBolean: false });
  };

  console.log("Answers array", answers);
  console.log("Questions array", questions);

  return (
    <TestModalWrapper
      width={800}
      closeIcon={<></>}
      bodyStyle={{ padding: 0 }}
      visible={props.takeTestModal.modalBolean}
      onOk={() =>
        props.setTakeTestModal({ ...props.takeTestModal, modalBolean: false })
      }
      // onCancel={() => props.setTakeTestModal({...props.takeTestModal, modalBolean: false})}
      footer={[
        <>
          {resultData ? (
            <Button type="primary" onClick={handleOkQuiz}>
              Ok
            </Button>
          ) : (
            <Button className="taketest-cancel" onClick={handleCancelQuiz}>
              Cancel
            </Button>
          )}
          {testData.length - 1 === nextQuestion ? (
            resultData ? (
              ""
            ) : (
              <Button
                disabled={!selectedAnswer ? "disabled" : ""}
                onClick={
                  // console.log("working on click")

                  handleSubmit
                }
                loading={submitLoading}
              >
                Submit
              </Button>
            )
          ) : (
            <Button
              disabled={!selectedAnswer ? "disabled" : ""}
              type="primary"
              onClick={nextQuestionHandle}
              style={{
                backgroundColor: selectedAnswer ? "#F6A017" : "",
                border: selectedAnswer ? "none" : "",
              }}
            >
              Next
            </Button>
          )}
        </>,
      ]}
    >
      {resultData ? (
        <ShowResultAfterTest data={resultData} />
      ) : (
        <div style={{ padding: 20 }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 0",
                flexDirection: "column",
              }}
            >
              <Spinwrapper size="large" />
              <h3>Please wait a moment...</h3>
            </div>
          ) : (
            <>
              {testData.length == 0 ? (
                <h3 align="center">Quiz in progress of this topic!</h3>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h3 style={{ marginBottom: "1em", marginTop: "25px" }}>
                      Best of Luck {firstName && firstName.toUpperCase()} 👍
                    </h3>
                    <h6 style={{ marginBottom: "20px" }}>
                      The quiz is being conducted for course{" "}
                      <b>
                        {props.takeTestModal.data.courseId &&
                          props.takeTestModal.data.courseId.courseName}
                      </b>{" "}
                      of topic <b>{topicName && topicName}</b>.
                    </h6>
                    <h6>
                      Question no: {nextQuestion + 1} out of {testData.length}
                    </h6>
                  </div>
                  <Divider style={{ marginTop: 0 }} />
                  <QueueAnim type="right" delay={300} className="queue-simple">
                    <QuestionWrapper key="a">
                      <p style={{ fontSize: 18 }}>
                        <b>Question # {nextQuestion + 1}:</b> {question}
                      </p>
                      <div>
                        {choices &&
                          choices.map((item, index) => {
                            return (
                              <OptionWrapper
                                style={{
                                  // backgroundColor: index == selectIndex ? selectBg : "#e3e8e6",
                                  backgroundColor: "#e3e8e6",

                                  border:
                                    index == selectIndex
                                      ? selectBorder
                                      : "1px solid #e3e8e6",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  maxHeight: 50,
                                  minHeight: 50,
                                }}
                                onClick={() => {
                                  console.log("on click form working");
                                  return handleChange(item, index);
                                }}
                                key={index}
                              >
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <h5
                                    value={item}
                                    style={{
                                      fontSize: 15,
                                      marginBottom: 0,
                                    }}
                                  >
                                    {item}
                                  </h5>
                                  {index == selectIndex ? check : ""}
                                </Row>
                              </OptionWrapper>
                            );
                          })}
                      </div>
                    </QuestionWrapper>
                  </QueueAnim>
                </>
              )}
            </>
          )}
        </div>
      )}
    </TestModalWrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    userProfileData: state.userProfileDataReducer.data,
  };
};

export default connect(mapStateToProps, null)(TakeTestModal);
const Spinwrapper = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #fa8c16;
  }
`;
const QuestionWrapper = styled.div`
  padding: 20px 65px 20px 65px;
`;
const OptionWrapper = styled.div`
  :hover {
    cursor: pointer;
  }
  margin: 10px 0;
  padding: 14px 16px;
  border-radius: 5px;
  :active {
    background: red;
  }
`;
const TestModalWrapper = styled(Modal)`
  .ant-modal-body {
    padding: 25px 0 0 0;
  }
  .ant-modal-close-x {
    width: 30px;
    height: 25px;
    line-height: 20px;
  }
`;
