import React from 'react';
import { Skeleton, Divider } from 'antd';

const ActivityCardLoading = () => {

    return (
      <div style={{width: 800, margin: '0 8px'}}>
        {/* <Skeleton.Image style={{width: 800, height: 180}}/> */}
        <Skeleton active></Skeleton>
      </div>
    );
}

export default ActivityCardLoading