import { COURSES } from "../constants/userConstants";

const initialState = {
  status: [],
  updateRegisterCourse: [],
  courseCardId: [],
  getUserApprovedCourses: [],
};

const registeredCourse = (state = initialState, action) => {
  switch (action.type) {
    // case COURSES.GET_REGISTERED_COURSES:
    //   console.log("getRegisteredCoursesReducer", action);
    //   return { ...state, status: action.payload };
    case COURSES.UPDATE_REGISTERED_COURSES:
      return {
        ...state,
        updateRegisterCourse: action.payload,
      };
    // case COURSES.GET_COURSE_CARD_ID:
    //   const item = state.updateRegisterCourse.filter((item) => item);
    //   const itemDeep = item[0]?.getRegisteredCourses;
    //   console.log(itemDeep, "item-----");
    //   action.payload.forEach((element) =>
    //     console.log(action.payload, "getCourseCardIdReducer")
    //   );
    //   console.log(itemDeep, "item1-----");

    case COURSES.GET_USER_APPROVED_COURSES_DATA:
      console.log(action.payload, "getUserApprovedCoursesDataFromReducer");
      return { ...state, getUserApprovedCourses: [action.payload] };

    default:
      return state;
  }
};

export default registeredCourse;
