import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const StyledInput = styled(Input)`
  // width: 430px;
  margin-bottom: 3px;
  margin-top: 10px;
`;

const InputWrapper = (props) => {
  return <StyledInput {...props}>{props.children}</StyledInput>;
};

export default InputWrapper;
