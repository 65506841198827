import React from "react";
import { Input } from "antd";
import styled from "styled-components";

const Searchbar = styled(Input)`
  width: 200px;
  border-radius: 20px !important;
  border: 1px solid #2e2d2d !important;
  &:focus {
    border: 1px solid #f6a017;
    box-shadow: none;
  }
  @media (max-width: 960px) {
    width: 222px;
    .ant-tabs-nav-list {
      left: 0px;
    }
    .ant-tabs-nav {
      display: grid;
    }
  }
`;

const SearchBar = (props) => {
  return <Searchbar {...props} />;
};

export default SearchBar;
