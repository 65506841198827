import React from "react";
import { Spin } from "antd";
import styled from "styled-components";

export const Spinner = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #fa8c16;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  min-height: 30vh;

  @media (max-width: 999px) {
    justify-content: center !important;
  }
`;

export const CardSpinner = () => (
  <CardsContainer>
    <Spinner size="large" />
  </CardsContainer>
);
