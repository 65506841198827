import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Avatar, Row, Col, Badge, message, Input } from "antd";
import HeaderLoading from "../components/HeaderLoading";
import {
  selectHttpOptionsAndBody,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { client } from "../appollo_client/client";
import Queries from "../appollo_client/Queries";
import Mutations from "../appollo_client/Mutations";
import Styled from "styled-components";
import { EditOutlined, LoadingOutlined, DownOutlined } from "@ant-design/icons";
import GetUserIdFromUrl from "../GetUserIdFromUrl";
import "./AboutHeader.css";
import { TiCameraOutline } from "react-icons/ti";
// import resemble from "resemblejs";
// import imageToBase64 from "image-to-base64";
import cogoToast from "cogo-toast";
import { getCookie, setCookie } from "../helpers";

import { userCoverImage } from "../redux/actions/users";
import { useDispatch, useSelector } from "react-redux";

const AboutHeader = (props) => {
  const [updateProfileImage, { loading: uploadLoading, error: uploadError }] =
    useMutation(Mutations.UPDATE_PROFILE_IMAGE, { client: client });

  const [
    updateCoverImage,
    { loading: uploadCoverLoading, error: uploadCoverError },
  ] = useMutation(Mutations.UPDATE_COVER_IMAGE, { client: client });

  const [profileData, setProfileData] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);
  const [imageHover, setImageHover] = useState(false);
  const [coverHover, setCoverHover] = useState(false);
  const [coverImageHover, setCoverImageHover] = useState(false);

  // const auth = localStorage.getItem("token") === null ? false : true;
  const auth = getCookie("token") === null ? false : true;

  // const tokenByIdQuery = localStorage.getItem("tokenForAuth");
  const tokenByIdQuery = getCookie("tokenForAuth");

  // const tokenFromStorage = localStorage.getItem("token");
  const tokenFromStorage = getCookie("token");

  const authForPublic = tokenByIdQuery === tokenFromStorage;
  const cover = document.getElementById("cover");
  const [imgSrc, setImgSrc] = useState(null);
  const [isUploadDp, setIsUploadDp] = React.useState(null);
  const [isUploadCover, setIsUploadCover] = React.useState(null);
  const imgPath = "../assets/cover.jpg";
  const imageIconContainer = document.getElementsByClassName(
    "ant-scroll-number-custom-component"
  );
  const dispatch = useDispatch();

  // console.log("Image Icon Div", imageIconContainer);

  // Handling profile picture upload
  React.useEffect(() => {
    const uploadDp = async (files) => {
      const data = new FormData();
      data.append("file", files[0]);
      data.append("upload_preset", "emergeForward");
      setImageLoading(true);
      // console.log("Files >>> ", files[0]);

      try {
        const res = await fetch(
          "https://api.cloudinary.com/v1_1/emergeyouthprogram/image/upload",
          {
            unsigned: true,
            method: "POST",
            body: data,
          }
        );
        const file = await res.json();
        setImgSrc(file.secure_url);
        // ===========image upload on database after response from cloudnary============
        try {
          const res = await updateProfileImage({
            variables: { profileImage: file.secure_url },
          });
          console.log("Image Data >>>", res);

          dispatch({
            type: "SET_USER_PROFILE_IMAGE",
            payload: res.data.updateProfileImage,
          });

          setImageLoading(false);
          userById();
          // localStorage.setItem("hasDP", "true");
          setCookie("hasDP", "true");
          message.success("Profile image has been updated successfully!");
          setIsUploadDp(null);
        } catch (error) {
          message.error("Some thing went wrong!");
          // console.log("Error >>", error.message);
          setIsUploadDp(null);
        }
      } catch (e) {
        // console.log(e, "error in res");
        setIsUploadDp(null);
      }
    };
    if (isUploadDp !== null) {
      uploadDp(isUploadDp);
    }
  }, [isUploadDp]);

  const uploadImage = async (event) => {
    if (
      event.target &&
      event.target.files.length > 0 &&
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/jpeg"
    ) {
      cogoToast.warn("The file should be png/jpg");
      return;
    }
    // ========image upload on cloudnary=========== //
    const files = event.target.files;

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function (e) {
      var image = new Image();

      image.src = e.target.result;

      image.onload = function () {
        var height = this.height;
        var width = this.width;
        // console.log("Height > ", height);
        if (height < 128 || width < 128) {
          cogoToast.warn("Height and Width must not exceed 128 x 128 .");
          setIsUploadDp(null);
          return false;
        }
        setIsUploadDp(files);
      };
    };
  };

  // ==============GET_USER_BY_ID===========//
  useEffect(() => {
    // console.log("use effect 1");
    userById();
  }, [profileData]);

  const [userById, { data: userData, loading: userDataLoading, error }] =
    useLazyQuery(Queries.GET_USER_BY_ID, {
      variables: { userId: GetUserIdFromUrl() },
      client: client,
    });

  useEffect(() => {
    // console.log("use effect 2");
    if (userData) {
      setProfileData(userData?.getUserById);
    }
  }, [userData]);

  const userdta = useSelector((state) => state.userReducer.profileData);

  const { firstName, lastName, email, city, gender, profileImage, coverImage } =
    userdta && userdta;

  const firstletter = firstName?.slice(0, 1).toUpperCase();
  const lastletter = lastName?.slice(0, 1).toUpperCase();

  //Handling Profile picture removal
  const handleRemoveImage = async () => {
    setImageLoading(true);
    try {
      const res = await updateProfileImage({
        variables: {
          profileImage:
            gender && gender.toUpperCase() === "MALE"
              ? "https://png.pngtree.com/png-clipart/20190924/original/pngtree-businessman-user-avatar-free-vector-png-image_4827807.jpg"
              : "https://www.shareicon.net/data/512x512/2016/09/15/829446_user_512x512.png",
        },
      });
      console.log("Image Data >>>", res);
      dispatch({
        type: "REMOVE_USER_PROFILE_IMAGE",
        payload: res.data.updateProfileImage,
      });
      setImageLoading(false);
      userById();
      // localStorage.setItem("hasDP", "false");
      setCookie("hasDP", "false");
      message.success("Profile image has been removed successfully!");
    } catch (error) {
      message.error("Some thing went wrong!");
      // console.log("Error >>", error.message);
    }
    // } catch (e) {
    //   console.log(e, "error in res");
    // }
  };

  //Handling Cover picture upload

  React.useEffect(() => {
    const uploadCover = async (files) => {
      const data = new FormData();
      data.append("file", files[0]);
      data.append("upload_preset", "emergeForward");
      setCoverLoading(true);
      try {
        const res = await fetch(
          "https://api.cloudinary.com/v1_1/emergeyouthprogram/image/upload",
          {
            unsigned: true,
            method: "POST",
            body: data,
          }
        );
        const file = await res.json();
        // setImgSrc(file.secure_url);
        // ===========image upload on database after response from cloudnary============
        try {
          const res = await updateCoverImage({
            variables: { coverImage: file.secure_url },
          });
          console.log("Cover Data >>>", res.data.updateCoverImage.coverImage);
          dispatch(userCoverImage(res.data.updateCoverImage.coverImage));
          setCoverLoading(false);
          userById();
          // localStorage.setItem("hasDP", "true");
          setCookie("hasDP", "true");
          message.success("Cover image has been updated successfully!");
          setIsUploadCover(null);
        } catch (error) {
          message.error("Some thing went wrong!");
          // console.log("Error >>", error.message);
          setIsUploadCover(null);
        }
      } catch (e) {
        // console.log(e, "error in res");
        setIsUploadCover(null);
      }
    };

    if (isUploadCover !== null) {
      uploadCover(isUploadCover);
    }
  }, [isUploadCover]);

  const uploadCover = async (event) => {
    // ========image upload on cloudnary===========
    // console.log("Cover Event > ", event.target.files);

    if (
      event.target &&
      event.target.files.length > 0 &&
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/jpeg"
    ) {
      cogoToast.warn("The file should be png/jpg");
      return;
    }

    const files = event.target.files;
    // console.log(files, "files");

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function (e) {
      var image = new Image();

      image.src = e.target.result;

      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height < 312 || width < 820) {
          cogoToast.warn("Height and Width must be atleast 312x820.");
          setIsUploadCover(null);
          return false;
        }
        setIsUploadCover(files);
      };
    };
  };

  //Handling Profile picture removal
  const handleRemoveCover = async () => {
    setCoverLoading(true);
    try {
      const res = await updateCoverImage({
        variables: {
          coverImage:
            "https://res.cloudinary.com/emergeyouthprogram/image/upload/v1625477132/emerge_forwar/sexwfupr8wvyhthkylay.svg",
        },
      });
      console.log(
        "Remove Cover Data >>>",
        res.data.updateCoverImage.coverImage
      );
      dispatch({
        type: "REMOVE_USER_COVER_IMAGE",
        payload: res.data.updateCoverImage.coverImage,
      });
      setCoverLoading(false);
      userById();
      message.success("Cover image has been removed successfully!");
    } catch (error) {
      message.error("Some thing went wrong!");
    }
    // } catch (e) {
    //   console.log(e, "error in res");
    // }
  };

  const hasDP = () => {
    return (
      profileImage !==
        "https://png.pngtree.com/png-clipart/20190924/original/pngtree-businessman-user-avatar-free-vector-png-image_4827807.jpg" &&
      profileImage !==
        "https://www.shareicon.net/data/512x512/2016/09/15/829446_user_512x512.png"
    );
  };

  const hasCover = () => {
    return (
      coverImage !==
      "https://res.cloudinary.com/emergeyouthprogram/image/upload/v1625477132/emerge_forwar/sexwfupr8wvyhthkylay.svg"
    );
  };

  return (
    <HeaderWrapper>
      {/* <img
        style={{ display: "none" }}
        src={coverImage}
        alt="cover"
        id="cover"
      /> */}
      <Row
        onMouseEnter={() => setCoverImageHover(true)}
        onMouseLeave={() => setCoverImageHover(false)}
        justify="center"
        style={{
          backgroundImage: `url(${coverImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <Col
          style={{
            textAlign: "center",
            margin: "30px 0",
            position: "relative",
            top: "160px",
          }}
        >
          {userDataLoading || imageLoading || coverLoading ? (
            <HeaderLoading />
          ) : (
            <>
              <Badge
                count={
                  tokenFromStorage ? (
                    <div>
                      <div
                        onMouseEnter={() => setImageHover(true)}
                        onMouseLeave={() => setImageHover(false)}
                        className="camera-logo-container"
                      >
                        <Label>
                          <Input
                            type="file"
                            onChange={(e) => uploadImage(e)}
                            accept="image/*"
                          />
                          <TiCameraOutline className="profile-image-icon" />
                        </Label>
                      </div>
                      {hasDP() ? (
                        <div
                          onMouseEnter={() => setImageHover(true)}
                          onMouseLeave={() => setImageHover(false)}
                        >
                          <span
                            className={
                              imageHover ? "remove-text-hovered" : "remove-text"
                            }
                            onClick={handleRemoveImage}
                          >
                            Remove
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    ""
                  )
                }
              >
                {gender && gender.toUpperCase() === "MALE" ? (
                  <Avatar
                    onMouseEnter={() => setImageHover(true)}
                    onMouseLeave={() => setImageHover(false)}
                    size={150}
                    src={
                      profileImage
                        ? profileImage
                        : "https://png.pngtree.com/png-clipart/20190924/original/pngtree-businessman-user-avatar-free-vector-png-image_4827807.jpg"
                    }
                  />
                ) : (
                  <Avatar
                    size={150}
                    src={
                      profileImage
                        ? profileImage
                        : "https://www.shareicon.net/data/512x512/2016/09/15/829446_user_512x512.png"
                    }
                  />
                )}
              </Badge>
              <h4
                style={{
                  textAlign: "center",
                  color: "black",
                  marginTop: "10px",
                }}
              >{`${firstletter}${
                firstName && firstName.slice(1)
              } ${lastletter}${lastName && lastName.slice(1)}`}</h4>
              <h6
                style={{
                  // fontWeight: "600",
                  textAlign: "center",
                  // color: "#919090",
                }}
              >
                {/* {email} */}
                Student | {city}
              </h6>
            </>
          )}
        </Col>
        {tokenFromStorage ? (
          <div className="upload-container">
            <div
              onMouseEnter={() => setCoverHover(true)}
              onMouseLeave={() => setCoverHover(false)}
              className="upload-camera-logo-container"
            >
              <div
                style={{
                  width: "90%",
                  margin: "2px auto",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Input
                      type="file"
                      onChange={(e) => {
                        uploadCover(e);
                      }}
                      accept="image/*"
                    />
                    <TiCameraOutline
                      className={
                        coverHover
                          ? "upload-image-icon-hovered"
                          : "upload-image-icon"
                      }
                    />
                    <span
                      className={
                        coverHover
                          ? "upload-image-text-hovered"
                          : "upload-image-text"
                      }
                      style={{
                        marginLeft: "8px",
                        fontSize: "0.9rem",
                        color: "#fff",
                      }}
                    >
                      Change Cover
                    </span>
                  </div>
                </Label>
              </div>
            </div>
            {hasCover() ? (
              <div>
                <span
                  className={
                    coverImageHover
                      ? "upload-remove-text-hovered"
                      : "upload-remove-text"
                  }
                  onClick={handleRemoveCover}
                >
                  Remove
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </Row>
    </HeaderWrapper>
  );
};

export default AboutHeader;

const HeaderWrapper = Styled.div`
    background: linear-gradient(to bottom right, #808080 , #292929 80%);
    margin-bottom: 80px
`;
const Label = Styled.label`
    input[type="file"] {
        display: none;
    }
`;
