import React from "react";
import { Link } from "react-router-dom";

function CardItem(props) {
  return (
    <>
      <li className="cards__item">
        {/* <Link className='cards__item__link' to={props.path}> */}
        <div className="cards__item__link">
          <figure className="cards__item__pic-wrap" data-category={props.label}>
            <img className="cards__item__img" src={props.src} alt="" />
          </figure>
          <div className="cards__item__info">
            <h5 className="cards__item__text">
              {props.text} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </h5>
          </div>
        </div>
        {/* </Link> */}
      </li>
    </>
  );
}

export default CardItem;
