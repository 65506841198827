const videoReducer = (state = {}, action) => {
  switch (action.type) {
    case "SAVE_VIDEO_DATA": {
      const { name, data } = action.payload;
      return { ...state, [name]: data };
    }
    default:
      return state;
  }
};

export default videoReducer;
