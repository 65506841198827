import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import Queries from "../../appollo_client/Queries";
import { client } from "../../appollo_client/client";
import GetUserIdFromUrl from "../../GetUserIdFromUrl";
/*-------------------------------*/
/* Importing Unregistered Routes */
/*-------------------------------*/
import StudentRegistrNavbar from "../../components/StudentRegisterNavbar";
import StudentInfo from "../../pages/StudentInfo";
import ApplicationQuestions from "../../pages/ApplicationQuestions";
import RegistrationSteps from "../../pages/RegistrationSteps";
import NotFound from "../NotFound";
/*-----------------------------*/
/* Importing Registered Routes */
/*-----------------------------*/
import Header from "../../components/header/Header";
import MainFooter from "../../components/MainFooter";
import About from "../../pages/About";
import Portfolio from "../../pages/Portfolio";
import Courses from "../../pages/Courses";
import WeeklyTopics from "../../pages/WeeklyTopics";
import Library from "../../pages/Library";
import RecentActivity from "../../pages/RecentActivity";
import Settings from "../../pages/Settings";
import LandingPage from "../LandingPage";
import { useSelector, useDispatch } from "react-redux";
import { userProfileData, bioData } from "../../redux/actions/users";
import { getAllPortFolioData } from "../../redux/actions/portfolio";
import { updateRegisteredCourses } from "../../redux/actions/registeredCourses";
import { userProfileDataById } from "../../redux/actions/users";

import { getCookie, setCookie } from "../../helpers";

const UnregisteredRoutes = () => {
  const history = useHistory();
  const appStatusuk = getCookie("appStatus");
  console.log(appStatusuk, "appStatusuk");

  const appUserId = getCookie("userId");
  console.log("appUserId", appUserId);

  useEffect(() => {
    if (appStatusuk != "approved") history.push(`/${appUserId}/steps`);
  }, []);

  return (
    <>
      <StudentRegistrNavbar />
      <Switch>
        <Route exact path="/:id/steps" component={RegistrationSteps} />
        <Route exact path="/:id/steps/student-info" component={StudentInfo} />
        <Route
          exact
          path="/:id/steps/application-questions"
          component={ApplicationQuestions}
        />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

const RegisteredRoutes = () => {
  let dispatch = useDispatch();
  const portfolioData = useSelector(
    (state) => state.getPortfolioData.portfolioData
  );
  // console.log("portfolioData", portfolioData);

  const [profileData, setProfileData] = useState([]);
  const [userPortfolioData, setPortfolioData] = useState([]);
  // console.log("userPortfolioData", userPortfolioData);

  /* Query to get User Profile Data */
  useEffect(() => {
    handleUserProfileData();
  }, []);

  const [handleUserProfileData, { loading: loadingUserProfile, error }] =
    useLazyQuery(Queries.GET_USER_PROFILE, {
      onCompleted: (data) => {
        setProfileData(data && data.getUserProfile);
        console.log("data.getUserProfile", data.getUserProfile.bio);
        dispatch(bioData(data.getUserProfile.bio));
        dispatch(userProfileData(data && data.getUserProfile));
        dispatch({
          type: "SET_USER_PROFILE_DATA",
          payload: { profileData: data.getUserProfile },
        });
      },
      onError: () => console.log(error),
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      client,
    });

  const UserDataReducer = useSelector(
    (state) => state.userProfileDataReducer.data
  );
  // console.log("State Here =========>", UserDataReducer);

  /* Query to get User Portfolio Data */
  const { loading: loadingUserPortfolio, error: userPortfolioError } = useQuery(
    Queries.GET_USER_PORTFOLIO,
    {
      variables: { userId: GetUserIdFromUrl() },
      onCompleted: (data) => {
        // console.log("User Portfolio Data", data);
        // dispatch({
        //   type: "GET_PORTFOLIO_DATA",
        //   payload: data && data.getUserPortfolio,
        // });
        dispatch(getAllPortFolioData(data && data.getUserPortfolio));
      },
      onError: () => console.log(error),
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      client,
    }
  );

  // ==============GET_USER_BY_ID===========
  const [applicationStatus, setApplicationStatus] = useState();

  useEffect(() => {
    getUserProfile();
  }, [profileData]);

  const [getUserProfile, { data: userData, loading: userDataLoading }] =
    useLazyQuery(Queries.GET_USER_BY_ID, {
      variables: {
        userId: GetUserIdFromUrl() || getCookie("userId"),
      },
      client: client,
      // nextFetchPolicy: "cache-first",
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        setProfileData(userData.getUserById);
        setCookie("appStatus", userData.getUserById.applicationStatus);
        setApplicationStatus(
          userData.getUserById.applicationStatus === "approved" ? true : false
        );
        dispatch(userProfileDataById(userData.getUserById));
      },
      onError: (e) => {
        console.log(e, "error in get user By id");
      },
    });

  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Switch>
          <Route
            exact
            path="/"
            component={LandingPage}
            profileData={profileData}
          />
          <Route exact path={`/:id/dashboard`}>
            <About
              handleUserProfileData={handleUserProfileData}
              profileData={profileData}
              loading={loadingUserProfile}
            />
          </Route>
          <Route exact path="/:id/portfolio">
            <Portfolio
              portfolioData={portfolioData}
              loading={loadingUserPortfolio}
            />
          </Route>
          <Route exact path="/:id/library" component={Library} />
          <Route exact path="/:id/courses" component={Courses} />
          <Route exact path="/:id/weekly-topics" component={WeeklyTopics} />
          <Route exact path="/:id/recent-activity" component={RecentActivity} />
          <Route exact path="/:id/settings">
            {/* <Settings data={profileData} loading={loadingUserProfile} /> */}
            <Settings data={UserDataReducer} loading={loadingUserProfile} />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <MainFooter />
      </div>
    </>
  );
};

const Shell = () => {
  // const isRegistered = localStorage.getItem("appStatus");

  const history = useHistory();
  const isRegistered = getCookie("appStatus");
  useEffect(() => {
    if (
      // history.location.pathname === "/" ||
      history.location.pathname === "/sign-up" ||
      history.location.pathname === "/sign-in"
    ) {
      history.push(`${getCookie("userId")}/dashboard`);
    }
  }, []);

  const userAppStatus = useSelector(
    (state) => state.userReducer.applicationStatus
  );
  console.log(userAppStatus, "userAppStatus");

  // ==============GET_USER_BY_ID===========
  const [applicationStatus, setApplicationStatus] = useState();

  useEffect(() => {
    getUserProfile();
  }, []);

  const [getUserProfile, { data: userData, loading: userDataLoading }] =
    useLazyQuery(Queries.GET_USER_BY_ID, {
      variables: {
        userId: GetUserIdFromUrl() || getCookie("userId"),
      },
      client: client,
      // nextFetchPolicy: "cache-first",
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        setCookie("appStatus", userData.getUserById.applicationStatus);
        setApplicationStatus(
          userData.getUserById.applicationStatus === "approved" ? true : false
        );
      },
      onError: (e) => {
        console.log(e, "error in get user By id");
      },
    });

  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      {isRegistered === "approved" ? (
        <RegisteredRoutes />
      ) : (
        <UnregisteredRoutes />
      )}
    </Switch>
  );
};

export default Shell;
