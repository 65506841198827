import React from "react";
import "./About.css";
import CardItem from "./CardItem";
import AboutItem from "./AboutItem";
import { Link } from "react-router-dom";
import aboutCardData from "./aboutCardData";
// import {ReactComponent as LiveTraining} from './images/Live-Training.svg'
// import {ReactComponent as GlobalMentorship} from './images/Global-Mentorship.svg'
// import {ReactComponent as ProfessionalNetwork} from './images/Professional-network.svg'
// // import {ReactComponent as VolunteeringOpportunities} from './images/VolunteeringOpportunities.svg'
// import {ReactComponent as CorporatePlacement} from './images/Corporate-placement.svg'
// import {ReactComponent as EmergeYouthClub} from './images/Emerge-YouthClub.svg'

// import LiveTraining from "./images/Live-Training.svg";
import LiveTraining from "../assets/Live-Training.svg";
import GlobalMentorship from "../assets/Global-Mentorship.svg";
import ProfessionalNetwork from "../assets/Professional-network.svg";
import VolunteeringOpportunities from "../assets/VolunteeringOpportunities.svg";
import CorporatePlacement from "../assets/Corporate-placement.svg";
import EmergeYouthClub from "../assets/Emerge-YouthClub.svg";

// import VolunteeringOpportunities from './images/VolunteeringOpportunities.svg'

const About = (props) => {
  return (
    <div className="about-container" id="courses">
      <div className="about-desc-align">
        <section className="about-subscription">
          <p
            style={{ fontFamily: "PT Sans" }}
            className="about-subscription-heading"
          >
            This program is going to change your life for good !
          </p>
          <p
            style={{ fontFamily: "PT Sans" }}
            className="about-subscription-text"
          >
            Get equipped for handling challenges of the corporate world and life
            in general !
          </p>
          <p
            style={{ fontFamily: "PT Sans" }}
            className="about-subscription-text"
          >
            After you graduate from this program, you will be able to
            confidently finalize the professional domain for your life. i.e.
            STEM, Medical, Engineering, Software, Law Business etc.
          </p>
        </section>
      </div>

      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <AboutItem
              src="https://s.w.org/images/core/emoji/13.0.0/svg/1f680.svg"
              // cardImg={LiveTraining}
              svgImg={LiveTraining}
              alt="🚀"
              heading="Live Trainings"
              text={aboutCardData[0].desc}
              label="Adventure"
              path="/services"
              setModalOpen={props.setModalOpen}
            />
            <AboutItem
              src="https://s.w.org/images/core/emoji/13.0.0/svg/1f3c6.svg"
              // cardImg={GlobalMentorship}
              svgImg={GlobalMentorship}
              heading="Global Mentorship"
              text={aboutCardData[1].desc}
              label="Adventure"
              path="/services"
              setModalOpen={props.setModalOpen}
            />
            <AboutItem
              src="https://s.w.org/images/core/emoji/13.0.0/svg/1f9e0.svg"
              // cardImg={ProfessionalNetwork}
              svgImg={ProfessionalNetwork}
              alt="🚀"
              heading="Professional Network"
              text={aboutCardData[2].desc}
              label="Adventure"
              path="/services"
              setModalOpen={props.setModalOpen}
            />
          </ul>
          <ul className="cards__items">
            <AboutItem
              src="https://s.w.org/images/core/emoji/13.0.0/svg/1f30e.svg"
              // cardImg={VolunteeringOpportunities}
              svgImg={VolunteeringOpportunities}
              // cardImg={'images/volunteering-opportunities-img.png'}
              alt="🚀"
              heading="Volunteering Opportunities"
              text={aboutCardData[3].desc}
              label="Adventure"
              path="/services"
              setModalOpen={props.setModalOpen}
            />
            <AboutItem
              src="https://s.w.org/images/core/emoji/13.0.0/svg/2b50.svg"
              // cardImg={CorporatePlacement}
              svgImg={CorporatePlacement}
              alt="🚀"
              heading="Corporate placement"
              text={aboutCardData[4].desc}
              label="Adventure"
              path="/services"
              setModalOpen={props.setModalOpen}
            />
            <AboutItem
              src="https://s.w.org/images/core/emoji/13.0.0/svg/1f984.svg"
              // cardImg={EmergeYouthClub}
              svgImg={EmergeYouthClub}
              alt="🚀"
              heading="Emerge Youth Club"
              text={aboutCardData[5].desc}
              label="Adventure"
              path="/services"
              setModalOpen={props.setModalOpen}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;

{
  /* <div style={{flexDirection:'column',width:'35%',backgroundColor:'green',marginLeft:'8%'}}>
                    <h3>Heading</h3>
                    <p>Testing sdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asd</p>
                </div>

                <div style={{flexDirection:'column',width:'35%',backgroundColor:'green',marginLeft:'8%'}}>
                    <h3>Heading</h3>
                    <p>Testing sdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asdsdasd sd sd asd</p>
                </div> */
}
