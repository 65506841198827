import React from 'react';
import { Skeleton, Divider } from 'antd';

const HeaderLoading = () => {

    return (
      <div style={{width: 250, margin: '0 20',}}>
        <Skeleton.Image active avatar style={{width: 150, height: 150, borderRadius: 100}} paragraph={false}/>
        <Skeleton.Input style={{width: 220, height: 40, marginTop: 15}} active />
        <Skeleton.Input style={{width: 180, height: 20, marginTop: 15}} active />
      </div>
    );
}

export default HeaderLoading