export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";

export const USER = {
  GET_DATA: "GET_USER_DATA_FROM_STORE",
  STORE_DATA: "STORE_USER_DATA_FROM_API_IN_STORE",
  USER_PROFILE_DATA: "USER_PROFILE_DATA",
  USER_PROFILE_DATA_BY_ID: "USER_PROFILE_DATA_BY_ID",
  USER_RESET_PASSWORD_EMAIL: "USER_RESET_PASSWORD_EMAIL",
  USER_APPLICATION_STATUS: "USER_APPLICATION_STATUS",
  PERSONALITY_TYPE: "PERSONALITY_TYPE",
  BIO_DATA: "BIO_DATA",
  SET_USER_COVER_IMAGE: "SET_USER_COVER_IMAGE",
  VERIFY_LOGGED_USER: "VERIFY_LOGGED_USER",
};

export const USER_AUTHENTICATED = {
  AUTHENTICATED: "USER_AUTHENTICATED_AUTHENTICATED",
  NOT_AUTHENTICATED: "USER_AUTHENTICATED_NOT_AUTHENTICATED",
};

export const PORTFOLIO = {
  GET_PORTFOLIO_DATA: "GET_PORTFOLIO_DATA",
  ADD_PORTFOLIO_DATA: "ADD_PORTFOLIO_DATA",
};

export const RECENT_ACTIVITY = {
  GET_ALL_ACTIVITY: "GET_ALL_ACTIVITY",
  ADD_NEW_ACTIVITY: "ADD_NEW_ACTIVITY",
};

export const COURSES = {
  GET_REGISTERED_COURSES: "GET_REGISTERED_COURSES",
  UPDATE_REGISTERED_COURSES: "UPDATE_REGISTERED_COURSES",
  GET_COURSE_CARD_ID: "GET_COURSE_CARD_ID",
  GET_USER_APPROVED_COURSES_DATA: "GET_USER_APPROVED_COURSES_DATA",
};
