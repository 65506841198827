import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Empty, Spin, Divider, Input, Tabs } from "antd";
import QueueAnim from "rc-queue-anim";
import { client } from "../appollo_client/client";
import Queries from "../appollo_client/Queries";
import RecentActivityCard from "../components/RecentActivityCard";
import GetUserIdFromUrl from "../GetUserIdFromUrl";
import { CardSpinner } from "../components/spinner";

import { useQuery } from "@apollo/client";

const InputWrapper = styled(Input)`
  &.ant-input {
    border-radius: 20px !important;
    border: 1px solid #2e2d2d;
  }
  &.ant-input:focus,
  .ant-input-focused {
    box-shadow: none;
    border: 1px solid #f6a017;
  }
`;
const CardsContainer = styled.div`
  background: #fff;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // @media(max-width: 999px){
  //     justify-content: center !important
  // }

  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
`;

const TabsNav = styled(Tabs)`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab {
    padding: 12px 20px;
    font-size: 15px;
  }
  .ant-tabs-ink-bar {
    background: #efa718 !important;
    height: 3px !important;
  }
  .ant-tabs-nav-list {
    position: relative;
    left: 99px;
  }
`;

const Spinwrapper = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #fa8c16;
  }
`;

const PublicRecentActivity = () => {
  const [textbox, setTextbox] = useState("");
  const [recentActivityData, setRecentActivityData] = useState([]);
  const [data, setData] = useState([]);

  const { loading, error } = useQuery(Queries.GET_USER_RECENT_ACTIVITY, {
    variables: { userId: GetUserIdFromUrl() },
    onCompleted: (data) => {
      // console.log("Activity", data.getUserRecentActivity);
      setRecentActivityData(data.getUserRecentActivity);
      setData(data.getUserRecentActivity);
    },
    onError: () => {
      console.log(error, "error in portfolio");
    },
    client: client,
    fetchPolicy: "cache-and-network",
  });

  const textSearch = () => {
    const filteredData = recentActivityData.filter((activity) =>
      activity.tags.some((abc) =>
        abc.toLowerCase().includes(textbox.toLowerCase())
      )
    );
    // console.log("filteredData", filteredData);
    setData(filteredData);
  };

  useEffect(() => textSearch(), [textbox]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
    >
      <div key="a" style={{ marginTop: 80 }}>
        <Container style={{ display: "flex", justifyContent: "flex-end" }}>
          <InputWrapper
            showSearch={true}
            placeholder="Enter a Keyword"
            onChange={(e) => setTextbox(e.target.value)}
            allowClear
            value={textbox}
            style={{ width: 200 }}
          />
        </Container>

        <Divider style={{ marginTop: 10 }} />

        <QueueAnim type="right" delay={300} className="queue-simple">
          <div key="a">
            {loading ? (
              <CardsContainer
                style={{ display: "flex", justifyContent: "center" }}
              >
                <CardSpinner />
              </CardsContainer>
            ) : (
              <CardsContainer>
                {data.length === 0 ? (
                  <CardsContainer
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Empty align="center"></Empty>
                  </CardsContainer>
                ) : (
                  <>
                    {data.map((item, index) => {
                      return (
                        <RecentActivityCard
                          key={index}
                          data={item}
                          setTextbox={setTextbox}
                        />
                      );
                    })}
                  </>
                )}
              </CardsContainer>
            )}
          </div>
        </QueueAnim>
      </div>
    </div>
  );
};

export default PublicRecentActivity;
