import React, { useState, useEffect } from "react";
import "./header/Header.css";
import { Image } from "react-bootstrap";
import EmergeLogo from "../assets/logo for Youth-white-01.svg";
import { Button } from "antd";
import styled from "styled-components";
import { Link, NavLink, useHistory } from "react-router-dom";
import { AlignRightOutlined, CloseOutlined } from "@ant-design/icons";
import Queries from "../appollo_client/Queries";
import { client } from "../appollo_client/client";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import GetUserIdFromUrl from "../GetUserIdFromUrl";
import { getCookie, removeCookie } from "../helpers";
import "./PublicMainNavbar.css";

const PublicMainNavbar = (props) => {
  const { profileData } = props;
  const userId = profileData._id;
  const history = useHistory();

  const [click, setClick] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const handleClick = () => setClick(!click);

  const [errorVerifiedUser, setErrorVerifiedUser] = useState();
  const token = getCookie("token");

  const closeMobileMenu = (index) => {
    setClick(false);
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  const navLinks = [
    { name: "About", to: `/${userId}/dashboard` },
    { name: "Portfolio", to: `/${userId}/portfolio` },
    { name: "Recent Activity", to: `/${userId}/recent-activity` },
    { name: "SignIn", to: `/sign-in` },
    { name: "SignUp", to: `/sign-up` },
  ];

  const navLinks1 = [
    { name: "About", to: `/${userId}/dashboard` },
    { name: "Portfolio", to: `/${userId}/portfolio` },
    { name: "Library", to: `/${userId}/library` },
    { name: "Courses", to: `/${userId}/courses` },
    { name: "Recent Activity", to: `/${userId}/recent-activity` },
    { name: "Settings", to: `/${userId}/settings` },
  ];

  const [_, setForceRender] = useState(); // Forcely render navbar

  return (
    <StyledDiv
      style={{
        boxShadow: "0 0 2px 2px lightgray",
      }}
    >
      <div className="header">
        <a href="/" target="_blank">
          <div className="logo-container">
            <Image src={EmergeLogo} width="120px" />
          </div>
        </a>
        <div className={click ? "nav-options active" : "nav-options"}>
          {navLinks.map((item, index) => {
            // console.log("item", item);
            return (
              <>
                {errorVerifiedUser != undefined ? (
                  <NavLinks
                    key={index}
                    className={`nav-item ${item.name}`}
                    style={{
                      borderBottom:
                        history.location.pathname == item.to
                          ? `3px solid #EFA718`
                          : "",
                      color:
                        history.location.pathname == item.to
                          ? "#EFA718"
                          : "#fff",
                    }}
                    onClick={() => {
                      closeMobileMenu(index);
                      setForceRender(Math.random()); // Forcely render navbar
                    }}
                    to={item.to}
                  >
                    {item.name}
                  </NavLinks>
                ) : (
                  <NavLinks
                    key={index}
                    className={`nav-item ${item.name}`}
                    style={{
                      borderBottom:
                        history.location.pathname == item.to
                          ? `3px solid #EFA718`
                          : "",
                      color:
                        history.location.pathname == item.to
                          ? "#EFA718"
                          : "#fff",
                    }}
                    onClick={() => {
                      closeMobileMenu(index);
                      setForceRender(Math.random()); // Forcely render navbar
                    }}
                    to={item.to}
                  >
                    {item.name}
                  </NavLinks>
                )}
              </>
            );
          })}
        </div>

        <SignInUpDiv>
          <div className="login-container">
            <p
              className="login_button"
              onClick={() => {
                history.push("/sign-in");
              }}
            >
              Log in
            </p>
          </div>
          <ButtonWrapper
            onClick={() => {
              // localStorage.clear();
              removeCookie();
              window.open(
                `${process.env.REACT_APP_PUBLIC_URL}/sign-up`,
                "_blank"
              );
            }}
          >
            Sign Up
          </ButtonWrapper>
        </SignInUpDiv>

        <div className="mobile-menu" onClick={handleClick}>
          {click ? (
            <CloseOutlined style={{ fontSize: 40, color: "#efa718" }} />
          ) : (
            <AlignRightOutlined style={{ fontSize: 40, color: "#efa718" }} />
          )}
        </div>
      </div>
    </StyledDiv>
  );
};
PublicMainNavbar.defaultProps = {
  profileData: {},
};
export default PublicMainNavbar;

const StyledDiv = styled.div`
  background-color: #292929;
  box-shadow: 0 0 2px 2px lightgray;
  @media (min-width: 961px) {
    .SignUp {
      display: none;
    }
    .SignIn {
      display: none;
    }
  }
`;

const NavLinks = styled(NavLink)`
  height: 80px;
  align-items: center;
  display: flex;
  :hover {
    text-decoration: none;
    color: #efa718 !important;
  }
`;
const ButtonWrapper = styled(Button)`
  border: none;
  color: black;
  border-radius: 20px;
  font-weight: bold;
  :hover {
    color: #efa718;
    box-shadow: 0 0 2px 1px white;
  }
  :active,
  :focus {
    color: #000 !important;
  }
`;

const SignInUpDiv = styled.div`
  display: flex;
  justifycontent: flex-end;
  alignitems: center;
  @media (max-width: 960px) {
    display: none;
  }
`;
