import gql from "graphql-tag";
const Mutations = {
  SIGN_UP: gql`
    mutation abc(
      $firstName: String!
      $lastName: String!
      $email: String!
      $password: String!
      $city: String!
      $deviceId: String!
      $phone: Float!
      $country: String!
    ) {
      signupUser(
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
        city: $city
        deviceId: $deviceId
        phone: $phone
        country: $country
      ) {
        _id
        jwtToken {
          token
          createdAt
        }
        userName
        firstName
        lastName
        email
      }
    }
  `,

  LOG_IN: gql`
    mutation abc($email: String!, $password: String!, $deviceId: String!) {
      loginUser(email: $email, password: $password, deviceId: $deviceId) {
        _id
        jwtToken {
          token
          createdAt
        }
        userName
        firstName
        lastName
        email
        applicationStatus
      }
    }
  `,

  LOG_OUT: gql`
    mutation abc($deviceId: String!) {
      logout(deviceId: $deviceId) {
        _id
        jwtToken {
          token
          createdAt
        }
      }
    }
  `,

  UPDATE_USER_INFO: gql`
    mutation abc(
      $firstName: String!
      $lastName: String!
      $city: String!
      $gender: String!
      $birthday: String!
      $phone: Float!
      $primeGuardianFirstName: String!
      $primeGuardianLastName: String!
      $primeGuardianEmail: String!
      $secGuardianFirstName: String
      $secGuardianLastName: String
      $secGuardianEmail: String
      $grade: String!
      $institute: String!
      $isJob: Boolean!
      $isMaster: Boolean!
      $knowThrough: [String!]
      $isInter: Boolean!
      $profileLink: String!
    ) {
      updateUserInfo(
        firstName: $firstName
        lastName: $lastName
        city: $city
        gender: $gender
        birthday: $birthday
        phone: $phone
        primeGuardianFirstName: $primeGuardianFirstName
        primeGuardianLastName: $primeGuardianLastName
        primeGuardianEmail: $primeGuardianEmail
        secGuardianFirstName: $secGuardianFirstName
        secGuardianLastName: $secGuardianLastName
        secGuardianEmail: $secGuardianEmail
        grade: $grade
        institute: $institute
        isJob: $isJob
        isMaster: $isMaster
        knowThrough: $knowThrough
        isInter: $isInter
        profileLink: $profileLink
      ) {
        _id
        applicationStatus
        profileStatus
      }
    }
  `,

  SUBMIT_APPLICATION_ANSWERS: gql`
    mutation abc($answer: String!, $questionId: String!) {
      submitApplicationAnswers(answer: $answer, questionId: $questionId) {
        _id
        userId {
          firstName
        }
        questionId {
          question
          discription
        }
      }
    }
  `,

  ADD_USER_PORTFOLIO: gql`
    mutation abc(
      $portfolioUrl: String!
      $portfolioType: String!
      $portfolioTitle: String!
      $description: String!
    ) {
      addUserPortfolio(
        description: $description
        portfolioUrl: $portfolioUrl
        portfolioType: $portfolioType
        portfolioTitle: $portfolioTitle
      ) {
        _id
        userId {
          firstName
        }
        description
        portfolioUrl
        portfolioType
        portfolioTitle
      }
    }
  `,

  RESET_PASSWORD: gql`
    mutation abc($email: String!) {
      resetPassword(email: $email) {
        _id
      }
    }
  `,

  UPDATE_PASSWORD: gql`
    mutation abc(
      $email: String!
      $newPassword: String!
      $reenterPassword: String!
      $code: String!
    ) {
      updatePassword(
        email: $email
        newPassword: $newPassword
        reenterPassword: $reenterPassword
        code: $code
      ) {
        _id
      }
    }
  `,

  RESEND_CODE: gql`
    mutation abc($email: String!) {
      resendCode(email: $email) {
        _id
      }
    }
  `,

  UPDATE_USER_BIO: gql`
    mutation abc($bio: String!) {
      updateUserBio(bio: $bio) {
        _id
        bio
      }
    }
  `,

  UPDATE_PROFILE_IMAGE: gql`
    mutation abc($profileImage: String!) {
      updateProfileImage(profileImage: $profileImage) {
        _id
        firstName
        profileImage
      }
    }
  `,

  UPDATE_COVER_IMAGE: gql`
    mutation abc($coverImage: String!) {
      updateCoverImage(coverImage: $coverImage) {
        _id
        firstName
        coverImage
      }
    }
  `,

  SUBMIT_TEST_ANSWERS: gql`
    mutation abc($answers: [String], $questionIds: [String]) {
      submitTestAnswers(answers: $answers, questionIds: $questionIds) {
        _id
        userId {
          firstName
        }
        courseId {
          topicName
          description
        }
        status
        totalMarks
        obtMarks
        result
        percent
        week
      }
    }
  `,

  ADD_USER_RECENT_ACTIVITY: gql`
    mutation abc(
      $description: String!
      $recentActivityUrl: String!
      $recentActivityType: String!
      $recentActivityTitle: String!
      $tags: [String]
    ) {
      addUserRecentActivity(
        description: $description
        recentActivityUrl: $recentActivityUrl
        recentActivityType: $recentActivityType
        recentActivityTitle: $recentActivityTitle
        tags: $tags
      ) {
        _id
        userId {
          firstName
        }
        description
        recentActivityUrl
        recentActivityType
        recentActivityTitle
        tags
      }
    }
  `,

  UPDATE_PERSONALITY_TYPE: gql`
    mutation abc($personalityType: String!) {
      updatePersonalityType(personalityType: $personalityType) {
        _id
        firstName
        personalityType
      }
    }
  `,

  CHANGE_SETTING: gql`
    mutation abc(
      $firstName: String!
      $lastName: String!
      $city: String!
      $country: String!
      $phone: Float!
      $password: String
      $changedPassword: String
    ) {
      changeSetting(
        firstName: $firstName
        lastName: $lastName
        city: $city
        country: $country
        phone: $phone
        password: $password
        changedPassword: $changedPassword
      ) {
        _id
        email
        firstName
        lastName
        gender
        country
        city
        applicationStatus
        profileStatus
        isJob
        isMaster
        isInter
        birthday
        bio
        phone
        profileImage
        personalityType
      }
    }
  `,

  REGISTER_COURSE: gql`
    mutation abc($courseId: String!) {
      registerCourse(courseId: $courseId) {
        _id
        userId {
          _id
          firstName
        }
        courseId {
          _id
          courseName
        }
        status
      }
    }
  `,
};

export default Mutations;
