import React, { useState, useEffect } from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Button, Empty, Spin, Layout, Divider, Input, Tabs } from "antd";
import QueueAnim from "rc-queue-anim";
import AddRecentActivityModal from "../components/AddRecentActivityModal";
import { client } from "../appollo_client/client";
import Queries from "../appollo_client/Queries";
import { useQuery, useLazyQuery } from "@apollo/client";
import { connect } from "react-redux";
import AboutHeader from "../components/AboutHeader";
import RecentActivityCard from "../components/RecentActivityCard";
import GetUserIdFromUrl from "../GetUserIdFromUrl";
import ArticleCard from "../components/ArticleCard";
import VideoCard from "../components/VideoCard";
import { getCookie } from "../helpers";
import { FullscreenExitOutlined } from "@ant-design/icons";
import { CardSpinner } from "../components/spinner";
import SearchBar from "../components/SearchBar";
import UserBanner from "../components/banner/userBanner";

import { getAllActivity } from "../redux/actions/recentActivity";

const { TabPane } = Tabs;

const RecentActivity = (props) => {
  const [textbox, setTextbox] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [recentActivityData, setRecentActivityData] = useState(null);
  const [propfileData, setProfileData] = useState("");

  // const auth = localStorage.getItem("token") === null ? false : true;
  const auth = getCookie("token") === null ? false : true;

  const videoTags = document.getElementsByTagName("video");

  const [getTags, setGetTags] = useState("");

  const handleTags = (item) => {
    // console.log("Function Fired > ", item);
    setGetTags(item);
  };
  // console.log("Get Tags", getTags);

  useEffect(() => {}, [getTags]);

  const articleData = [];
  const videoData = [];

  // const tokenByIdQuery = localStorage.getItem("tokenForAuth");
  const tokenByIdQuery = getCookie("tokenForAuth");

  // const tokenFromStorage = localStorage.getItem("token");
  const tokenFromStorage = getCookie("token");

  const authForPublic = tokenByIdQuery === tokenFromStorage;
  // ===============Get user Profile Query=================
  const { data: profileData } = useQuery(Queries.GET_USER_PROFILE, {
    client: client,
    onCompleted: () => {
      setProfileData(profileData && profileData.getUserProfile);
    },
    onError: (error) => console.log(error),
  });

  // console.log("Video tags >> ", videoTags.item(0));

  // ===============Seprate article and video data===========
  recentActivityData &&
    recentActivityData.map((item, index) => {
      if (item.topicType === "article") {
        articleData.push(item);
      } else if (item.topicType === "videos") {
        videoData.push(item);
      }
    });

  // ===========Get user Recent Activity Query=============
  const [getData, { data, loading, error }] = useLazyQuery(
    Queries.GET_USER_RECENT_ACTIVITY,
    {
      variables: { userId: GetUserIdFromUrl() },
      client: client,
      onCompleted: () => {
        // props.dispatch({
        //   type: "GET_ALL_ACTIVITY",
        //   payload: data.getUserRecentActivity,
        // });
        props.dispatch(getAllActivity(data.getUserRecentActivity));
      },
      onError: () => {
        console.log(error, "error in recent activity");
      },
    }
  );

  // ==========Search by tag===========
  const textboxSearch = (e) => {
    setTextbox(e.target.value) && setTextbox(getTags);
  };

  // const textSearch = () => {
  //   let filteredData = [];
  //   props.allActivities.forEach((element) => {
  //     element.tags.forEach((tag) => {
  //       if (tag.toLowerCase().includes(textbox.toLowerCase())) {
  //         filteredData.push(element);
  //       }
  //     });
  //   });
  //   console.log("Filtered data >>> ", filteredData);
  //   setRecentActivityData(filteredData);
  // };

  const textSearch = () => {
    let filteredData = [];
    for (let i = 0; i < props.allActivities.length; i++) {
      for (let j = 0; j < props.allActivities[i].tags?.length; j++) {
        if (
          props.allActivities[i].tags[j]
            .toLowerCase()
            .includes(textbox.toLowerCase())
        ) {
          filteredData.push(props.allActivities[i]);
          break;
        }
      }
    }
    setRecentActivityData(filteredData);
  };

  useEffect(() => {
    setRecentActivityData(props.allActivities);
  }, [props.allActivities]);
  useEffect(() => getData(), []);
  useEffect(() => textSearch(), [textbox]);

  // console.log("All Activities Tags >>> ", props.allActivities);
  // console.log("All Recent Activity Data >>> ", recentActivityData);

  const handleCrossButton = (e) => {
    e.preventDefault();
    setTextbox("");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
    >
      {/* <AboutHeader /> */}
      <UserBanner />

      <div key="a" style={{ marginTop: 80 }}>
        <Container style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* <CrossButton onClick={handleCrossButton}>
            <FullscreenExitOutlined style={{ marginBlock: 7 }} />
          </CrossButton> */}
          <SearchBar
            showSearch={true}
            placeholder="Enter a Keyword"
            onChange={textboxSearch}
            allowClear
            value={textbox}
            style={{ width: 200 }}
          />
          <AddNewBtn
            // disabled={authForPublic === true ? "" : "disabled"}
            onClick={() => setAddModal(true)}
          >
            + Add Activity
          </AddNewBtn>
        </Container>
        <Divider style={{ marginTop: 10 }} />
        <QueueAnim type="right" delay={300} className="queue-simple">
          <div key="a">
            {loading ? (
              <CardsContainer
                style={{ display: "flex", justifyContent: "center" }}
              >
                <CardSpinner />
              </CardsContainer>
            ) : (
              <CardsContainer>
                {recentActivityData && recentActivityData.length === 0 ? (
                  <CardsContainer
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Empty align="center"></Empty>
                  </CardsContainer>
                ) : (
                  <>
                    {recentActivityData &&
                      recentActivityData.map((item, index) => {
                        return (
                          <RecentActivityCard
                            key={index}
                            data={item}
                            setTextbox={setTextbox}
                          />
                        );
                      })}
                  </>
                )}
              </CardsContainer>
            )}
          </div>
        </QueueAnim>
        <AddRecentActivityModal
          addModal={addModal}
          setAddModal={setAddModal}
          getData={getData}
          userId={props.userProfileData._id}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userProfileData: state.userProfileDataReducer.data,
    allActivities: state.getAllRecentActivity.allActivities,
    garbage: state.getAllRecentActivity.garbage,
  };
};

export default connect(mapStateToProps, null)(RecentActivity);

const ContentWrapper = styled.div`
  min-height: 100%;
  margin: 0 auto -50px;
`;
const AddNewBtn = styled(Button)`
  margin-left: 20px;
  background-color: rgb(41, 41, 41);
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 15px;
  text-align: center;
  margin-right: 5px;
  box-shadow: 0 1px 4px 0px black;
  :hover {
    background-color: rgb(41, 41, 41);
    color: #efa718 !important;
  }
  :focus {
    background-color: rgb(41, 41, 41);
    color: white;
  }
`;

const CardsContainer = styled.div`
  background: #fff;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // @media(max-width: 999px){
  //     justify-content: center !important
  // }

  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
`;

const TabsNav = styled(Tabs)`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab {
    padding: 12px 20px;
    font-size: 15px;
  }
  .ant-tabs-ink-bar {
    background: #efa718 !important;
    height: 3px !important;
  }
  .ant-tabs-nav-list {
    position: relative;
    left: 99px;
  }
`;
const Spinwrapper = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #fa8c16;
  }
`;

const CrossButton = styled.button`
  border-radius: 50px;
  width: 30px;
  height: 30px;
  background: white;
  margin-inline: 15px;
`;
