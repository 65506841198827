import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Typography, Avatar } from "antd";
import styled from "styled-components";
import ReactPlayer from "react-player";
import moment from "moment";
import "./courseCard.css";
import { CardButton } from "./button/cardButton";
import swal from "sweetalert";
import { useMutation, useQuery, useLazyQuery } from "react-apollo";
import Mutations from "../appollo_client/Mutations";
import Queries from "../appollo_client/Queries";
import { client } from "../appollo_client/client";
import { useDispatch, useSelector } from "react-redux";
import {
  getRegisteredCourses,
  updateRegisteredCourses,
  getCourseCardId,
} from "../redux/actions/registeredCourses";

const CardWrapper = styled(Card)`
  box-shadow: 1px 0 6px 1px #ccc;
  .ant-card-actions {
    border-radius: 10px;
  }
`;

const CoursesCard1 = (props) => {
  let dispatch = useDispatch();
  const { history, data, registeredCourse } = props;
  console.log(data, "data");
  const { courseImageURL, description, courseName, _id, createdAt } =
    data.courseId;

  const courseHandle = () => {
    history.push("weekly-topics", { courseId: _id, title: courseName });
  };

  return (
    <CardWrapper
      onClick={courseHandle}
      className="my-3"
      hoverable
      bodyStyle={{ padding: 10 }}
      style={{
        width: 340,
        borderRadius: "10px",
        margin: "0 11px",
      }}
      cover={<ReactPlayer url={courseImageURL} width="340px" height="200px" />}
      actions={[
        <Typography.Text level={3} className="mb-0">
          <Row justify="space-between">
            <Col span={8} style={{ marginTop: 5 }}>
              {moment(createdAt).format("Do MMM YYYY")}
            </Col>
            <Col span={8}>Approved</Col>
          </Row>
        </Typography.Text>,
      ]}
    >
      <Card.Meta
        style={{ height: 100 }}
        title={courseName}
        description={description}
      />
    </CardWrapper>
  );
};

CoursesCard1.defaultProps = {
  registeredCourseData: {
    getRegisteredCourses: [],
  },
  data: {
    courseImageURL: "",
  },
};

export default CoursesCard1;
