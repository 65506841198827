import { combineReducers } from "redux";
import userAuthenticateReducer from "./reducers/authenticate";
import {
  userProfileDataReducer,
  userResetPasswordEmailReducer,
  userProfileDataByIdReducer,
  userReducer,
} from "./reducers/userReducer";

import getAllRecentActivity from "./reducers/getAllRecentActivity";
import videoReducer from "./reducers/videoReducer";
import articleReducer from "./reducers/articleReducer";
import recentAtivityReducer from "./reducers/recentActivityReducer";
import getAllCourses from "./reducers/getAllCourses";
import getPortfolioData from "./reducers/getPortfolioData";
import verifyLoggedUser from "./reducers/verifyLoggedUser";
import registeredCourse from "./reducers/registeredCourses";

export const rootReducer = combineReducers({
  userAuthenticateReducer,
  userProfileDataReducer,
  userProfileDataByIdReducer,
  userResetPasswordEmailReducer,
  getAllRecentActivity,
  getPortfolioData,
  getAllCourses,
  videoReducer,
  articleReducer,
  recentAtivityReducer,
  userReducer,
  verifyLoggedUser,
  registeredCourse,
});
