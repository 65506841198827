import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InputWrapper from "../components/input/index";
import ArrowLeft from "../components/arrowLeft/ArrowLeft";
import {
  Form,
  Button,
  Col,
  Row,
  Checkbox,
  Typography,
  Alert,
  Input,
} from "antd";
import { MailFilled, LockFilled, RightOutlined } from "@ant-design/icons";
import tksLogo from "../assets/signIn-bg.jpg";
import Logo from "../assets/emergeYouth.png";
import { ReactComponent as EmergeLogo } from "../assets/logo for Youth.svg";
import QueueAnim from "rc-queue-anim";
import RememberPasswordInput from "../components/RememberPasswordInput";
import { useMutation } from "@apollo/client";
import { client } from "../appollo_client/client";
import Mutations from "../appollo_client/Mutations";
import DeviceId from "../components/DeviceId";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { userAuthenticatedUpdate } from "../redux/actions/authenticate";
import { userResetPasswordEmail } from "../redux/actions/users";
import { getCookie, setCookie } from "../helpers";

const SignIn = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [error, setError] = useState("");
  const [responseSuccefully, setResponseSuccefully] = useState(null);
  // console.log("Email >>> ", email);

  const [loginUser, { loading }] = useMutation(Mutations.LOG_IN, {
    client: client,
  });

  const [resetPassword, { loading: resetPasswordLoading }] = useMutation(
    Mutations.RESET_PASSWORD,
    { client: client }
  );

  const handleSubmit = () => {
    if (rememberPassword) {
      handleRememberPassword();
    } else {
      handleLogIn();
    }
  };

  const handleLogIn = async () => {
    const deviceId = DeviceId(10);
    try {
      const { data } = await loginUser({
        variables: { email, password, deviceId },
      });
      // localStorage.setItem("appStatus", data.loginUser.applicationStatus);
      setCookie("appStatus", data.loginUser.applicationStatus);
      props.logIn(data.loginUser, deviceId, props.history);
      setError("");
    } catch (error) {
      setError(error.message.slice(15));
      console.log("Errors >>", error.message);
    }
  };

  const handleRememberPassword = async () => {
    try {
      const res = await resetPassword({
        variables: {
          email: resetPasswordEmail,
        },
      });
      setError("");
      setResponseSuccefully(res.data && res.data.resetPassword);
      props.userEmail({ email: resetPasswordEmail });
      // localStorage.setItem("email", resetPasswordEmail);
      setCookie("email", resetPasswordEmail);
      history.push("/reset-password");
    } catch (e) {
      setError(e.message.slice(15));
      console.log("Error >>", e.message.slice(15));
    }
  };
  const [form] = Form.useForm();
  // console.log("Reset Password Email >>>> ", resetPasswordEmail);

  const authenticated = getCookie("isLogin");

  const userId = getCookie("userId");

  useEffect(() => {
    if (authenticated === "true") history.push(`/${userId}/dashboard`);
  }, []);

  return (
    <SignInBgImage>
      <SignInContainer>
        <QueueAnim type="bottom" delay={500} className="queue-simple">
          <Row key="a" justify="center" align="middle">
            <Col>
              <SignInCard>
                <SignInCardImage>
                  {rememberPassword ? (
                    <ArrowLeft
                      onClick={() => {
                        form.resetFields();
                        setError("");
                        setRememberPassword(false);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        style={{ display: "flex", justifyContent: "center" }}
                        to="/"
                      >
                        {/* <img
                          src={Logo}
                          style={{
                            height: "60%",
                            width: "60%",
                          }}
                          alt="logo"
                        /> */}
                        <EmergeLogo style={{ height: "45%", width: "45%" }} />
                      </Link>
                    </div>

                    {rememberPassword ? (
                      <p style={{ marginBottom: 0, marginTop: "6%" }}>
                        Reset Your Password
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </SignInCardImage>

                <Form onFinish={handleSubmit} form={form}>
                  {rememberPassword ? (
                    <div style={{ padding: 20 }}>
                      <QueueAnim
                        type="right"
                        delay={300}
                        className="queue-simple"
                      >
                        <RememberPasswordInput
                          value={resetPasswordEmail}
                          key="b"
                          setResetPasswordEmail={setResetPasswordEmail}
                          error={error}
                          responseSuccefully={responseSuccefully}
                          onChange={(e) =>
                            setResetPasswordEmail(e.target.value)
                          }
                        />
                      </QueueAnim>
                    </div>
                  ) : (
                    // <div key="c" style={{ padding: 20 }}>
                    //   <Form.Item
                    //     name="email"
                    //     rules={[
                    //       {
                    //         required: true,
                    //         message: "Please input your Email!",
                    //       },
                    //     ]}
                    //   >
                    //     <InputWrapper
                    //       placeholder="yours@example.com"
                    //       prefix={<MailFilled style={{ marginRight: 5 }} />}
                    //       onChange={(e) => setEmail(e.target.value)}
                    //       type="email"
                    //       size={"large"}
                    //       value={email}
                    //     />
                    //   </Form.Item>
                    //   <Form.Item>
                    //     <InputWrapper
                    //       style={{ width: 300 }}
                    //       size={"large"}
                    //       prefix={<LockFilled style={{ marginRight: 5 }} />}
                    //       placeholder="Your password"
                    //     />
                    //   </Form.Item>
                    // </div>
                    <QueueAnim type="left" delay={300} className="queue-simple">
                      <div key="c" style={{ padding: 20 }}>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your Email!",
                              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            },
                          ]}
                        >
                          <InputWrapper
                            value={email}
                            style={{ width: "300px" }}
                            type="email"
                            size={"large"}
                            prefix={<MailFilled style={{ marginRight: 5 }} />}
                            placeholder="Yours@example.com"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Form.Item>

                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your Password!",
                            },
                          ]}
                        >
                          <Input.Password
                            autoComplete="off"
                            style={{ width: "300px" }}
                            size={"large"}
                            prefix={<LockFilled style={{ marginRight: 5 }} />}
                            placeholder="Your password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Form.Item>
                        {error ? (
                          <Alert message={error} type="error" showIcon />
                        ) : (
                          ""
                        )}
                        {/* <Form.Item name="remember" valuePropName="checked"> */}
                        <Checkbox
                          checked={isCheckboxChecked}
                          onChange={(e) =>
                            setIsCheckboxChecked(e.target.checked)
                          }
                          style={{ color: "gray" }}
                        >
                          {" "}
                          Remember me
                        </Checkbox>
                        {/* </Form.Item> */}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography.Link
                            style={{
                              marginTop: 10,
                              marginBottom: 0,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              form.resetFields();
                              setRememberPassword(true);
                              setError("");
                            }}
                          >
                            Forget password?
                          </Typography.Link>
                          <Typography.Link
                            style={{
                              marginTop: 10,
                              marginBottom: 0,
                              cursor: "pointer",
                            }}
                            onClick={() => props.history.push("/sign-up")}
                          >
                            Don't have an account!
                          </Typography.Link>
                        </div>
                      </div>
                    </QueueAnim>
                  )}

                  <LoginCustomBtn
                    htmlType="submit"
                    loading={loading || resetPasswordLoading}
                  >
                    <h4 style={{ color: "white", marginBottom: 0 }}>
                      {rememberPassword ? "SEND EMAIL" : "LOG IN"}
                    </h4>
                    <RightOutlined
                      style={{ color: "white", fontSize: 20, marginLeft: 10 }}
                    />
                  </LoginCustomBtn>
                </Form>
              </SignInCard>
            </Col>
          </Row>
        </QueueAnim>
      </SignInContainer>
    </SignInBgImage>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logIn: (data, deviceId, history) =>
      dispatch(userAuthenticatedUpdate(data, deviceId, history)),
    userEmail: (data) => dispatch(userResetPasswordEmail(data)),
  };
};

export default connect(null, mapDispatchToProps)(SignIn);

const SignInContainer = styled.div`
  background: rgba(0, 0, 10, 0.7);
  // width: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SignInBgImage = styled.div`
  height: 100vh;
  background-image: url(${tksLogo});
  background-size: cover;
  background-repeat: no-repeat;
`;
const SignInCard = styled.div`
  background-color: white;
  width: 340px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 6px -2px black;
  align-items: center;
  border-radius: 5px;
`;
const LoginCustomBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a2a2a;
  height: 80px;
  width: 340px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  outline: none;
  box-shadow: none;
  &:hover,
  &:focus {
    background-color: #2a2a2a;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;
const SignInCardImage = styled.div`
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #f6f6f6;
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
