import React, { useState, useEffect } from "react";
import "../App.css";
import Cards from "../landing-page-components/Cards";
import About from "../landing-page-components/About";
import HeroSection from "../landing-page-components/HeroSection";
import Footer from "../landing-page-components/Footer";
import { removeCookie } from "../helpers";

function Home() {
  const [modalOpen, setModalOpen] = useState(false);

  const modalUpdate = () => {
    setModalOpen(!modalOpen);
  };
  // useEffect(() => {
  //   // localStorage.removeItem("email");
  //   // localStorage.removeItem("countryCode");
  //   // localStorage.removeItem("appStatus");
  //   // localStorage.removeItem("isLogin");
  //   // localStorage.removeItem("tokenForAuth");

  //   removeCookie("email");
  //   removeCookie("countryCode");
  //   removeCookie("appStatus");
  //   removeCookie("isLogin");
  //   removeCookie("tokenForAuth");

  // }, []);
  // console.log("Modal Open >>> ", modalOpen);
  return (
    <>
      <HeroSection />
      <div
        style={{
          width: modalOpen ? "100vw" : "100%",
        }}
      >
        <About setModalOpen={modalUpdate} />
      </div>
      <div
        style={{
          width: modalOpen ? "100vw" : "100%",
        }}
      >
        <Cards />
      </div>
      <Footer />
    </>
  );
}

export default Home;
