import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import ButtonWrapper from "../components/button/index";
import InputWrapper from "../components/input/index";
import {
  Divider,
  Form,
  AutoComplete,
  Select,
  Col,
  Row,
  Input,
  Alert,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import "../App.css";
import { Link, useHistory } from "react-router-dom";
import {
  UserOutlined,
  MailFilled,
  PhoneOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import DeviceId from "../components/DeviceId";
import Mutations from "../appollo_client/Mutations";
import { userAuthenticatedUpdate } from "../redux/actions/authenticate";
import { useMutation } from "@apollo/client";
import { client } from "../appollo_client/client";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import csc from "country-state-city";
import { useForm } from "antd/lib/form/Form";
import { getCookie, setCookie } from "../helpers";
import Recaptcha from "react-recaptcha";
import cogoToast from "cogo-toast";
import moment from "moment";

const { Option } = Select;

const SignUpCard = styled.div`
  //   width: 570px;
  padding: 20px;
  //   background-color: white;
  //   justify-content: center;
  //   display: flex;
  //   flex-direction: column;
  box-shadow: 0px 1px 6px -2px black;
  //   align-items: center;
`;

const StyledRecaptcha = styled(Recaptcha)`
  width: 300px;
`;
const StyledCol = styled(Col)`
  padding: 20px 0 20px;
`;

const SignupDiv = styled.div`
  @media (min-width: 720px) {
    padding: 0 60px 0;
  }
  @media (max-width: 720px) {
    padding: 0 10px 0;
  }
`;

const SignUp = (props) => {
  const history = useHistory();
  const [signupUser, { data, loading }] = useMutation(Mutations.SIGN_UP, {
    client: client,
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectCity, setSelectCity] = useState(null);
  const [phone, setPhone] = useState(null);
  const [countryFormat, setCountryFormat] = useState(null);
  const [country, setCountry] = useState(
    csc.getCountryByCode("pk".toUpperCase())
  );
  const [showError, setShowError] = useState(false);
  const [valueFormat, setValueFormat] = useState(null);
  const [error, setError] = useState("");
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [countryCode, setCountryCode] = useState("PK");
  const [cities, setCities] = useState([]);
  const [cityError, setCityError] = useState(null);
  const [firstNameError, setfirstNameError] = useState(null);
  const [lastNameError, setlastNameError] = useState(null);
  const [showFirstNameError, setshowFirstNameError] = useState(false);
  const [showLastNameError, setshowLastNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  // console.log("Country by Code >>> ", country.name);
  // console.log("First Name >>>", firstName);
  const handleSubmit = async () => {
    if (isVerified === true) {
      const deviceId = DeviceId(10);
      if (
        countryFormat !== null &&
        countryFormat.format.length === valueFormat.length &&
        selectCity !== null &&
        firstName.length > 2 &&
        !isContainNumber(firstName) &&
        lastName.length > 0 &&
        !isContainNumber(lastName) &&
        isPassword(password)
      ) {
        try {
          const res = await signupUser({
            variables: {
              firstName: firstName,
              lastName: lastName,
              email: email,
              password: password,
              city: selectCity,
              country: countryCode,
              phone: parseFloat(phone),
              deviceId: deviceId,
            },
          });
          // localStorage.setItem("countryCode", countryCode.toUpperCase());
          setCookie("countryCode", countryCode.toUpperCase());
          props.signUp(res.data.signupUser, deviceId, props.history);
        } catch (error) {
          setTimeout(() => {
            setErrorDisplay(true);
          }, 2000);
          setErrorDisplay(false);
          console.log("Error >>>", error.message);
          setError(error.message.slice(15));
        }
      } else {
        if (isContainNumber(firstName) || firstName.length < 3) {
          setTimeout(() => {
            setshowFirstNameError(true);
          }, 2000);
          setshowFirstNameError(false);
          setlastNameError("Please enter valid first name");
        } else if (isContainNumber(lastName) || lastName.length < 3) {
          setTimeout(() => {
            setshowLastNameError(true);
          }, 2000);
          setshowLastNameError(false);
          setlastNameError("Please enter valid last name");
        } else if (
          countryFormat === null ||
          countryFormat.format.length !== valueFormat.length
        ) {
          setPhoneError("Please enter valid phone number");
        } else if (isContainNumber(lastName) || lastName.length < 3) {
          setTimeout(() => {
            setshowLastNameError(true);
          }, 2000);
          setshowLastNameError(false);
          setlastNameError("Please enter valid last name");
        } else if (!isPassword(password)) {
          setTimeout(() => {
            setShowPasswordError(true);
          }, 4000);
          setShowPasswordError(true);
          setPasswordError(
            "Enter at least 8 characters. Include one lowercase letter, one uppercase letter, and one number."
          );
        } else if (selectCity == null) {
          setTimeout(() => {
            setShowError(true);
          }, 2000);
          setShowError(false);
          setCityError("Please select a city");
        }
      }
    } else {
      cogoToast.warn("Please verify that you are human!");
    }
  };

  useEffect(() => {
    setCities(csc.getCitiesOfCountry(countryCode));
    // setSelectCity(null);
  }, [countryCode]);

  const phoneHandle = (value, country, e, formattedValue) => {
    if (value[0] != "9") {
      setPhone("92" + value);
    } else if (value[1] && value[1] != "2") {
      value = value.replace(value[1], "2");
      setPhone(value);
    } else {
      setPhone(value);
    }
    setCountryFormat(country);
    setCountryCode(country.countryCode.toUpperCase());
    setValueFormat(formattedValue);
    setPhoneError("");
  };

  React.useEffect(() => {
    // console.log("City is > ", selectCity);
  }, [selectCity]);
  const handleCityChange = (e) => {
    setSelectCity(e);
  };

  const isContainNumber = (value) => {
    return (
      (value && value.toLowerCase().includes("1".toLowerCase())) ||
      (value && value.toLowerCase().includes("2".toLowerCase())) ||
      (value && value.toLowerCase().includes("3".toLowerCase())) ||
      (value && value.toLowerCase().includes("4".toLowerCase())) ||
      (value && value.toLowerCase().includes("5".toLowerCase())) ||
      (value && value.toLowerCase().includes("6".toLowerCase())) ||
      (value && value.toLowerCase().includes("7".toLowerCase())) ||
      (value && value.toLowerCase().includes("8".toLowerCase())) ||
      (value && value.toLowerCase().includes("9".toLowerCase()))
    );
  };
  const hasUpperCase = (str) => {
    return str && str.toLowerCase() !== str;
  };

  const hasLowerCase = (str) => {
    return str && str.toUpperCase() !== str;
  };

  const isLong = (str) => {
    return str && str.length >= 8;
  };

  const isPassword = (str) => {
    return (
      str &&
      hasUpperCase(str) &&
      hasLowerCase(str) &&
      isLong(str) &&
      isContainNumber(str)
    );
  };

  const authenticated = getCookie("isLogin");

  const userId = getCookie("userId");

  useEffect(() => {
    if (authenticated === "true") history.push(`/${userId}/dashboard`);
  }, []);

  const recaptchaLoaded = () => {
    console.log("Captcha Successfully Loaded!");
  };

  const verifyCallback = (response) => {
    if (response) {
      setIsVerified(true);
    }
  };

  const pkCitites = [
    "Karachi",
    "Lahore",
    "Faisalabad",
    "Rawalpindi",
    "Gujranwala",
    "Peshawar",
    "Multan",
    "Hyderabad",
    "Islamabad",
    "Quetta",
  ];

  return (
    <Row style={{ justifyContent: "center" }}>
      <StyledCol xl={10} lg={10} md={18} sm={22} xs={22}>
        <SignUpCard>
          <h1>
            Welcome!
            <span role="img" aria-label="wave">
              👋
            </span>
          </h1>
          <p style={{ textAlign: "center", marginBottom: 0 }}>
            Let's get started. First we need to gather some basic <br />{" "}
            information to create your application account.
          </p>

          <SignupDiv>
            <Divider style={{ backgroundColor: "#f7f7f7" }} />
            {/* <h4 style={{ textAlign: "center" }}>
              Apply below to Emerge {moment().format("YYYY")}-
              {moment().add(1, "year").format("YY")}.
            </h4> */}
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="signup_firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your First Name",
                    pattern: /^[A-Za-z]+$/,
                  },
                ]}
              >
                <InputWrapper
                  maxLength="30"
                  value={firstName}
                  type="text"
                  size={"large"}
                  prefix={<UserOutlined style={{ marginRight: 5 }} />}
                  placeholder="First Name*"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  autoComplete="none"
                />
              </Form.Item>
              {!showFirstNameError && firstNameError ? (
                // <Alert
                //   message={firstNameError}
                //   type="error"
                //   showIcon
                //   style={{ marginBottom: "10px" }}
                // />
                <p style={{ marginTop: "3px", color: "#f00" }}>
                  {firstNameError}
                  <Tooltip title="Name should be at least 3 characters long and not have number">
                    <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                  </Tooltip>
                </p>
              ) : (
                ""
              )}
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Last Name",
                    pattern: /^[A-Za-z]+$/,
                  },
                ]}
              >
                <InputWrapper
                  maxLength="30"
                  type="text"
                  size={"large"}
                  prefix={<UserOutlined style={{ marginRight: 5 }} />}
                  placeholder="Last Name*"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  autoComplete="none"
                />
              </Form.Item>
              {!showLastNameError && lastNameError ? (
                // <Alert
                //   message={lastNameError}
                //   type="error"
                //   showIcon
                //   style={{ marginBottom: "10px" }}
                // />
                <p style={{ marginTop: "3px", color: "#f00" }}>
                  {lastNameError}
                  <Tooltip title="Name should be at least 3 characters long and not have number">
                    <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                  </Tooltip>
                </p>
              ) : (
                ""
              )}
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid email",
                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  },
                ]}
              >
                <InputWrapper
                  maxLength="30"
                  type="email"
                  size={"large"}
                  prefix={<MailFilled style={{ marginRight: 5 }} />}
                  placeholder="Your Email*"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="none"
                />
              </Form.Item>
              {/* <Form.Item
              name="phone"
              rules={[{ required: true, message: 'phoneError' }]}
            > */}
              <PhoneInput
                country={"pk"}
                disableDropdown="true"
                value={phone}
                containerStyle={{ marginTop: 10 }}
                inputStyle={{ width: "100%", borderRadius: 0, height: 38 }}
                searchStyle={{ width: "366px" }}
                dropdownStyle={{ width: "430px" }}
                regions={["america", "europe", "asia", "oceania", "africa"]}
                onChange={(value, country, e, formattedValue) =>
                  phoneHandle(value, country, e, formattedValue)
                }
                enableSearch={true}
                placeholder="Phone number"
                autoComplete="none"
              />
              <p style={{ color: "red" }}>{phoneError}</p>
              {/* <Input 
            prefix={<PhoneOutlined style={{marginRight: 5}}/>}
            addonBefore={prefixSelector} 
            placeholder="Phone Number"
            style={{ width: '100%' }} 
            maxLength={10}
            minLength={10}
            onChange={(e) => setPhone(e.target.value)}
            /> */}
              {/* </Form.Item> */}
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message:
                      "Password must contain atleast 8 characters. Including 1 lowercase letter, 1 uppercase letter, and 1 number",
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
                  },
                ]}
              >
                <Input.Password
                  maxLength="20"
                  style={{ marginTop: 10 }}
                  size={"large"}
                  prefix={<LockOutlined style={{ marginRight: 5 }} />}
                  placeholder=" Password*"
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="off"
                />
              </Form.Item>
              {!showPasswordError && passwordError ? (
                // <Alert
                //   message={lastNameError}
                //   type="error"
                //   showIcon
                //   style={{ marginBottom: "10px" }}
                // />
                <p style={{ marginTop: "3px", color: "#f00" }}>
                  {passwordError}
                </p>
              ) : (
                ""
              )}
              <Form.Item
              // name="city"
              // rules={[{ required: true, message: "Please select the City" }]}
              >
                {/* {console.log("", selectCity)} */}
                <Select
                  showSearch
                  size="large"
                  onChange={handleCityChange}
                  placeholder="Which city are you applying from?*"
                  style={{ borderLeft: "none", marginTop: 10 }}
                  value={selectCity}
                  defaultValue={selectCity}
                >
                  {/* {cities.map((city) => (
                    <Option key={cities.indexOf(city)} value={city.name}>
                      {city.name}
                    </Option>
                  ))} */}
                  {pkCitites?.map((city) => (
                    <Option value={city}>{city}</Option>
                  ))}
                </Select>
              </Form.Item>
              {!showError && cityError ? (
                <Alert
                  message={cityError}
                  type="error"
                  showIcon
                  style={{ marginBottom: "10px" }}
                />
              ) : (
                ""
              )}
              <p style={{ marginBottom: 0 }}>
                Have an account? <Link to="/sign-in">Sign In</Link>
              </p>
              {!errorDisplay && error ? (
                <Alert
                  style={{ color: "red" }}
                  message={error}
                  type="error"
                  showIcon
                />
              ) : (
                ""
              )}
              {/* <Form.Item name="remember" valuePropName="checked">
                <Checkbox checked={isCheckboxChecked} 
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                    style={{color: 'gray'}}> I am a student applying to Emerge
                </Checkbox>
              </Form.Item> */}
              <br />

              <StyledRecaptcha
                sitekey="6LdkRn8bAAAAAGIU7mFyaxypcMGmZhJmdHWpb2iN"
                secretkey="6LdkRn8bAAAAAIn6r2TuwIqIr2RWZJizJLdwghzG"
                render="explicit"
                onloadCallback={recaptchaLoaded}
                verifyCallback={verifyCallback}
              />

              <Divider style={{ backgroundColor: "#f7f7f7" }} />
              <ButtonWrapper
                loading={loading ? loading : ""}
                htmlType="submit"
                style={{ textAlign: "center" }}
                size="large"
                type="primary"
              >
                SUBMIT
              </ButtonWrapper>
              <Divider style={{ backgroundColor: "#f7f7f7" }} />
              <p style={{ textAlign: "center" }}>
                <strong>We offer financial aid to students who need it</strong>{" "}
                – and applying for aid does not affect your chances of getting
                in. So if you think you can't afford Emerge, still apply!
              </p>
            </Form>
          </SignupDiv>
        </SignUpCard>
      </StyledCol>
    </Row>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (data, deviceId, history) =>
      dispatch(userAuthenticatedUpdate(data, deviceId, history)),
  };
};

export default connect(null, mapDispatchToProps)(SignUp);
