import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "@apollo/client/link/context";
import { getCookie } from "../helpers";

const authLink = setContext((_, { headers }) => {
  // const token = localStorage.getItem("token");
  const token = getCookie("token");
  return {
    headers: {
      ...headers,
      Authorization: token ? token : "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchpolicy: "cache-and-network",
  },
  query: {
    fetchpolicy: "cache-and-network",
  },
};

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export { client };
