import React, { useState, useEffect } from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Form, Button, Input, message, Tooltip } from "antd";
import QueueAnim from "rc-queue-anim";
import { useHistory } from "react-router-dom";
import Mutations from "../../appollo_client/Mutations";
import { useMutation } from "@apollo/client";
import { client } from "../../appollo_client/client";
import { InfoCircleOutlined } from "@ant-design/icons";
import GetUserIdFromUrl from "../../GetUserIdFromUrl";

const { TextArea } = Input;

const QuestionFive = (props) => {
  var count = 0;
  let history = useHistory();

  const [submitApplicationAnswers, { data, loading, error }] = useMutation(
    Mutations.SUBMIT_APPLICATION_ANSWERS,
    { client: client }
  );

  const finish = async (e) => {
    try {
      const res = await submitApplicationAnswers({
        variables: {
          answer: props.applicationState.questionFive,
          questionId: props.data && props.data._id,
        },
      });
      count = count + 1;

      message.success("Submitted successfully!");
      history.push(`/${GetUserIdFromUrl()}/steps`);
      // console.log(res, "response in application submit");
    } catch (error) {
      console.log(error.message, "Application submit error");
    }
  };
  const previous = (e) => {
    props.prevStep();
  };
  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={0} className="queue-simple">
        <Form key="a" onFinish={finish}>
          <h5 style={{ margin: "20px 0" }}>
            {props.data && props.data.question}
          </h5>

          <Form.Item
            initialValue={props.applicationState.questionFive}
            name="firstName"
            rules={[
              {
                required: true,
                min: 100,
                max: 350,
                message: "Answer must be between 100 and 350 characters",
              },
            ]}
            style={{ marginTop: 50 }}
          >
            <TextArea
              showCount
              rows={5}
              style={{ minHeight: 100, maxHeight: 100 }}
              name="name"
              type="text"
              placeholder="Type your answer here...."
              onChange={(e) =>
                props.setApplicationState({
                  ...props.applicationState,
                  questionFive: e.target.value,
                })
              }
              maxLength={350}
            />
          </Form.Item>
          {/* {props.applicationState.questionFive.length !== 0 ? (
            <p style={{ color: "#f00" }}>
              Answer must be between 10 and 350 characters*
            </p>
          ) : (
            <></>
          )} */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <Button
              size="large"
              onClick={previous}
              style={{ backgroundColor: "lightgray" }}
            >
              {" "}
              Previous
            </Button>
            <Button
              disabled={!props.isSubmittable()}
              size="large"
              htmlType="submit"
              type="primary"
              loading={loading}
            >
              Finish{" "}
            </Button>
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default QuestionFive;
