import { Button } from "antd";
import styled from "styled-components";
export const ReadMoreBtn = styled(Button)`
  font-size: 12px;
  font-weight: bold;
  border-color: #efa718;
  border-radius: 20px !important;
  transition: ease 1s;

  :hover {
    color: black;
    background-color: #efa718;
    transition: ease 1s;
    border-color: black;
  }
`;
