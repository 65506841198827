import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Container, Image } from "react-bootstrap";
import {
  UserOutlined,
  MailOutlined,
  InfoCircleOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Typography,
  Input,
  Row,
  Col,
  message,
  Select,
  Skeleton,
  Tooltip,
} from "antd";
import styled from "styled-components";
import { useMutation, useLazyQuery } from "@apollo/client";
import Mutations from "../appollo_client/Mutations";
import { client } from "../appollo_client/client";
import GetUserIdFromUrl from "../GetUserIdFromUrl";
import swal from "sweetalert";
import "./AboutContent.css";
import { useSelector, useDispatch } from "react-redux";
import { prsonalityType, bioData } from "../redux/actions/users";
import { getCookie } from "../helpers";

const { Option } = Select;

const { TextArea } = Input;

const AboutContent = (props) => {
  const dispatch = useDispatch();
  console.log(props, "props");
  let { bio, birthday, gender, personalityType } = props.profileData;
  // personalityType = null;
  console.log(props.profileData?.personalityType, "personalityType");
  const [perType, setPerType] = useState(null);
  const [userBio, setUserBio] = useState("");
  const [showCount, setShowCount] = useState(false);
  const ant = document.getElementsByClassName("about-text-container");
  const [divHeight, setDivHeight] = useState(0);
  const [isReadMore, setIsReadMore] = useState(true);
  const [personality, setPersonality] = useState("");

  const pstate = useSelector(
    (state) => state.userReducer.profileData.personalityType
  );

  const bioDatas = useSelector((state) => state);
  console.log("bioDatas", bioDatas.userReducer.profileData.bioData);

  // console.log("pstate", pstate);

  // const auth = localStorage.getItem("token") === null ? false : true;
  const auth = getCookie("token") === null ? false : true;

  // const tokenByIdQuery = localStorage.getItem("tokenForAuth");
  const tokenByIdQuery = getCookie("tokenForAuth");

  // const tokenFromStorage = localStorage.getItem("token");
  const tokenFromStorage = getCookie("token");

  // const authForPublic = tokenByIdQuery === tokenFromStorage;
  const authForPublic = tokenFromStorage ? true : false;

  useEffect(() => {
    setUserBio(bio);
  }, [bio]);

  useEffect(() => {
    setIsReadMore(true);
    setDivHeight(ant[0]?.offsetHeight);
  }, []);

  const personalityTypeOptions = [
    { value: "ISTJ - The Inspector", type: "ISTJ - The Inspector" },
    { value: "ISTP - The Crafter", type: "ISTP - The Crafter" },
    { value: "ISFJ - The Protector", type: "ISFJ - The Protector" },
    { value: "ISFP - The Artist", type: "ISFP - The Artist" },
    { value: "INFJ - The Advocate", type: "INFJ - The Advocate" },
    { value: "INFP - The Mediator", type: "INFP - The Mediator" },
    { value: "INTJ - The Architect", type: "INTJ - The Architect" },
    { value: "INTP - The Thinker", type: "INTP - The Thinker" },
    { value: "ESTP - The Persuader", type: "ESTP - The Persuader" },
    { value: "ESTJ - The Director", type: "ESTJ - The Director" },
    { value: "ESFP - The Performer", type: "ESFP - The Performer" },
    { value: "ESFJ - The Caregiver", type: "ESFJ - The Caregiver" },
    { value: "ENFP - The Champion", type: "ENFP - The Champion" },
    { value: "ENFJ - The Giver", type: "ENFJ - The Giver" },
    { value: "ENTP - The Debater", type: "ENTP - The Debater" },
    { value: "ENTJ - The Commander", type: "ENTJ - The Commander" },
  ];

  const bioRef = useRef(bio);
  const [updateUserBio, { loading }] = useMutation(Mutations.UPDATE_USER_BIO, {
    client: client,
  });
  const updateBio = async () => {
    try {
      const { data } = await updateUserBio({
        variables: { bio: userBio },
      });
      if (data) {
        props.handleUserProfileData();
        message.success("Bio has been updated successfully!");
        bioRef.current = userBio;
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  // console.log("Personalitys", personality);
  // =================Updata personalityType===========
  const [updatePersonalityType, { loading: personalityTypeLoading }] =
    useMutation(Mutations.UPDATE_PERSONALITY_TYPE, { client: client });

  const updatePersonality = (e) => {
    swal({
      title: "Are you sure?",
      text: "The personality type is a one time selection and cannot be reverted. Click yes to confirm or cancel to clear.",
      icon: "success",
      buttons: ["Cancel", "Yes"],
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        const call = async () => {
          try {
            const res = await updatePersonalityType({
              variables: { personalityType: perType },
            });
            console.log(res, "res");
            setPersonality(res.data.updatePersonalityType.personalityType);
            dispatch(
              prsonalityType(res.data.updatePersonalityType.personalityType)
            );
            message.success("Personality Type has been updated successfully!");
            props.getUserProfile({ variables: { userId: GetUserIdFromUrl() } });
          } catch (error) {
            console.log("Err > ", error);
          }
        };
        call();
      }
    });
  };

  const handleIsReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const handleIsReadLess = () => {
    setIsReadMore(!isReadMore);
  };

  const isReadMoreButton = () => {
    if (divHeight === 63 && isReadMore === true) {
      return (
        <Button type="primary" onClick={handleIsReadMore}>
          Read more
        </Button>
      );
    } else if (divHeight > 63 && isReadMore === false) {
      return (
        <Button type="primary" onClick={handleIsReadLess}>
          Read less
        </Button>
      );
    }
  };

  return (
    <Container style={{ marginTop: 160 }}>
      <Row style={{ margin: "60px 0" }}>
        <Col xs={{ span: 24 }} md={{ span: 14 }} style={{ paddingRight: 50 }}>
          <h3>About:</h3>

          <div
            className={
              isReadMore
                ? "about-text-container"
                : "about-text-container-expanded"
            }
          >
            {showCount ? (
              <TextArea
                id="bio-text-area"
                value={userBio}
                onChange={(e) => setUserBio(e.target.value)}
                maxLength={500}
                onBlur={() => {
                  setShowCount(false);
                  bioRef.current !== userBio && updateBio();
                }}
                showCount
                autoFocus={true}
                rows={8}
              />
            ) : (
              <p>
                {userBio}
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <EditOutlined
                    onClick={() => {
                      setShowCount(true);
                    }}
                  />
                )}
              </p>
            )}
          </div>
        </Col>

        <Col xs={{ span: 24 }} md={{ span: 9 }}>
          <>
            <h3>Personal Information:</h3>
            <div>
              <h5 className="mb-0">Date of Birth:</h5>
              <h6 className="text-secondary">{birthday && birthday}</h6>
            </div>
            <div className="my-4">
              <h5 className="mb-0">Personality Type:</h5>
              <div>
                {console.log("personality", personality)}
                {/* {console.log("authForPublic", authForPublic)}
                                {console.log("props", props)} */}
                {/* {personality === "" ? ( */}
                {pstate === null || personality === null ? (
                  <>
                    <div>
                      <Select
                        placeholder="Select your personality type"
                        style={{ width: 200, marginTop: 5, marginRight: 5 }}
                        onChange={(e) => setPerType(e)}
                      >
                        {personalityTypeOptions.map((item, index) => {
                          return (
                            <Option key={index} value={item.value}>
                              {item.value}
                            </Option>
                          );
                        })}
                      </Select>
                      <Button
                        onClick={updatePersonality}
                        type="primary"
                        loading={personalityTypeLoading}
                        disabled={perType == null ? "disabled" : ""}
                      >
                        Confirm
                      </Button>
                    </div>
                    <br />
                    <p>
                      Please click
                      <Typography.Link
                        href="https://www.16personalities.com/"
                        target="_blank"
                      >
                        {" "}
                        here,{" "}
                      </Typography.Link>
                      to verify your personality type.
                    </p>
                  </>
                ) : (
                  <h6 className="text-secondary">{personality || pstate}</h6>
                )}
              </div>
            </div>
            <div>
              <h5 className="mb-0">Gender:</h5>
              <h6 className="text-secondary">
                {gender &&
                  gender[0].toUpperCase() + gender.slice(1, gender.length)}
              </h6>
            </div>
          </>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutContent;
