import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./Navbar.css";
// import {ReactComponent as EmergeLogoNav} from './images/emg-yp-white-bg-logo.svg';
import EmergeLogoNav from "../assets/logo for Youth.svg";
import { FaTimes, FaBars } from "react-icons/fa";
import { connect, useSelector } from "react-redux";
import { getCookie, removeCookie } from "../helpers";
import { Avatar, Dropdown, Row, Col, Menu, Spin, Divider } from "antd";
import styled from "styled-components";
// import {menu} from '../components/header/Header'
import { MdHome } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import { useMutation, useQuery } from "@apollo/client";
import { client } from "../appollo_client/client";
import Mutations from "../appollo_client/Mutations";
import Queries from "../appollo_client/Queries";
import GetUserIdFromUrl from "../GetUserIdFromUrl";
import { userNotAuthenticatedUpdate } from "../redux/actions/authenticate";

const Navbar = (props) => {
  const { profileData } = props;
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [userProfileData, setUserProfileData] = useState("");

  const fname = useSelector((state) => state.userReducer.profileData.firstName);
  console.log("fname", fname);


  const appStatus = getCookie("appStatus");
  // console.log("stateuk", appStatus);

  const history = useHistory();
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  // console.log("userProfileData 1 >", userProfileData);

  const firstLatter =
    userProfileData.firstName &&
    userProfileData.firstName.slice(0, 1).toUpperCase();
  const lastLatter =
    userProfileData.lastName &&
    userProfileData.lastName.slice(0, 1).toUpperCase();

  const userId = userProfileData && userProfileData._id;

  const userIdFromCookie = getCookie("userId");
  // console.log("Get user Id", userIdFromCookie);

  // ==============GET_USER_BY_ID===========
  // const { data: userData, loading: userDataLoading } = useQuery(
  //   Queries.GET_USER_BY_ID,
  //   {
  //     variables: { userId: userIdFromCookie || '60c3564adfd38e49b622b1df' },
  //     client: client,
  //     nextFetchPolicy: "cache-first",
  //     fetchPolicy: "cache-and-network",
  //     onCompleted: () => {
  //       setUserProfileData(userData.getUserById);
  //     },
  //     onError: () => {
  //       console.log("error in get user By id");
  //     },
  //   }
  // );

  // ==================Log out Mutation============
  const [logout, { data, loading, error }] = useMutation(Mutations.LOG_OUT, {
    client: client,
  });

  const handleLogOut = async () => {
    // const deviceId = localStorage.getItem("deviceId");
    const deviceId = getCookie("deviceId");
    // console.log("Checking handle log out", deviceId);
    try {
      const res = await logout({
        variables: {
          deviceId: deviceId,
        },
      });
      // localStorage.removeItem("email");
      removeCookie("email");
      // localStorage.removeItem("countryCode");
      removeCookie("countryCode");
      props.logOut(res.data, history);
    } catch (error) {
      console.log("Error >>", error.message);
    }
  };

  const menu = (
    <div
      style={{
        background: "#fff",
        borderRadius: "6px",
        width: "200px",
        padding: "10px 0 0px 0",
        boxShadow: "0px 0px 3px 0px #949492",
        wordBreak: "break-all",
      }}
    >
      <Row>
        <Col span={24}>
          <div
            style={{
              margin: "5px 10px 5px 15px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            {/* {console.log("Name here --- >", userProfileData)} */}
            <span style={{ fontSize: "1.05rem", fontWeight: "600" }}>
              {`${userProfileData.firstName
                ?.slice(0, 1)
                .toUpperCase()}${userProfileData.firstName?.slice(
                1,
                userProfileData.firstName.length
              )} `}
            </span>
            <span style={{ fontSize: "0.8rem" }}>{userProfileData.email}</span>
          </div>
        </Col>
        <Col
          style={{
            background: "#ccc",
            height: "1px",
            opacity: 0.3,
            margin: "5px 0",
            boxShadow: "0px 0px 2px 0px #ccc",
          }}
          span={24}
        ></Col>
        <Col span={24}>
          <Menu className="avatar-dropdown-menu">
            <Menu.Item
              icon={<MdHome style={{ fontSize: 18, marginRight: "3px" }} />}
            >
              <Link
                to={`/${userIdFromCookie}/dashboard`}
                onClick={() => closeMobileMenu(0)}
              >
                <span rel="noopener noreferrer">Home</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              icon={<IoSettings style={{ fontSize: 18, marginRight: "3px" }} />}
            >
              <Link to={`/${userIdFromCookie}/settings`} onClick={handleClick}>
                <span rel="noopener noreferrer">Settings</span>
              </Link>
            </Menu.Item>


            {appStatus != "approved" ? (
              <Menu.Item
                style={{ display: "none" }}
                icon={
                  <IoSettings
                    style={{
                      fontSize: 18,
                      marginRight: "3px",
                    }}
                  />
                }
              >
                <Link to={`/${userId}/settings`} onClick={handleClick}>
                  <span rel="noopener noreferrer">Settings</span>
                </Link>
              </Menu.Item>
            ) : (
              <Menu.Item
                icon={
                  <IoSettings style={{ fontSize: 18, marginRight: "3px" }} />
                }
              >
                <Link to={`/${userId}/settings`} onClick={handleClick}>
                  <span rel="noopener noreferrer">Settings</span>
                </Link>
              </Menu.Item>
            )}

          </Menu>
        </Col>
        <Col span={24}>
          <div
            onClick={handleLogOut}
            style={{
              margin: "10px 0",
              width: "100%",
              height: "40px",
            }}
          >
            <div className="logout-container">
              <span>Logout</span> {loading ? <Spin size="small" /> : ""}{" "}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <div className="navbar1">
        <div className="navbar-container">
          <HashLink
            to="/#home"
            className="navbar-logo"
            onClick={closeMobileMenu}
          >
            <div
              style={{ width: "100px", height: "100px", marginLeft: "-10px" }}
            >
              <img
                src={EmergeLogoNav}
                style={{ width: "100%", height: "100%" }}
                alt="logo"
              />
            </div>
          </HashLink>
          <div className="menu-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"} /> */}
            {click ? <FaTimes /> : <FaBars />}
          </div>
          {getCookie("token") ? (
            <MobileMenuDiv>
              <ul className={click ? "nav-menu active" : "nav-menu"}>
                <MobileMenu
                  onClick={() => {
                    history.push(`${userId}/dashboard`);
                  }}
                >
                  <AvatarRow
                    align="middle"
                    style={{ justifyContent: "center" }}
                  >
                    <Col xs={4} sm={4} md={4} style={{ textAlign: "center" }}>
                      <Avatar size={50} src={userProfileData.profileImage} />
                    </Col>
                    <Col offset={2} xs={9} sm={9} md={8}>
                      <div style={{ display: "grid" }}>
                        <UserName span={24}>
                          <StyledParagraphs>
                            {userProfileData.firstName}{" "}
                            {userProfileData.lastName}
                          </StyledParagraphs>
                        </UserName>
                        <Col span={24}>
                          <StyledParagraphs>
                            {userProfileData.email}
                          </StyledParagraphs>
                        </Col>
                      </div>
                    </Col>
                  </AvatarRow>
                  <Row align="middle">
                    <Col span={24}>
                      <MobileMenuDivider />
                    </Col>
                  </Row>
                </MobileMenu>

                <li className="nav-item-landing">
                  <HashLink
                    to="/#home"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Home
                  </HashLink>
                </li>
                <li className="nav-item-landing">
                  <HashLink
                    to="/#courses"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Courses
                  </HashLink>
                </li>
                <li className="nav-item-landing">
                  <HashLink
                    to="/#about"
                    // activeClassName="selected"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    About
                  </HashLink>
                </li>

                <li className="nav-item-landing">
                  <HashLink
                    to="/#info"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Contact
                  </HashLink>
                </li>

                <MobileCol span={24}>
                  <div
                    onClick={handleLogOut}
                    style={{
                      margin: "32px 0",
                      width: "100%",
                      height: "40px",
                    }}
                  >
                    <div className="logout-container">
                      <span>Logout</span> {loading ? <Spin size="small" /> : ""}{" "}
                    </div>
                  </div>
                </MobileCol>
              </ul>
            </MobileMenuDiv>
          ) : (
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item-landing">
                <HashLink
                  to="/#home"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Home
                </HashLink>
              </li>
              <li className="nav-item-landing">
                <HashLink
                  to="/#courses"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Courses
                </HashLink>
              </li>
              <li className="nav-item-landing">
                <HashLink
                  to="/#about"
                  // activeClassName="selected"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  About
                </HashLink>
              </li>

              <li className="nav-item-landing">
                <HashLink
                  to="/#info"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Contact
                </HashLink>
              </li>

              <li>
                <Link
                  to="/sign-in"
                  target="_blank"
                  className="nav-links-mobile"
                  onClick={closeMobileMenu}
                >
                  Sign in
                </Link>
                {/* </Link> */}
              </li>
            </ul>
          )}
          {/* {console.log("IS LOGEDIN > ", props.isLoggedIn)} */}
          {!getCookie("token") && button && (
            <Link to="/sign-in" style={{ textDecoration: "none" }}>
              <div className="signin-container">
                <span style={{ fontWeight: "600" }}>Sign in</span>
              </div>
            </Link>
          )}
          {getCookie("token") && button && (
            // <div style={{height:'40px',width:'40px',borderRadius:'20px',backgroundColor:'green'}}>

            // </div>
            <div
              className="profile-image-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {/* <UserIcon className='px-5'> */}
              {/* <Dropdown
                  trigger={["click"]}
                  overlay={menu}
                  placement="bottomRight"
                  arrow
              > */}
              {/* {console.log("Letter > ", firstLatter)} */}
              {/* <Avatar className="header-avatar" onClick={()=>window.location.href= `${getCookie('userId')}/dashboard`}>
                      {firstLatter}
                      {lastLatter}
                  </Avatar> */}
              <Dropdown
                trigger={["click"]}
                overlay={menu}
                placement="bottomRight"
                arrow
              >
                <AvatarWrapper className="header-avatar">
                  {firstLatter}
                  {lastLatter}
                </AvatarWrapper>
              </Dropdown>
              {/* </Dropdown> */}
              {/* </UserIcon> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.userAuthenticateReducer.isLogin,
    userProfileData: state.userProfileDataReducer.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: (data, history) =>
      dispatch(userNotAuthenticatedUpdate(data, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

const MobileMenuDiv = styled.div`
  @media (max-width: 960px) {
    .nav-menu.active {
      background-color: #292929;
    }
    .nav-links {
      background-color: #292929;
      color: white;
    }
  }
`;

const AvatarWrapper = styled(Avatar)`
  :hover {
    cursor: pointer;
  }
`;

const MobileMenuDivider = styled(Divider)`
  margin: 0px;
  @media (max-width: 960px) {
    background-color: #efa718;
  }
  @media (min-width: 961px) {
    display: none;
  }
`;

const AvatarRow = styled(Row)`
  margin: 24px;
  @media (min-width: 961px) {
    display: none;
  }
`;

const StyledParagraphs = styled.p`
  margin-bottom: 0px;
  margin-top: 3px;
  color: white;
  @media (max-width: 960px) {
    float: left;
  }
`;

const UserName = styled(Col)`
  @media (max-width: 960px) {
    float: left;
  }
`;

const MobileMenu = styled.div`
  text-align: center;
  cursor: pointer;
  @media (min-width: 961px) {
    display: none;
  }
`;

const MobileCol = styled(Col)`
  @media (min-width: 961px) {
    display: none;
  }
`;
