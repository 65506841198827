import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Tabs, Row, Col, Input, Empty } from "antd";
import styled from "styled-components";
import CoursesCard from "../components/CoursesCard";

import Queries from "../appollo_client/Queries.js";
import { useMutation, useQuery, useLazyQuery } from "react-apollo";

import CoursesCard1 from "../components/CoursesCard1";

import { Spinner } from "../components/spinner";
import AboutHeader from "../components/AboutHeader";
import SearchBar from "../components/SearchBar";
import PaginationComp from "../components/PaginationComp";
import UserBanner from "../components/banner/userBanner";
import { client } from "../appollo_client/client";
import { useSelector, useDispatch } from "react-redux";
import {
  updateRegisteredCourses,
  getCourseCardId,
  getUserApprovedCoursesData,
} from "../redux/actions/registeredCourses";

const { TabPane } = Tabs;

const TabsNav = styled(Tabs)`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab {
    padding: 12px 20px;
    font-size: 15px;
  }
  .ant-tabs-ink-bar {
    background: #efa718 !important;
    height: 3px !important;
  }
  .ant-tabs-nav-list {
    position: relative;
    left: 99px;
  }
  @media (max-width: 960px) {
    .ant-tabs-nav-list {
      left: 0px;
    }
    .ant-tabs-nav {
      display: grid;
    }
  }
`;

const CardsContainer = styled.div`
  padding: 20px 10px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-item: center;
  @media (max-width: 999px) {
    justify-content: center !important;
  }
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
`;

const Courses = (props) => {
  const dispatch = useDispatch();

  const getUserApprovedCoursesReducer = useSelector(
    (state) => state.registeredCourse.getUserApprovedCourses[0]
  );

  console.log(getUserApprovedCoursesReducer, "getUserApprovedCoursesReducer");

  const allCourses = useSelector((state) => state.getAllCourses.allCourses);

  const [courseData, setCourseData] = useState([]);

  const [getAllCourses, { loading, error }] = useLazyQuery(
    Queries.GET_ALL_COURSES,
    {
      onCompleted: (data) => {
        setCourseData(data.getAllCourses);
        dispatch({
          type: "GET_ALL_COURSES",
          payload: data.getAllCourses,
        });
      },
      onError: () => {
        console.log(error.message);
      },
    }
  );
  useEffect(() => {}, [getAllCourses, search]);

  // const [getAllCourses, { loading, error }] = useLazyQuery(
  //   Queries.GET_ALL_COURSES,
  //   {
  //     onCompleted: (data) => {
  //       setCourseData(data.getAllCourses);
  //       dispatch({
  //         type: "GET_ALL_COURSES",
  //         payload: data.getAllCourses,
  //       });
  //     },
  //     onError: () => {
  //       console.log(error.message);
  //     },
  //   }
  // );
  // useEffect(() => getAllCourses(), []);

  //step1
  const [search, setSearch] = useState("");

  //step3
  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  //step 4
  const filtered = (search) => (f) =>
    f.courseId.courseName?.toLowerCase().includes(search);

  /* This useEffect is used to filter data through inputbox */
  useEffect(() => {
    const filteredData = allCourses?.filter((value) =>
      value.courseName.toLowerCase().includes(search.toLowerCase())
    );
    setCourseData(filteredData);
  }, [search]);

  const operations = (
    <SearchBar
      showSearch={true}
      placeholder="Search courses"
      onChange={handleSearchChange}
      allowClear
      value={search}
    />
  );

  const numEachArticleVideo = 9;
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(9);
  const [currentValue, setCurrentValue] = useState(1);

  const paginationHandleChange = (value) => {
    console.log("count", value);
    setCurrentValue(value);
    setMinValue((value - 1) * numEachArticleVideo);
    setMaxValue(value * numEachArticleVideo);
  };

  const [registeredCourseData, setRegisteredCourseData] = useState();

  const { data: registeredCoursesData, error: errors } = useQuery(
    Queries.GET_USER_APPROVED_COURSES,
    {
      client: client,
      onCompleted: () => {
        setRegisteredCourseData(registeredCoursesData);
        dispatch(updateRegisteredCourses(registeredCoursesData));
      },
      onError() {
        console.log(errors);
      },
    }
  );

  const registeredCourse = useSelector((state) => state);
  console.log(registeredCourse, "registeredCourseReducer");

  dispatch(getCourseCardId(courseData));

  const [allUserCourses, setAllUserCourses] = useState();

  console.log(allUserCourses, "allUserCourses");

  const [
    getAllUserCourses,
    { loading: getAllUserCoursesLoading, error: getAllUserCoursesError },
  ] = useLazyQuery(Queries.GET_ALL_USER_COURSES, {
    client: client,
    onCompleted: (data) => {
      console.log("getAllUserCoursesData", data.getAllUserCourses);
      setAllUserCourses(data.getAllUserCourses);
    },
    onError: () => {
      console.log(getAllUserCoursesError.message);
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => getAllUserCourses(), []);

  const [userApprovedData, setUserApprovedData] = useState();
  console.log(userApprovedData, "userApprovedData");

  const [
    getUserApprovedCourses,
    {
      loading: getUserApprovedCoursesLoading,
      error: getUserApprovedCoursesError,
    },
  ] = useLazyQuery(Queries.GET_USER_APPROVED_COURSES, {
    onCompleted: (data) => {
      console.log("getUserApprovedData", data);
      setUserApprovedData(data?.getUserApprovedCourses);
      dispatch(getUserApprovedCoursesData(data?.getUserApprovedCourses));
    },
    onError: () => {
      console.log(getUserApprovedCoursesError.message);
    },
  });
  useEffect(() => getUserApprovedCourses(), []);

  const [minApprovedValue, setMinApprovedValue] = useState(0);
  const [maxApprovedValue, setMaxApprovedValue] = useState(9);
  const [currentApprovedValue, setCurrentApprovedValue] = useState(1);

  const paginationApprovedHandleChange = (value) => {
    console.log("count", value);
    setCurrentApprovedValue(value);
    setMinApprovedValue((value - 1) * numEachArticleVideo);
    setMaxApprovedValue(value * numEachArticleVideo);
  };

  useEffect(() => {}, [numEachArticleVideo, minValue, maxValue, currentValue]);

  return (
    <div>
      <UserBanner />
      {/* <AboutHeader /> */}
      <Container style={{ marginTop: 160 }}>
        <TabsNav defaultActiveKey="1" tabBarExtraContent={operations}>
          <TabPane tab="All Courses" key="1">
            {getAllUserCoursesLoading ? (
              <Spinner
                size="large"
                style={{ textAlign: "center", marginTop: 24, display: "block" }}
              />
            ) : (
              <Row justify="left" align="middle">
                {allUserCourses?.length === 0 ? (
                  <CardsContainer
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Empty align="center"></Empty>
                  </CardsContainer>
                ) : (
                  <>
                    {allUserCourses
                      ?.slice(minValue, maxValue)
                      ?.filter(filtered(search))
                      .map((item, index) => (
                        <Col
                          xl={8}
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                          key={index}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <CoursesCard
                            key={index}
                            data={item}
                            registeredCourse={registeredCourse}
                            registeredCourseData={registeredCourseData}
                            history={props.history}
                          />
                        </Col>
                      ))}
                  </>
                )}

                <Row
                  style={{
                    margin: 25,
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <PaginationComp
                    defaultCurrent={currentValue}
                    current={currentValue}
                    total={allUserCourses?.length}
                    defaultPageSize={numEachArticleVideo}
                    onChange={paginationHandleChange}
                  />
                </Row>
              </Row>
            )}
          </TabPane>
          <TabPane tab="My Courses" key="2">
            {getUserApprovedCoursesLoading ? (
              <Spinner
                size="large"
                style={{ textAlign: "center", marginTop: 24, display: "block" }}
              />
            ) : (
              <Row justify="left" align="middle">
                {getUserApprovedCoursesReducer?.length === 0 ? (
                  <CardsContainer
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Empty align="center"></Empty>
                  </CardsContainer>
                ) : (
                  <>
                    {getUserApprovedCoursesReducer
                      ?.slice(minApprovedValue, maxApprovedValue)
                      ?.filter(filtered(search))
                      .map((item, index) => (
                        <Col
                          xl={8}
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                          key={index}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <CoursesCard1
                            key={index}
                            data={item}
                            registeredCourse={registeredCourse}
                            registeredCourseData={registeredCourseData}
                            history={props.history}
                          />
                        </Col>
                      ))}
                  </>
                )}

                <Row
                  style={{
                    margin: 25,
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <PaginationComp
                    defaultCurrent={currentApprovedValue}
                    current={currentApprovedValue}
                    total={getUserApprovedCoursesReducer?.length}
                    defaultPageSize={numEachArticleVideo}
                    onChange={paginationApprovedHandleChange}
                  />
                </Row>
              </Row>
            )}
          </TabPane>
        </TabsNav>
      </Container>
    </div>
  );
};

export default Courses;
