import { COURSES } from "../constants/userConstants";

export const getRegisteredCourses = (data) => {
  console.log(data, "getRegisteredCoursesActionCreator");
  return { type: COURSES.GET_REGISTERED_COURSES, payload: data };
};

export const updateRegisteredCourses = (data) => {
  console.log(data, "updateRegisteredCoursesActionCreator");
  return { type: COURSES.UPDATE_REGISTERED_COURSES, payload: data };
};

export const getCourseCardId = (data) => {
  console.log(data, "getCourseCardIdActionCreator");
  return { type: COURSES.GET_COURSE_CARD_ID, payload: data };
};

export const getUserApprovedCoursesData = (data) => {
  console.log(data, "getUserApprovedCoursesDataFromActionCreator");
  return { type: COURSES.GET_USER_APPROVED_COURSES_DATA, payload: data };
};
