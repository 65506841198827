import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Signin from "./views/Signin";
import Signup from "./views/Signup";
import ResetPassword from "./pages/ResetPassword";
import LandingPage from "./views/LandingPage";
import Shell from "./views/shell/Shell";
import NotFound from "./views/NotFound.js";

import { connect, useDispatch, useSelector } from "react-redux";
import { useQuery, useLazyQuery } from "@apollo/client";

import { client } from "./appollo_client/client";
import Queries from "./appollo_client/Queries";
import swal from "sweetalert";
/*-------------------------*/
/* Importing Public Routes */
/*-------------------------*/
import PublicMainNavbar from "./components/PublicMainNavbar";
import PublicAboutHeader from "./components/PublicAboutHeader";
import PublicFooter from "./components/footer/publicFooter";
import PublicProfile from "./views/PublicProfile.js";
import PublicPortfolio from "./views/PublicPortfolio";
import PublicRecentActivity from "./views/PublicRecentActivities";
import { setCookie, getCookie } from "./helpers";
import GetUserIdFromUrl from "./GetUserIdFromUrl";
import { verifyLoggedUser } from "./redux/actions/verifyLoggedUser";

const Unauthorized = () => (
  <Switch>
    <Route exact path="/" component={LandingPage} />
    <Route exact path="/sign-in" component={Signin} />
    <Route exact path="/sign-up" component={Signup} />
    <Route exact path="/reset-password" component={ResetPassword} />
    <Route path="*" component={NotFound} />
  </Switch>
);

const PublicRoutes = () => {
  const history = useHistory();
  const [profileData, setProfileData] = useState([]);
  const [userPortfolioData, setPortfolioData] = useState([]);

  /* Getting user data by id */
  const { loading: loadingUserProfile, error } = useQuery(
    Queries.GET_USER_BY_ID,
    {
      variables: { userId: GetUserIdFromUrl() },
      onCompleted: (data) => {
        setProfileData(data.getUserById);
      },
      onError: (e) => {
        if (e.graphQLErrors) {
          swal("Error", "User not Found", "error").then(() =>
            history.push("/")
          );
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      client,
    }
  );

  /* Query to get User Portfolio Data */
  const { loading: loadingUserPortfolio, error: userPortfolioError } = useQuery(
    Queries.GET_USER_PORTFOLIO,
    {
      variables: { userId: GetUserIdFromUrl() },
      onCompleted: (data) => {
        setPortfolioData(data && data.getUserPortfolio);
      },
      onError: () => console.log("userPortfolioError", userPortfolioError),
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      client,
    }
  );

  return (
    <>
      <PublicMainNavbar profileData={profileData} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "10vh",
        }}
      >
        <PublicAboutHeader data={profileData} />
        <Switch>
          <Route exact path="/:id/dashboard">
            <PublicProfile
              profileData={profileData}
              loading={loadingUserProfile}
            />
          </Route>

          <Route exact path="/:id/portfolio">
            <PublicPortfolio
              userPortfolioData={userPortfolioData}
              loading={loadingUserPortfolio}
            />
          </Route>

          <Route
            exact
            path="/:id/recent-activity"
            component={PublicRecentActivity}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
      {/* <PublicFooter /> */}
    </>
  );
};

const Routes = ({ isLoggedIn }) => {
  // const authenticated = localStorage.getItem("isLogin");
  const authenticated = getCookie("isLogin");
  const history = useHistory();

  const dispatch = useDispatch();

  const reducerOfVerifyLoggedUser = useSelector(
    (state) => state.verifyLoggedUser.error
  );
  console.log("ReducerVerifyLoggedUser", reducerOfVerifyLoggedUser);

  const token = getCookie("token");
  const userId = getCookie("userId");
  const id = GetUserIdFromUrl();

  console.log("ResponseDataId", id, token);

  const [dataVerifiedUser, setDataVerifiedUser] = useState();
  const [errorVerifiedUser, setErrorVerifiedUser] = useState();

  // /* Getting verified logged user */
  // const [
  //   loggedUserFunc,
  //   { data: loggedUserData, loading: userDataLoading, error },
  // ] = useLazyQuery(Queries.VERIFY_LOGGED_USER, {
  //   variables: { userId: userId, token: token },
  //   onCompleted: () => {
  //     setDataVerifiedUser(loggedUserData);
  //   },
  //   onError: (e) => {
  //     setErrorVerifiedUser(e);
  //     dispatch(verifyLoggedUser(e));
  //   },
  //   fetchPolicy: "network-only",
  // });

  // useEffect(() => {
  //   loggedUserFunc();
  // }, [reducerOfVerifyLoggedUser, dataVerifiedUser]);

  return (
    <Switch>
      <Route exact path="/sign-in" component={Signin} />
      <Route exact path="/sign-up" component={Signup} />
      <Route exact path="/reset-password" component={ResetPassword} />

      {authenticated != "true" ? (
        <Route path="/:id" component={PublicRoutes}>
          <PublicRoutes />
        </Route>
      ) : null}

      <Route path="/">
        {authenticated == "true" ? <Shell /> : <Unauthorized />}
      </Route>
    </Switch>
  );
};

const mapState = (state) => ({
  isLoggedIn: state.userAuthenticateReducer.isLogin,
});

export default connect(mapState)(Routes);
