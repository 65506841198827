import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav, Container, Image, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Result, Button } from "antd";
import { useHistory, Link } from "react-router-dom";
import { getCookie } from "../helpers";

const NotFound = (props) => {
  const history = useHistory();

  // console.log("Props NotFound > ", props);

  let userUrlId = getCookie("userId");

  React.useEffect(() => {
    if (props.userData && userUrlId) {
      props.userData.getUserById?.applicationStatus !== "approved"
        ? history.push(`/${userUrlId}/steps`)
        : history.push(`/${userUrlId}/dashboard`);
    }
  }, []);

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <a href="/">
          <Button
            type="primary"
          // onClick={() => history.push(`/`)}
          >
            Back Home
          </Button>
        </a>
      }
    />
  );
};

export default NotFound;
