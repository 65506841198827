import React, { useState } from "react";
import { Avatar, Row, Col, Badge, message, Form } from "antd";
import HeaderLoading from "../HeaderLoading";
import { useMutation } from "react-apollo";
import Mutations from "../../appollo_client/Mutations";
import Styled from "styled-components";
import { TiCameraOutline } from "react-icons/ti";
import cogoToast from "cogo-toast";
import { connect, useSelector } from "react-redux";

const UserBanner = (props) => {
  const {
    profileData, // user state
    changeCoverImage, // dispatchers ...
    changeProfileImage,
    removeCoverImage,
    removeProfileImage,
  } = props;

  const [myForm] = Form.useForm();

  const { firstName, lastName, city, gender, profileImage, coverImage } =
    profileData;

  const fname = useSelector((state) => state.userReducer.profileData.firstName);
  console.log("fname", fname);

  const lname = useSelector((state) => state.userReducer.profileData.lastName);
  console.log("lname", lname);

  const getNameInTitleCase = (firstName, lastName) => {
    const first = firstName[0]?.toUpperCase() + firstName.slice(1);
    const last = lastName[0]?.toUpperCase() + lastName.slice(1);
    return `${first} ${last}`;
  };

  /**
   * Mutation to update user profile image
   */
  const [updateProfileImage, { loading: profileImageLoading }] = useMutation(
    Mutations.UPDATE_PROFILE_IMAGE
  );
  /**
   * Mutation to update user cover image
   */
  const [updateCoverImage, { loading: coverImageLoading }] = useMutation(
    Mutations.UPDATE_COVER_IMAGE
  );

  /**
   * State declarations
   */
  const [imageLoading, setImageLoading] = useState(false);
  const [imageHover, setImageHover] = useState(false);
  const [coverImageHover, setCoverImageHover] = useState(false);
  const [coverHover, setCoverHover] = useState(false);

  const uploadToCloudinary = async (file) => {
    const data = { file, upload_preset: "emergeForward" };
    setImageLoading(true);
    try {
      const uploadData = await fetch(
        "https://api.cloudinary.com/v1_1/emergeyouthprogram/image/upload",
        {
          body: JSON.stringify(data),
          headers: { "content-type": "application/json" },
          method: "POST",
        }
      );
      const response = await uploadData.json();
      //   console.log("Response from Cloudinary", response);
      setImageLoading(false);
      return response;
    } catch (err) {
      setImageLoading(false);
      console.log("Error when uploading to cloudinary", err);
      return { error: "Can't upload image" };
    }
  };

  /**
   * Handler to change cover image and profile image
   * typeof @e is event from file input
   * @uploadType is string set in function call
   */
  const handleImageChange = (e, uploadType) => {
    const { files } = e.target;

    if (files && files[0]) {
      console.log("Files", files[0]);

      if (
        files[0].type != "image/png" &&
        files[0].type != "image/jpg" &&
        files[0].type != "image/jpeg"
      ) {
        cogoToast.warn("The file should be png/jpg");
        return;
      }

      let reader = new FileReader();

      reader.onload = (event) => {
        // For calculating image resolution
        var image = new Image();
        image.src = event.target.result;

        image.onload = async function () {
          /**
           * This refers to the img element
           * This keyword only work when using function keyword instead of arrow notation
           */
          if (
            uploadType == "coverImage" &&
            (this.height < 312 || this.width < 820)
          ) {
            cogoToast.warn("Height and Width must be atleast 312x820.");
          } else {
            /**
             * Uploading image to cloudinary
             */
            const response = await uploadToCloudinary(event.target.result);
            if (response.error) {
              console.log("Error response", response.error);
              cogoToast.error(response.error);
            } else {
              /**
               * When changing cover image
               */
              if (uploadType == "coverImage") {
                try {
                  const res = await updateCoverImage({
                    variables: { coverImage: response.secure_url },
                  });

                  changeCoverImage(res.data.updateCoverImage.coverImage);
                  message.success("Cover image has been updated successfully!");
                } catch (err) {
                  console.log("GraphQL error", err);
                }
              }
              /**
               * When changing profile image
               */
              if (uploadType == "profileImage") {
                try {
                  const res = await updateProfileImage({
                    variables: { profileImage: response.secure_url },
                  });

                  changeProfileImage(res.data.updateProfileImage.profileImage);
                  message.success(
                    "Profile image has been updated successfully!"
                  );
                } catch (err) {
                  console.log("GraphQL error", err);
                }
              }
            }
          }
        };
      };

      reader.readAsDataURL(files[0]);
    } else {
      cogoToast.error("Something went wrong.");
    }
  };

  /**
   * Handle cover remove
   */
  const handleRemoveCover = async () => {
    const coverImage =
      "https://res.cloudinary.com/emergeyouthprogram/image/upload/v1625477132/emerge_forwar/sexwfupr8wvyhthkylay.svg";
    try {
      await updateCoverImage({
        variables: { coverImage },
      });
      removeCoverImage(coverImage);
      message.success("Cover image has been removed successfully!");
    } catch (error) {
      message.error("Some thing went wrong!");
    }
  };

  /**
   * Handle remove profile image
   */
  const handleRemoveProfileImage = async () => {
    const profileImage =
      gender && gender.toUpperCase() === "MALE"
        ? "https://png.pngtree.com/png-clipart/20190924/original/pngtree-businessman-user-avatar-free-vector-png-image_4827807.jpg"
        : "https://www.shareicon.net/data/512x512/2016/09/15/829446_user_512x512.png";

    try {
      await updateProfileImage({
        variables: {
          profileImage,
        },
      });
      removeProfileImage(profileImage);
      message.success("Profile image has been removed successfully!");
    } catch (error) {
      message.error("Some thing went wrong!");
    }
  };

  const hasDP = () => {
    return (
      profileImage !==
        "https://png.pngtree.com/png-clipart/20190924/original/pngtree-businessman-user-avatar-free-vector-png-image_4827807.jpg" &&
      profileImage !==
        "https://www.shareicon.net/data/512x512/2016/09/15/829446_user_512x512.png"
    );
  };

  const hasCover = () => {
    return (
      coverImage !==
      "https://res.cloudinary.com/emergeyouthprogram/image/upload/v1625477132/emerge_forwar/sexwfupr8wvyhthkylay.svg"
    );
  };

  return (
    <div
      style={{
        background: ` linear-gradient(to bottom right, #808080 , #292929 80%)`,
        marginBottom: 80,
      }}
    >
      <Row
        onMouseEnter={() => setCoverImageHover(true)}
        onMouseLeave={() => setCoverImageHover(false)}
        justify="center"
        style={{
          backgroundImage: `url(${coverImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <Col
          style={{
            textAlign: "center",
            margin: "30px 0",
            position: "relative",
            top: "160px",
          }}
        >
          {imageLoading || profileImageLoading || coverImageLoading ? (
            <HeaderLoading />
          ) : (
            <>
              <Badge
                count={
                  <div>
                    <div
                      onMouseEnter={() => setImageHover(true)}
                      onMouseLeave={() => setImageHover(false)}
                      className="camera-logo-container"
                    >
                      <Label>
                        <input
                          type="file"
                          onChange={(e) => handleImageChange(e, "profileImage")}
                          accept="image/*"
                        />
                        <TiCameraOutline className="profile-image-icon" />
                      </Label>
                    </div>

                    {console.log("profileImageUk", profileImage)}

                    {hasDP() &&
                    profileImage !== undefined &&
                    profileImage !=
                      "https://www.shareicon.net/data/512x512/2016/09/15/829446_user_512x512.png" ? (
                      <div
                        onMouseEnter={() => setImageHover(true)}
                        onMouseLeave={() => setImageHover(false)}
                      >
                        <span
                          className={
                            imageHover ? "remove-text-hovered" : "remove-text"
                          }
                          onClick={handleRemoveProfileImage}
                        >
                          Remove
                        </span>
                      </div>
                    ) : null}
                  </div>
                }
              >
                {gender && gender.toUpperCase() === "MALE" ? (
                  <Avatar
                    onMouseEnter={() => setImageHover(true)}
                    onMouseLeave={() => setImageHover(false)}
                    size={150}
                    src={
                      profileImage
                        ? profileImage
                        : "https://png.pngtree.com/png-clipart/20190924/original/pngtree-businessman-user-avatar-free-vector-png-image_4827807.jpg"
                    }
                  />
                ) : (
                  <Avatar
                    size={150}
                    src={
                      profileImage
                        ? profileImage
                        : "https://www.shareicon.net/data/512x512/2016/09/15/829446_user_512x512.png"
                    }
                  />
                )}
              </Badge>
              <h4
                style={{
                  textAlign: "center",
                  color: "black",
                  marginTop: "10px",
                }}
              >
                {fname} {lname}
              </h4>
              <h6 style={{ textAlign: "center" }}>Student | {city}</h6>
            </>
          )}
        </Col>

        <div className="upload-container">
          <div
            className="upload-camera-logo-container"
            onMouseEnter={() => setCoverHover(true)}
            onMouseLeave={() => setCoverHover(false)}
            className="upload-camera-logo-container"
          >
            <div
              style={{
                width: "90%",
                margin: "2px auto",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Form form={myForm}>
                    <Form.Item name="my-input">
                      <input
                        type="file"
                        onChange={(e) => {
                          handleImageChange(e, "coverImage");
                          myForm.resetFields();
                        }}
                        accept="image/*"
                      />
                    </Form.Item>
                  </Form>
                  <TiCameraOutline
                    className={
                      coverHover
                        ? "upload-image-icon-hovered"
                        : "upload-image-icon"
                    }
                  />
                  <span
                    className={
                      coverHover
                        ? "upload-image-text-hovered"
                        : "upload-image-text"
                    }
                    style={{
                      marginLeft: "8px",
                      fontSize: "0.9rem",
                      color: "#fff",
                    }}
                  >
                    Change Cover
                  </span>
                </div>
              </Label>
            </div>
          </div>

          {hasCover() ? (
            <div>
              <span
                className={
                  coverImageHover
                    ? "upload-remove-text-hovered"
                    : "upload-remove-text"
                }
                onClick={handleRemoveCover}
              >
                Remove
              </span>
            </div>
          ) : null}
        </div>
      </Row>
    </div>
  );
};

const mapState = (state) => ({
  profileData: state.userReducer.profileData,
  garbage: state.userReducer.g,
});

const mapDispatch = (dispatch) => ({
  changeCoverImage: (imgURL) =>
    dispatch({ type: "SET_USER_COVER_IMAGE", payload: { coverImage: imgURL } }),
  changeProfileImage: (imgURL) =>
    dispatch({
      type: "SET_USER_PROFILE_IMAGE",
      payload: { profileImage: imgURL },
    }),
  removeCoverImage: (imgURL) =>
    dispatch({
      type: "REMOVE_USER_COVER_IMAGE",
      payload: { coverImage: imgURL },
    }),
  removeProfileImage: (imgURL) =>
    dispatch({
      type: "REMOVE_USER_PROFILE_IMAGE",
      payload: { profileImage: imgURL },
    }),
});

export default connect(mapState, mapDispatch)(UserBanner);

const Label = Styled.label`
    input[type="file"] {
        display: none;
    }
`;
