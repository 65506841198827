import React from 'react'
import { Card, Button, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import styled from "styled-components";

const ArticlePreviewCard = (props) => {
    const {title, description, images, link} = props.data
    return(
        <>
        <Card
            bodyStyle={{padding: '10px'}}
            hoverable
            style={{ width: 230,}}
            cover={<img alt="example" src={images[0]} height='100px' style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}} />}
            >
            <Card.Meta title={title} description={`${description}...`} />
        </Card>
        </>
    )
}

export default ArticlePreviewCard

const ReadMoreBtn = styled(Button) `
    font-size: 12px;
    font-weight: bold;
    border-color: #EFA718;
    border-radius: 20px !important;
    transition: ease 1s;

    :hover{
        color: black;
        background-color: #EFA718;
        transition: ease 1s;
        border-color: black;
    }
   
`