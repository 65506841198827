import React, { useState, useEffect } from "react";
import {
  Tabs,
  Button,
  Modal,
  Input,
  Form,
  Typography,
  Divider,
  Spin,
  message,
  Tooltip,
} from "antd";
import styled from "styled-components";
import { getLinkPreview, getPreviewFromContent } from "link-preview-js";
import ArticlePreviewCard from "../components/ArticlePreviewCard";
import VideoPreviewCard from "../components/VideoPreviewCard";
import Mutations from "../appollo_client/Mutations";
import Queries from "../appollo_client/Queries";
import { client } from "../appollo_client/client";
import { useMutation } from "@apollo/client";
import GetUserIdFromUrl from "../GetUserIdFromUrl";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  getAllPortFolioData,
  addPortfolioData,
} from "../redux/actions/portfolio";
import { useDispatch } from "react-redux";

const { TabPane } = Tabs;

const AddArticleVideoModal = (props) => {
  console.log("props ==>", props);
  const dispatch = useDispatch();
  const [typeUrl, setTypeUrl] = useState(1);
  const [articleLink, setArticleLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [linkData, setLinkData] = useState(null);
  const [submitData, setSubmitData] = useState();
  const [portfolioType, setPortfolioType] = useState(
    parseInt(typeUrl) === 1 ? "article" : "video"
  );
  const [wait, setWait] = useState(false);
  const errorText =
    "The link you provided is invalid, the emerge data could not be generated. To enable this, please input a valid link that could provide with the sufficient data.";
  useEffect(() => {
    if (props.addModal === false) {
      setArticleLink("");
      setLinkData(null);
      setLoading(false);
    }
  }, [props.addModal]);

  // =================Link preview ==================
  useEffect(() => {
    if (articleLink !== "" && isLink() && !articleLink.includes("github")) {
      autoLinkPreview();
    }
  }, [articleLink]);
  useEffect(() => {
    setPortfolioType(parseInt(typeUrl) === 1 ? "article" : "video");
  }, [typeUrl]);

  const autoLinkPreview = async (evt) => {
    setLoading(true);
    await getLinkPreview(
      `https://secret-ocean-49799.herokuapp.com/${articleLink}`
    ).then((data) => {
      // console.log(data, "data");
      setLinkData(data);
    });
    setLoading(false);
  };

  const [addUserPortfolio, { data, loading: addLinkLoading, error }] =
    useMutation(Mutations.ADD_USER_PORTFOLIO, { client: client });

  const handleSubmit = async () => {
    try {
      const res = await addUserPortfolio({
        variables: {
          portfolioUrl: articleLink,
          portfolioType: portfolioType,
          portfolioTitle: linkData.title ? linkData.title : "",
          description: linkData.description ? linkData.description : "",
        },
      });
      if (res.data) {
        console.log(res.data, "res in video modal");
        message.success(`${portfolioType} link has been added successfully!`);
        setArticleLink("");
        setLinkData(null);
        setSubmitData();
        // window.location.reload();
        // dispatch({
        //   type: "ADD_PORTFOLIO_DATA",
        //   payload: res.data.addUserPortfolio,
        // });
        props.setAddModal(false);
        dispatch(addPortfolioData(res.data.addUserPortfolio));
      }
    } catch (error) {
      // console.log(error.message, "error in video modal");
    }
  };

  // Implementing link validation
  const [imageError, setImageError] = useState(false);
  const handleOnLoad = () => {
    setImageError(false);
  };
  const handleOnError = () => {
    setImageError(true);
  };
  const isImage = () => {
    return (
      !imageError && submitData !== undefined && submitData?.images?.length > 0
    );
  };
  const isDisplayble = () => {
    if (parseInt(typeUrl) === 1) {
      if (
        isImage() &&
        submitData !== undefined &&
        submitData.description !== undefined
      ) {
        return true;
      } else {
        return false;
      }
    } else if (parseInt(typeUrl) === 2) {
      if (submitData !== undefined && isVideo()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const isVideo = () => {
    if (
      articleLink &&
      articleLink.slice(0, 32) === "https://www.youtube.com/watch?v="
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isLink = () => {
    return (
      articleLink &&
      (articleLink.slice(0, 8) === "https://" ||
        articleLink.slice(0, 8) === "https:\\\\")
    );
  };
  const isSubmittable = () => {
    if (parseInt(typeUrl) === 1) {
      return isDisplayble() && isLink() && !isVideo();
    } else if (parseInt(typeUrl) === 2) {
      return isLink() && isVideo();
    }
    return false;
  };

  // console.log("is Image >>> ", isImage());
  // console.log("is displayble >>> ", isDisplayble());
  // console.log("is Video >>> ", isVideo());
  // console.log("is link >>> ", isLink());
  // console.log("is submittable >>> ", isSubmittable());
  // console.log("link data >>> ", linkData);
  // console.log("portfolio url >>> ", articleLink);
  // console.log("portfolio type >>> ", portfolioType);
  // console.log("portfolioTitle >>> ", linkData?.title);
  // console.log("description >>> ", linkData?.description);
  return (
    <>
      <div>
        <img
          src={
            submitData &&
            submitData?.images?.length > 0 &&
            submitData?.images[0]
          }
          alt="checkImage"
          onLoad={handleOnLoad}
          onError={handleOnError}
          width="2"
          height="2"
          style={{ display: "none" }}
        />
      </div>
      <ModalWrapper
        visible={props.addModal}
        onCancel={() => props.setAddModal(false)}
        footer={[
          <AddBtn
            disabled={
              !isSubmittable() ||
              articleLink === "" ||
              articleLink === null ||
              articleLink.includes("github") ||
              loading
            }
            onClick={handleSubmit}
            key="submit"
            type="primary"
            loading={articleLink !== "" && isLink() && (loading || wait)}
          >
            Add
          </AddBtn>,
        ]}
      >
        <TabsNav defaultActiveKey="1" onChange={(e) => setTypeUrl(e)}>
          <TabPane
            disabled={articleLink ? "disabled" : ""}
            tab={<span>Article</span>}
            key="1"
          >
            <InputWrapper>
              <Form>
                <Form.Item>
                  <Input
                    value={articleLink}
                    placeholder="https://www.article-link.com/"
                    onChange={async (e) => {
                      setArticleLink(e.target.value);
                      if (!e.target.value.includes("github")) {
                        setWait(true);
                        await getLinkPreview(
                          `https://secret-ocean-49799.herokuapp.com/${e.target.value}`
                        ).then((data) => {
                          setSubmitData(data);
                          setWait(false);
                        });
                      }
                    }}
                  />
                  {isSubmittable() !== undefined &&
                  !isSubmittable() &&
                  articleLink !== "" &&
                  articleLink.includes("github") ? (
                    <p style={{ marginTop: "3px", color: "#f00" }}>
                      Please input valid link!
                      <Tooltip title={errorText}>
                        <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                      </Tooltip>
                    </p>
                  ) : (
                    ""
                  )}
                </Form.Item>
              </Form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {linkData &&
                articleLink !== "" &&
                isSubmittable() &&
                !articleLink.includes("github") ? (
                  <>
                    <ArticlePreviewCard link={articleLink} data={linkData} />
                    <Divider />
                    <Typography.Link level={5} className="mb-0">
                      <a href={articleLink} target="_blank">
                        {" "}
                        <span className="mb-0">{articleLink}</span>{" "}
                      </a>
                    </Typography.Link>
                  </>
                ) : (
                  <div>{loading ? <Spin size="large" /> : ""}</div>
                )}
              </div>
            </InputWrapper>
          </TabPane>
          <TabPane
            forceRender={true}
            disabled={articleLink ? "disabled" : ""}
            tab={<span>Video</span>}
            key="2"
          >
            <InputWrapper>
              <Form>
                <Form.Item>
                  <Input
                    value={articleLink}
                    placeholder="https://www.video-link.com/"
                    onChange={async (e) => {
                      setArticleLink(e.target.value);
                      if (!e.target.value.includes("github")) {
                        setWait(true);
                        setArticleLink(e.target.value);
                        await getLinkPreview(
                          `https://secret-ocean-49799.herokuapp.com/${e.target.value}`
                        ).then((data) => {
                          setSubmitData(data);
                          setWait(false);
                        });
                      }
                    }}
                  />
                  {isSubmittable() !== undefined &&
                  !isSubmittable() &&
                  articleLink !== "" &&
                  articleLink.includes("github") ? (
                    <p style={{ marginTop: "3px", color: "#f00" }}>
                      Please input valid link!
                      <Tooltip title={errorText}>
                        <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                      </Tooltip>
                    </p>
                  ) : (
                    ""
                  )}
                </Form.Item>
              </Form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {linkData && isSubmittable() && articleLink ? (
                  <>
                    <VideoPreviewCard link={articleLink} data={linkData} />
                    <Divider />
                    <Typography.Link level={5} className="mb-0">
                      <a href={articleLink} target="_blank">
                        {" "}
                        <span className="mb-0">{articleLink}</span>{" "}
                      </a>
                    </Typography.Link>
                  </>
                ) : (
                  <div>{loading ? <Spin size="large" /> : ""}</div>
                )}
              </div>
            </InputWrapper>
          </TabPane>
        </TabsNav>
      </ModalWrapper>
    </>
  );
};

export default AddArticleVideoModal;

const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 0;
    border-radius: 12px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-footer {
    padding: 17px 4%;
  }
`;
const TabsNav = styled(Tabs)`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab {
    padding: 20px 109px;
    font-size: 15px;
    margin: 0;
  }
  .ant-tabs-ink-bar {
    background: #efa718 !important;
    height: 3px !important;
  }
`;
const AddBtn = styled(Button)`
  // background-color: #4dae4e;
  // border: none;
  // border-radius: 12px;
  // height: 40px;
  // padding: 0 30px;
  // font-size: 18px;
  background-color: rgb(41, 41, 41);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  text-align: center;
  margin-right: 5px;
  box-shadow: 0 1px 4px 0px black;
  :hover {
    background-color: rgb(41, 41, 41);
    color: #efa718 !important;
  }
  :focus {
    background-color: rgb(41, 41, 41);
    color: white;
  }
`;
const InputWrapper = styled.div`
  padding: 20px;
  .ant-input {
    padding: 12px;
  }
`;
