import React, { useState, useEffect } from "react";
import { Row, Col, Form, Label, Input, Select, Button } from "antd";
import PhoneInput from "react-phone-input-2";
import csc from "country-state-city";
import Mutations from "../appollo_client/Mutations";
import Queries from "../appollo_client/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { client } from "../appollo_client/client";
import DeviceId from "../components/DeviceId";
import { useHistory, Route } from "react-router-dom";
import "./Settings.css";
import GetUserIdFromUrl from "../GetUserIdFromUrl";
import cogoToast from "cogo-toast";
import { useSelector, useDispatch } from "react-redux";

const { Option } = Select;
const Settings = (props) => {
  const [userProfileData, setUserProfileData] = useState({});
  const [countryCode, setCountryCode] = useState(userProfileData.country);
  const [cities, setCities] = useState([]);
  const [firstName, setFirstName] = useState(userProfileData?.firstName);
  const [lastName, setLastName] = useState(userProfileData?.lastName);
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState(userProfileData.city);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [valueFormat, setValueFormat] = useState("");
  const [countryFormat, setCountryFormat] = useState("");

  const { data: userData, loading: userDataLoading } = useQuery(
    Queries.GET_USER_BY_ID,
    {
      variables: { userId: GetUserIdFromUrl() },
      client: client,
      nextFetchPolicy: "cache-first",
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        setUserProfileData(userData.getUserById);
        setFirstName(userData.getUserById.firstName);
        setLastName(userData.getUserById.lastName);
        setPhone(userData.getUserById.phone);
        setCountry(userData.getUserById.country);
        setValueFormat(userData.getUserById.phone);
        setCountryFormat(userData.getUserById.country);
        setSelectedCity(userData.getUserById.city);
      },
      onError: () => {
        console.log("error in get user By id");
      },
    }
  );

  let dispatch = useDispatch();
  let history = useHistory();

  const phoneHandle = (value, country, e, formattedValue) => {
    if (value[0] != "9") {
      setPhone("92" + value);
    } else if (value[1] && value[1] != "2") {
      value = value.replace(value[1], "2");
      setPhone(value);
    } else {
      setPhone(value);
    }
    setCountryFormat(country);
    setCountryCode("PK");
    setValueFormat(formattedValue);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e);
  };
  useEffect(() => {
    setCities(csc.getCitiesOfCountry("PK"));
  }, [
    countryCode,
    firstName,
    lastName,
    phone,
    country,
    selectedCity,
    currentPassword,
    newPassword,
    confirmPassword,
    valueFormat,
    countryFormat,
  ]);

  const isContainNumber = (value) => {
    return /^[A-Za-z]+$/.test(value);
  };

  const [changeSetting, { loading: mutationLoad }] = useMutation(
    Mutations.CHANGE_SETTING,
    {
      client: client,
    }
  );

  const handleChangeSetting = async () => {
    // console.log("countryFormat.format", countryFormat);
    // console.log("valueFormat", valueFormat?.length);
    if (
      selectedCity !== null &&
      firstName?.length > 2 &&
      lastName?.length > 2 &&
      isContainNumber(firstName) &&
      isContainNumber(lastName) &&
      newPassword === confirmPassword &&
      phone.toString().length === 12
    ) {
      try {
        const { data } = await changeSetting({
          variables: {
            firstName: firstName,
            lastName: lastName,
            city: selectedCity,
            country: "PK",
            phone: parseFloat(phone),
            password: currentPassword,
            changedPassword: newPassword,
          },
        });
        cogoToast.success("Profile has been updated successfully");
        dispatch({
          type: "SET_USER_PROFILE_DATA",
          payload: { profileData: data.changeSetting },
        });
        history.push(`/${GetUserIdFromUrl()}/dashboard`);
      } catch (error) {
        cogoToast.error(error.message.slice(15, error.message.length));
      }
    } else {
      if (firstName.length < 3 || !isContainNumber(firstName)) {
        cogoToast.error("Invalid first name");
      } else if (lastName.length < 3 || !isContainNumber(lastName)) {
        cogoToast.error("Invalid last name");
      } else if (phone.toString().length !== 12) {
        // console.log("phone", phone);
        cogoToast.error("Please enter valid phone number");
      } else if (selectedCity === null) {
        cogoToast.error("Please select your city");
      } else if (newPassword !== confirmPassword) {
        cogoToast.error("Confirm password should be same as new password");
      } else if (newPassword && confirmPassword && !currentPassword) {
        cogoToast.error("Please enter your current password");
      }
    }
  };

  const pkCitites = [
    "Karachi",
    "Lahore",
    "Faisalabad",
    "Rawalpindi",
    "Gujranwala",
    "Peshawar",
    "Multan",
    "Hyderabad",
    "Islamabad",
    "Quetta",
  ];

  return (
    <>
      <div className="fields-container">
        <div className="form-container">
          <div className="main-heading-container">
            <h4 style={{ color: "#10132F", fontWeight: "550" }}>
              Profile Settings
            </h4>
          </div>

          <Row justify="space-between" style={{ padding: "0 35px" }}>
            <Col xl={11} lg={11} md={24} sm={24} xs={24}>
              <Row justify="space-between" align="middle">
                <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                  First Name
                </Col>
                <Col xl={14} lg={14} md={24} sm={24} xs={24}>
                  <Input
                    onChange={(e) => setFirstName(e.target.value)}
                    name="firstName"
                    value={firstName}
                    maxLength="20"
                  />
                </Col>
                <Col span={24} style={{ height: 12 }} />
                <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                  Last Name
                </Col>
                <Col xl={14} lg={14} md={24} sm={24} xs={24}>
                  <Input
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    name="lastName"
                    maxLength="20"
                  />
                </Col>
                <Col span={24} style={{ height: 12 }} />
                <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                  Email
                </Col>
                <Col xl={14} lg={14} md={24} sm={24} xs={24}>
                  <Input
                    disabled
                    name="email"
                    defaultValue={props.data.email}
                    value={props.data.email}
                  />
                </Col>
                <Col span={24} style={{ height: 12 }} />
                <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                  Phone
                </Col>
                <Col xl={14} lg={14} md={24} sm={24} xs={24}>
                  <PhoneInput
                    country={country || "PK"}
                    disableDropdown="true"
                    value={phone.toString()}
                    searchStyle={{ width: "80%" }}
                    dropdownStyle={{ width: "225px" }}
                    inputStyle={{
                      width: "100%",
                      borderRadius: 0,
                      height: "100%",
                    }}
                    regions={["america", "europe", "asia", "oceania", "africa"]}
                    enableSearch={true}
                    onChange={(value, country, e, formattedValue) =>
                      phoneHandle(value, country, e, formattedValue)
                    }
                  />
                </Col>
                <Col span={24} style={{ height: 12 }} />
                <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                  City
                </Col>
                <Col xl={14} lg={14} md={24} sm={24} xs={24}>
                  <Select
                    showSearch
                    defaultValue={selectedCity}
                    value={selectedCity}
                    style={{ width: "100%" }}
                    onChange={handleCityChange}
                    placeholder="Select your city"
                  >
                    {pkCitites?.map((city) => (
                      <Option value={city}>{city}</Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>

            <Col xl={10} lg={10} md={24} sm={24} xs={24}>
              <Row justify="space-between" align="middle">
                <Col span={24} style={{ height: 12 }} />
                <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                  Current Password
                </Col>
                <Col xl={14} lg={14} md={24} sm={24} xs={24}>
                  <Input.Password
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    name="currentPassword"
                  />
                </Col>
                <Col span={24} style={{ height: 12 }} />
                <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                  New Password
                </Col>
                <Col xl={14} lg={14} md={24} sm={24} xs={24}>
                  <Input.Password
                    onChange={(e) => setNewPassword(e.target.value)}
                    name="newPassword"
                  />
                </Col>
                <Col span={24} style={{ height: 12 }} />
                <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                  Confirm Password
                </Col>
                <Col xl={14} lg={14} md={24} sm={24} xs={24}>
                  <Input.Password
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    name="confirmPassword"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="save-button"
              onClick={handleChangeSetting}
              loading={mutationLoad}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
