import React from "react";
import QueueAnim from "rc-queue-anim";
import { Layout, Row, Col, Spin } from "antd";
import { Container } from "react-bootstrap";
import { CardSpinner } from "../components/spinner";

const PublicProfile = (props) => {
  const { profileData, loading } = props;
  // console.log("Profile Data", profileData);
  const { bio, birthday, gender, personalityType } = profileData;
  return (
    <>
      {loading ? (
        <CardSpinner />
      ) : (
        <QueueAnim type="bottom" delay={400} className="queue-simple">
          <Layout.Content>
            <Container>
              <Row style={{ margin: "80px 0" }}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 14 }}
                  style={{ paddingRight: 50 }}
                >
                  <h3>About:</h3>

                  <div className="about-text-container-expanded">
                    <p>{bio}</p>
                  </div>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 9 }}>
                  <>
                    <h3>Personal Information:</h3>
                    <div>
                      <h5 className="mb-0">Date of Birth:</h5>
                      <h6 className="text-secondary">{birthday}</h6>
                    </div>
                    <div className="my-4">
                      <h5 className="mb-0">Personality Type:</h5>
                      <div>
                        <h6 className="text-secondary">{personalityType} </h6>
                      </div>
                    </div>
                    <div>
                      <h5 className="mb-0">Gender:</h5>
                      <h6 className="text-secondary">
                        {gender &&
                          gender[0].toUpperCase() +
                            gender.slice(1, gender.length)}
                      </h6>
                    </div>
                  </>
                </Col>
              </Row>
            </Container>
          </Layout.Content>
        </QueueAnim>
      )}
    </>
  );
};

PublicProfile.defaultProps = {
  profileData: { bio: "", birthday: "", personalityType: "" },
};

export default PublicProfile;
