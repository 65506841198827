import React, { useState, useEffect } from "react";
import { Divider, Button, Modal, Result } from "antd";
import styled from "styled-components";
import { FrownOutlined } from "@ant-design/icons";
import "./TestFailModal.css";
const TestFailModal = (props) => {
  // console.log(props.failModal.data, "test fail modal >>");
  // const {firstName} = props.failModal.data.resultData && props.failModal.data.resultData.userId
  const { status, week, percent, obtMarks, result, totalMarks } =
    props.failModal.data && props.failModal.data.resultData;

  const retakeTestHandle = () => {
    props.setFailModal({ ...props.failModal, modalBolean: false });
    props.failModal.data.setTakeTestModal({
      ...props.failModal,
      modalBolean: true,
      data: props.failModal.data.topicData,
    });
  };

  return (
    <>
      <TestModalWrapper
        style={{ height: "500px" }}
        visible={props.failModal.modalBolean}
        onOk={() =>
          props.setFailModal({ ...props.failModal, modalBolean: false })
        }
        onCancel={() =>
          props.setFailModal({ ...props.failModal, modalBolean: false })
        }
        footer={[
          <Button
            className="testfail-ok-button"
            type="primary"
            onClick={() =>
              props.setFailModal({ ...props.failModal, modalBolean: false })
            }
          >
            Ok
          </Button>,
        ]}
      >
        <ResultWrapper
          icon={<FrownOutlined style={{ color: "rgb(255, 72, 66)" }} />}
          status="error"
          // title={`UH OH ${firstName && firstName.toUpperCase()}!`}
          subTitle="You have failed the test"
          extra={[
            <>
              <ResultDetailWrapper>
                <h4 align="left" style={{ margin: "1.5rem 0" }}>
                  Result Detail:
                </h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <h6> Total Marks:</h6> <h5>{totalMarks}</h5>
                </div>
                <Divider style={{ margin: "8px 0" }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <h6> Obtain Marks:</h6> <h5>{obtMarks}</h5>
                </div>
                <Divider style={{ margin: "8px 0" }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <h6> Percentage:</h6>{" "}
                  <h5>{`${parseFloat(percent).toFixed(1)} %`}</h5>
                </div>
                <Divider style={{ margin: "8px 0" }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <h6> Status:</h6> <h5>{`${status}ed`}</h5>
                </div>
                <Divider style={{ margin: "8px 0" }} />
              </ResultDetailWrapper>
              <StatusBtn
                style={{ background: "#D81E32" }}
                onClick={retakeTestHandle}
              >
                Retake Test
              </StatusBtn>
            </>,
          ]}
        />
      </TestModalWrapper>
    </>
  );
};

export default TestFailModal;

const TestModalWrapper = styled(Modal)`
  .ant-modal-content {
    width: 100% !important;
  }
  .ant-modal-footer {
    padding: 17px 35px;
  }
  .ant-modal-body {
    padding: 25px;
  }
  .ant-modal-close-x {
    width: 30px;
    height: 25px;
    line-height: 20px;
  }
`;
const ResultWrapper = styled(Result)`
  padding: 0 16px;
  // .ant-result-extra{
  //     text-align: left !important;
  // }
`;
const ResultDetailWrapper = styled.div``;
const StatusBtn = styled(Button)`
  letter-spacing: 1.5px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: bold;
  // border-color: #EFA718;
  border-radius: 20px !important;
  transition: ease 1s;
  background-color: rgb(255, 72, 66) !important;
  color: white;
  // box-shadow: 0 0 3px 0.4px black;
  :hover {
    color: white;
    background-color: #efa718;
    transition: ease 1s;
    border-color: black;
  }
  :focus {
    color: white;
    background-color: #efa718;
    transition: ease 1s;
    border-color: black;
  }
`;

const okBtn = styled(Button)`
  // background-color: #4dae4e;
  // border: none;
  // border-radius: 12px;
  // height: 40px;
  // padding: 0 10px;
  // font-size: 18px;
  background-color: rgb(41, 41, 41);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  text-align: center;
  margin-right: 5px;
  box-shadow: 0 1px 4px 0px black;
  :hover {
    background-color: rgb(41, 41, 41);
    color: #efa718 !important;
  }
  :focus {
    background-color: rgb(41, 41, 41);
    color: white;
  }
`;
