import React, { useState } from "react";
import { Card, Button, Modal } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import ReactPlayer from "react-player";

const VideoPreviewCard = (props) => {
  const { title, url } = props.data;
  const [videoError, setVideoError] = useState("");
  return (
    <>
      <VideoCardWrapper
        className="my-3"
        hoverable
        style={{ width: 280 }}
        cover={<ReactPlayer controls url={url} width="280px" height="200px" />}
      >
        <Card.Meta title={<span>{title}</span>} />
      </VideoCardWrapper>
    </>
  );
};

export default VideoPreviewCard;

const VideoCardWrapper = styled(Card)`
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  .ant-card-body {
    padding: 20px;
    border-bottom-left-radius: 10px;
  }
  .ant-card-meta-title:hover {
    color: #efa718;
  }
  .ant-card-cover div video {
    position: static !important;
  }
`;
const VideoModal = styled(Modal)`
  .ant-modal-body {
    padding: 25px 0 0 0;
  }
  .ant-modal-close-x {
    width: 30px;
    height: 25px;
    line-height: 20px;
  }
`;
