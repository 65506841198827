import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Steps, Icon, Row, Col, Button, Spin } from "antd";
import Queries from "../appollo_client/Queries";
import { client } from "../appollo_client/client";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import GetUserIdFromUrl from "../GetUserIdFromUrl";
import { userApplicationStatus } from "../redux/actions/users";

import { useDispatch, useSelector } from "react-redux";

import "./RegistrationSteps.css";
import { getCookie } from "../helpers";
const Step = Steps.Step;

const RegistrationSteps = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [userProfileData, setUserProfileData] = useState(null);
  const [status, setStatus] = useState(0);

  const applicationStatus = useSelector(
    (state) => state.userReducer.applicationStatus
  );
  console.log("ApplicationStatusFromComponent", applicationStatus);

  const firstletter =
    userProfileData && userProfileData.firstName.slice(0, 1).toUpperCase();
  // const lastletter = lastName.slice(0, 1).toUpperCase()

  const [getUserProfile, { data, loading, error }] = useLazyQuery(
    Queries.GET_USER_PROFILE,
    {
      client: client,
      nextFetchPolicy: "cache-first",
      fetchPolicy: "network-only",
      onCompleted: () => {
        dispatch(userApplicationStatus(data.getUserProfile.applicationStatus));
        setUserProfileData(data && data.getUserProfile);
      },
      onError: () => console.log(error),
    }
  );
  // getUserProfile()

  useEffect(() => {
    getUserProfile();
  }, [userProfileData]);

  console.log(userProfileData?.profileStatus, "userProfileData.profileStatus");

  const userId = getCookie("userId");
  console.log(userId, "userId");

  console.log(GetUserIdFromUrl(), "GetUserIdFromUrl()");

  useEffect(() => {
    if (userProfileData && userProfileData.profileStatus === false) {
      setStatus(0);
    }
    if (userProfileData && userProfileData.profileStatus === true) {
      setStatus(1);
    }
    if (applicationStatus === "submitted") {
      setStatus(2);
    }
    if (applicationStatus === "approved") {
      history.push(`/${GetUserIdFromUrl()}/dashboard`);
      setStatus(2);
    }
  }, [userProfileData]);

  return (
    <Row
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Col>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Spinwrapper size="large" />
          </div>
        ) : (
          <StepsCard>
            <div>
              <h1 style={{ marginBottom: "20px" }}>
                Hey, {firstletter}
                {userProfileData && userProfileData.firstName.slice(1)}!
              </h1>
              <h6 style={{ marginBottom: "10px" }}>
                We'll guide you through the Emerge application process. The
                first thing you need to do is fill in your personal information.
              </h6>
            </div>
            <StepsWrapper
              direction="vertical"
              current={status}
              style={{ marginTop: 30 }}
            >
              <Step
                description={
                  <LinkWrapper
                    disabled={status === 1 || status === 2 ? "disabled" : ""}
                    to={
                      status === 1 || status === 2
                        ? `/${GetUserIdFromUrl()}/steps`
                        : `/${GetUserIdFromUrl()}/steps/student-info`
                    }
                  >
                    <DiscriptionCard disabled className="cardArrow">
                      <h2>Profile:</h2>
                      <h6>
                        Your personal information entries, please fill out all
                        the fields in the Profile.
                      </h6>
                    </DiscriptionCard>
                  </LinkWrapper>
                }
              />
              <Step
                description={
                  <LinkWrapper
                    disabled={status === 0 || status === 2 ? "disabled" : ""}
                    to={
                      status === 0 || status === 2
                        ? `/${GetUserIdFromUrl()}/steps`
                        : `/${GetUserIdFromUrl()}/steps/application-questions`
                    }
                  >
                    <>
                      <DiscriptionCard className="cardArrow">
                        <h2>Application:</h2>
                        <h6>
                          Please answer the questions to submit the application!
                        </h6>
                      </DiscriptionCard>
                    </>
                  </LinkWrapper>
                }
              />
              <Step
                title={
                  status === 2
                    ? "Thank You! Your application has been submitted. We will get back to you after reviewing your application shortly."
                    : "Please complete the above procedures"
                }
              />
            </StepsWrapper>
          </StepsCard>
        )}
      </Col>
    </Row>
  );
};

export default RegistrationSteps;
const Spinwrapper = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #fa8c16;
  }
`;

const StepsCard = styled.div`
  padding: 30px;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-item: center;
  flex-direction: column;
`;
const StepsWrapper = styled(Steps)`
  .ant-steps-item-process .ant-steps-item-icon {
    background: #eea720;
    border-color: #eea720;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #ee7328;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #eea720;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #ee7328;
  }
`;

const DiscriptionCard = styled.div`
  // height: 100px;
  // width: 500px;
  box-shadow: 0px 1px 6px -2px black;
  align-items: center;
  border-radius: 5px;
  margin: 5px 20px 20px 20px;
  padding: 10px;
  position: relative;

  &.cardArrow:after {
    content: " ";
    position: absolute;
    left: -10px;
    top: 10px;
    // box-shadow: 0px 1px 6px -2px black;
    border-top: 10px solid transparent;
    border-right: 10px solid lightgray;
    border-left: none;
    border-bottom: 10px solid transparent;
  }
`;
const LinkWrapper = styled(Link)`
  :hover {
    text-decoration: none;
  }
`;
