import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import {
  Form,
  Button,
  Col,
  Row,
  Avatar,
  message,
  Alert,
  Input,
  Typography,
  Spin,
} from "antd";
import {
  MailFilled,
  LockFilled,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import tksLogo from "../assets/signIn-bg.jpg";
import Logo from "../assets/logo-dark-retina-270x132.png";
import QueueAnim from "rc-queue-anim";
import { useMutation } from "@apollo/client";
import { client } from "../appollo_client/client";
import Mutations from "../appollo_client/Mutations";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import InputWrapper from "../components/input";
import { useBootstrapPrefix } from "react-bootstrap/esm/ThemeProvider";
import { ReactComponent as OTP } from "../assets/OTP-01.svg";
import { useForm } from "antd/lib/form/Form";
import ArrowLeft from "../components/arrowLeft/ArrowLeft";
import { getCookie, removeCookie, setCookie } from "../helpers";
import moment from "moment";
import cogoToast from "cogo-toast";

const ResetPassword = (props) => {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [count, setCount] = useState(0);
  const [todayDate, setTodayDate] = useState(
    moment().format("YYYY-MM-DD HH:MM")
  );
  const [tomorrowDate, setTomorrowDate] = useState(
    moment().add(1, "day").format("YYYY-MM-DD HH:MM")
  );

  const [disableBtn, setDisableBtn] = useState(false);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter > 0) {
        setCounter((currentCount) => --currentCount);
      }
    }, 1000);
    counter === 0 && setDisableBtn(false);

    //cleanup
    return () => clearInterval(interval);
  }, [counter]);

  useEffect(() => {
    setTomorrowDate(
      getCookie("tomorrowDate") ||
        moment().add(1, "day").format("YYYY-MM-DD HH:MM")
    );
    setCount(parseFloat(getCookie("count")) || 0);
    setEmail(getCookie("email"));
  }, []);
  const [updatePassword, { loading }] = useMutation(Mutations.UPDATE_PASSWORD, {
    client: client,
  });
  const isContainNumber = (value) => {
    return (
      (value && value.toLowerCase().includes("1".toLowerCase())) ||
      (value && value.toLowerCase().includes("2".toLowerCase())) ||
      (value && value.toLowerCase().includes("3".toLowerCase())) ||
      (value && value.toLowerCase().includes("4".toLowerCase())) ||
      (value && value.toLowerCase().includes("5".toLowerCase())) ||
      (value && value.toLowerCase().includes("6".toLowerCase())) ||
      (value && value.toLowerCase().includes("7".toLowerCase())) ||
      (value && value.toLowerCase().includes("8".toLowerCase())) ||
      (value && value.toLowerCase().includes("9".toLowerCase()))
    );
  };
  const hasUpperCase = (str) => {
    return str && str.toLowerCase() !== str;
  };

  const hasLowerCase = (str) => {
    return str && str.toUpperCase() !== str;
  };

  const isLong = (str) => {
    return str && str.length >= 8;
  };

  const isPassword = (str) => {
    return (
      str &&
      hasUpperCase(str) &&
      hasLowerCase(str) &&
      isLong(str) &&
      isContainNumber(str)
    );
  };

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setError("Password not match!");
      return;
    }
    if (!isPassword(password) && !isPassword(confirmPassword)) {
      setError(
        "Enter at least 8 characters. Include one lowercase letter, one uppercase letter, and one number."
      );
      return;
    }
    if (password === confirmPassword) {
      if (count > 5 && todayDate < tomorrowDate) {
        cogoToast.warn("You cannot reset your password before 24 hours");
        setCount(count + 1);
        setCookie("count", count);
        setError("");
      } else {
        try {
          const res = await updatePassword({
            variables: {
              email: email,
              newPassword: password,
              reenterPassword: confirmPassword,
              code: code,
            },
          });
          if (res.data) {
            removeCookie("tomorrowDate");
            removeCookie("count");
            message.success("Your password has been updated");
            setPassword("");
            setConfirmPassword("");
            setError("");
            removeCookie("email");
            history.push("/sign-in");
          }
        } catch (error) {
          setError(error.message.slice(15));
          setCount(count + 1);
          setCookie("count", count);
          setCookie("tomorrowDate", tomorrowDate);
        }
      }
    } else {
      setError("Password not match!");
    }
  };

  const [resendCode, { loading: resendCodeLoading }] = useMutation(
    Mutations.RESEND_CODE,
    { client: client }
  );
  const handleResendCode = async () => {
    try {
      const res = await resendCode({
        variables: {
          email: email,
        },
      });
      message.success("Resend code successully.");
      setDisableBtn(true);
      setCounter(120);
    } catch (error) {
      setError(error.message.slice(15));
    }
  };
  const [form] = useForm();

  // const disabledFunc = () => {
  //   return setDisableBtn(false);
  //   // if () {
  //   // }
  // };

  return (
    <SignInBgImage>
      <SignInContainer>
        <QueueAnim type="bottom" delay={500} className="queue-simple">
          <Row key="a" justify="center" align="middle">
            <Col>
              <SignInCard>
                <SignInCardImage>
                  <ArrowLeft
                    onClick={() => {
                      history.push("/sign-in");
                      form.resetFields();
                      setError("");
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      style={{ display: "flex", justifyContent: "center" }}
                      to="/"
                    >
                      <img
                        src={Logo}
                        style={{
                          height: "60%",
                          width: "60%",
                        }}
                        alt="logo"
                      />
                    </Link>
                    <p style={{ marginBottom: 0, marginTop: "6%" }}>
                      Reset Your Password
                    </p>
                  </div>
                </SignInCardImage>

                <Form onFinish={handleSubmit} form={form}>
                  <QueueAnim type="left" delay={300} className="queue-simple">
                    <div key="c" style={{ padding: "20px 20px 0" }}>
                      {/* {props.userEmail.email ? ( */}
                      <div
                        disabled
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid lightgray",
                          borderRadius: 20,
                          margin: "0 0 15px 0 ",
                          padding: 3,
                        }}
                      >
                        <Avatar
                          style={{ marginRight: 5 }}
                          size="small"
                          icon={<UserOutlined />}
                        />
                        {email}
                      </div>
                      {/* ) : (
                        "" */}
                      {/* )} */}
                      <Form.Item
                        name="text"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Code!",
                            pattern: /^[0-9]{1,6}$/,
                          },
                        ]}
                      >
                        <InputWrapper
                          style={{ width: "300px" }}
                          type="text"
                          size={"large"}
                          prefix={
                            <OTP
                              style={{
                                marginRight: 5,
                                width: "1.4em",
                                height: "1.4em",
                              }}
                            />
                          }
                          placeholder="Six digit code"
                          onChange={(e) => setCode(e.target.value)}
                          maxLength="6"
                        />
                        {/* <Input
                          style={{
                            width: 300,
                            marginBottom: "3px",
                            marginTop: "10px",
                          }}
                        /> */}
                      </Form.Item>

                      <Form.Item
                        name="resetpassword"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                            // pattern
                          },
                        ]}
                      >
                        <Input.Password
                          type="password"
                          style={{ width: "300px" }}
                          size={"large"}
                          prefix={<LockFilled style={{ marginRight: 5 }} />}
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        name="confirmpassword"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Confirm Password!",
                          },
                        ]}
                      >
                        <Input.Password
                          type="password"
                          style={{ width: "300px" }}
                          size={"large"}
                          prefix={<LockFilled style={{ marginRight: 5 }} />}
                          placeholder="Confirm password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </Form.Item>
                      {error ? (
                        <Alert message={error} type="error" showIcon />
                      ) : (
                        ""
                      )}
                    </div>

                    {counter === 0 ? (
                      <h4 style={{ display: "none" }} className="text-danger">
                        {counter}
                      </h4>
                    ) : (
                      <p className="text-danger" style={{ padding: "0 19px" }}>
                        Please wait before resending the code {counter}
                      </p>
                    )}

                    {console.log("resendCodeLoading", resendCodeLoading)}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: 18,
                      }}
                    >
                      <ResendCodeBtn
                        onClick={handleResendCode}
                        loading={resendCodeLoading}
                        disabled={disableBtn}
                      >
                        Resend Code
                      </ResendCodeBtn>
                    </div>
                  </QueueAnim>
                  <LoginCustomBtn htmlType="submit" loading={loading}>
                    <h4 style={{ color: "white", marginBottom: 0 }}>
                      Update Password
                    </h4>
                    <RightOutlined
                      style={{ color: "white", fontSize: 20, marginLeft: 10 }}
                    />
                  </LoginCustomBtn>
                </Form>
              </SignInCard>
            </Col>
          </Row>
        </QueueAnim>
      </SignInContainer>
    </SignInBgImage>
  );
};

const mapStateToProps = (state) => {
  return {
    userEmail: state.userResetPasswordEmailReducer.data,
  };
};

export default connect(mapStateToProps, null)(ResetPassword);
const ResendCodeBtn = styled(Button)`
  border: none;
  :focus {
    border: none;
  }
`;

const SignInContainer = styled.div`
  background: rgba(0, 0, 10, 0.7);
  // width: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SignInBgImage = styled.div`
  height: 100vh;
  background-image: url(${tksLogo});
  background-size: cover;
  background-repeat: no-repeat;
`;
const SignInCard = styled.div`
  background-color: white;
  width: 340px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 6px -2px black;
  align-items: center;
  border-radius: 5px;
`;
const LoginCustomBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a2a2a;
  height: 80px;
  width: 340px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  outline: none;
  box-shadow: none;
  &:hover,
  &:focus {
    background-color: #2a2a2a;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;
const SignInCardImage = styled.div`
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #f6f6f6;
  width: 100%;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
