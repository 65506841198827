import React, { useEffect } from "react";
import AboutHeader from "../components/AboutHeader";
import AboutContent from "../components/AboutContent";
import { Layout } from "antd";
import QueueAnim from "rc-queue-anim";
import UserBanner from "../components/banner/userBanner";
const About = (props) => {
  const { profileData, loading, handleUserProfileData } = props;

  return (
    <>
      <QueueAnim type="bottom" delay={400} className="queue-simple">
        <Layout.Content>
          <UserBanner />
          <AboutContent
            handleUserProfileData={handleUserProfileData}
            profileData={profileData}
            loading={loading}
          />
        </Layout.Content>
      </QueueAnim>
    </>
  );
};

export default About;
