 const aboutCardData = [
    {
        desc: `
            At Emerge, we have designed different programs and training sessions  for students, each session is a fun, engaging and learning experience for them.  We offer single day trainings that are either conducted on demand of the educational institute or after a careful need analysis that is carried out  by industry experts. Along with these 1 day trainings, we have designed a special 5 day youth empowerment boot camp which aims at professional and personal development of its participants. Idea behind this boot camp is to train and prepare students for a successful practical life. 
            Trainings included in this camp have been designed after an in depth study,  research and evidence-based practice, and are delivered by trained, experienced professionals who are doing well in their respective fields.
        `
    } ,
    {
        desc: `
            Our mentorship program has been designed to connect students with professionals, who help them in building skills for future goals. It is a wonderful opportunity for students to learn from experienced professionals, from different parts of the world. This mentoring program also helps students in taking important career decisions. From courses & certifications a student should enroll in, to the degree they should pursue and the field they should choose in order to enter the workforce, our mentors help their mentees in every possible way. Productive conversations & meetings, provide mentees with career development tools and professional networks that help them in their professional success.
        `
    } ,
    {
        desc: `
            Professional networking is essential for personal and career growth. Members of the Emerge Youth program get an opportunity to connect with students and professionals from all over the world. Networking is great for sharing ideas and knowledge, it opens doors to new opportunities, helps students in becoming confident, and gain a different perspective. We organize different programs & events that help students to meet like minded students from other educational institutes and professionals from different fields.
        `
    } ,
    {
        desc: `
            At Emerge, we identify different volunteering opportunities in different organizations and offer them to students who wish to contribute and play a positive role in the society. Volunteering plays a huge role in a student’s personal and professional growth. It gives them a sense of satisfaction as they are  giving back to the community, they learn social skills, for it is an opportunity to socialise with people from different social and cultural backgrounds. The work experience and knowledge they gain during volunteering help them get jobs and perform well in the field. 
        `
    } ,
    {
        desc: `
            Employability has three components: Education, set of certain skills, and personal attributes. The programs we have designed for students help them improve their employability. Through our training, mentorship, networking and volunteering opportunities we prepare students to succeed in the corporate world. After monitoring students’ dedication & progress, we offer paid internships as well as jobs that help them gain knowledge, experience and competitive edge in the job market.
        `
    } ,
    {
        desc: `
            We have observed that students in Pakistan usually do not have many platforms where they can express themselves and showcase their talent. At Emerge we believe in positive youth development, which means focusing on each individual’s unique talent, interest & future potential.  According to a study, Known as the three Es, which was conducted to identify the drivers of change that can empower youth and harness their potential for human development, identified drivers of change are: quality Education, gainful Employment, and meaningful Engagement. Youth have the power to transform a country’s future, with a meaningful engagement and a sense of direction our youth can do wonders. Emerge Youth Club encourages students who are interested in performing arts, sports or rhetorics to come forward, take part in activities and events we organize and showcase their talent.
        `
    }
]

export default aboutCardData