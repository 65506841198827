import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Tabs, Spin, Empty, Row } from "antd";
import ArticleCard from "../components/ArticleCard";
import VideoCard from "../components/VideoCard";
import QueueAnim from "rc-queue-anim";
import { CardSpinner } from "../components/spinner";
import PaginationComp from "../components/PaginationComp";

const { TabPane } = Tabs;

const PublicPortfolio = ({ userPortfolioData, loading }) => {
  const [articleData, setArticleData] = useState([]);
  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    const articleFilter = userPortfolioData.filter(
      (item) => item.portfolioType === "article"
    );
    setArticleData(articleFilter);

    const videoFilter = userPortfolioData.filter(
      (item) => item.portfolioType === "video"
    );
    setVideoData(videoFilter);
  }, [userPortfolioData]);

  const callback = (key) => {
    // console.log(key)
  };

  //pagination handle change for article
  const numEachArticleVideo = 9;

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(9);
  const [currentValue, setCurrentValue] = useState(1);

  const paginationHandleChange = (value) => {
    console.log("count", value);
    setCurrentValue(value);
    setMinValue((value - 1) * numEachArticleVideo);
    setMaxValue(value * numEachArticleVideo);
  };

  useEffect(() => {}, [numEachArticleVideo, minValue, maxValue, currentValue]);

  //pagination handle change for video

  const [minVideoValue, setMinVideoValue] = useState(0);
  const [maxVideoValue, setMaxVideoValue] = useState(9);
  const [currentVideoValue, setCurrentVideoValue] = useState(1);

  const videoPaginationHandleChange = (value) => {
    console.log("count", value);
    setCurrentVideoValue(value);
    setMinVideoValue((value - 1) * numEachArticleVideo);
    setMaxVideoValue(value * numEachArticleVideo);
  };

  useEffect(() => {}, [numEachArticleVideo, minValue, maxValue, currentValue]);

  return (
    <>
      <StyledDiv>
        <Container key="a" style={{ marginTop: 80, minHeight: "50vh" }}>
          <TabsNav defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Articles" key="1">
              <QueueAnim type="right" delay={300} className="queue-simple">
                <div key="a">
                  {loading ? (
                    <CardsContainer
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Spinwrapper size="large" />
                    </CardsContainer>
                  ) : (
                    <CardsContainer>
                      {articleData.length === 0 ? (
                        <CardsContainer
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Empty align="center"></Empty>
                        </CardsContainer>
                      ) : (
                        <>
                          {/* {articleData?.map((item, index) => { */}
                          {articleData
                            ?.slice(minValue, maxValue)
                            .map((item, index) => {
                              return <ArticleCard key={index} data={item} />;
                            })}
                        </>
                      )}
                      <Row
                        style={{
                          margin: 25,
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <PaginationComp
                          defaultCurrent={currentValue}
                          total={articleData?.length}
                          defaultPageSize={numEachArticleVideo}
                          onChange={paginationHandleChange}
                        />
                      </Row>
                    </CardsContainer>
                  )}
                </div>
              </QueueAnim>
            </TabPane>

            <TabPane tab="Videos" key="2">
              <QueueAnim type="left" delay={300} className="queue-simple">
                <div key="b">
                  {loading ? (
                    <CardsContainer
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Spinwrapper size="large" />
                    </CardsContainer>
                  ) : (
                    <CardsContainer>
                      {videoData.length === 0 ? (
                        <CardsContainer
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Empty align="center"></Empty>
                        </CardsContainer>
                      ) : (
                        <>
                          {videoData
                            ?.slice(minVideoValue, maxVideoValue)
                            .map((item, index) => {
                              return <VideoCard key={index} data={item} />;
                            })}{" "}
                        </>
                      )}
                      <Row
                        style={{
                          margin: 25,
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <PaginationComp
                          defaultCurrent={currentVideoValue}
                          total={videoData?.length}
                          defaultPageSize={numEachArticleVideo}
                          onChange={videoPaginationHandleChange}
                        />
                      </Row>
                    </CardsContainer>
                  )}
                </div>
              </QueueAnim>
            </TabPane>
          </TabsNav>
        </Container>
      </StyledDiv>
    </>
  );
};

PublicPortfolio.defaultProps = {
  userPortfolioData: [],
};

export default PublicPortfolio;

const StyledDiv = styled.div`
  @media (max-width: 960px) {
    .ant-tabs-nav {
      flex-direction: inherit;
    }
  }
`;

const Spinwrapper = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #fa8c16;
  }
`;

const CardsContainer = styled.div`
      padding: 20px 10px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-item: center;

      @media (max-width: 999px) {
        justify - content: center !important;
  }

      .ant-tabs-nav-wrap {
        display: flex;
      justify-content: center;
  }
      `;

const TabsNav = styled(Tabs)`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab {
    padding: 12px 20px;
    font-size: 15px;
  }
  .ant-tabs-ink-bar {
    background: #efa718 !important;
    height: 3px !important;
  }
  .ant-tabs-nav-list {
  }
`;
