import React from "react";
//---------------------//
// Importing CSS files //
//---------------------//
import "./App.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";

import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { rootReducer } from "./redux/rootReducer";
import { client } from "./appollo_client/client";
import Routes from "./Routes";
import { setCookie, getCookie } from "./helpers";

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function App() {
  // const isLogin = localStorage.getItem("isLogin");
  const isLogin = getCookie("isLogin");

  if (!isLogin) {
    // localStorage.setItem("isLogin", false);
    setCookie("isLogin", false);
  }
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
