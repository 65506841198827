import { USER } from "../constants/userConstants";

const initialState = {
  profileData: {
    firstName: "",
    lastName: "",
    coverImage:
      "https://res.cloudinary.com/emergeyouthprogram/image/upload/v1625477132/emerge_forwar/sexwfupr8wvyhthkylay.svg",
  },
  portfolioData: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER.STORE_DATA: {
      return { ...action.payload };
    }

    case "USER_APPLICATION_STATUS": {
      console.log("ApplicationStatusFromReducer", action);
      return {
        ...state,
        applicationStatus: action.payLoad,
        g: Math.random(),
      };
    }

    case "SET_USER_PROFILE_IMAGE": {
      const { profileImage } = action.payload;
      return {
        ...state,
        profileData: { ...state.profileData, profileImage },
        g: Math.random(),
      };
    }

    case "REMOVE_USER_PROFILE_IMAGE": {
      const { profileImage } = action;
      return {
        ...state,
        profileData: { ...state.profileData, profileImage },
        g: Math.random(),
      };
    }

    case "SET_USER_COVER_IMAGE": {
      const { coverImage } = action.payload;

      return {
        ...state,
        profileData: { ...state.profileData, coverImage },
        g: Math.random(),
      };
    }

    case "REMOVE_USER_COVER_IMAGE": {
      const { coverImage } = action.payload;

      return {
        ...state,
        profileData: { ...state.profileData, coverImage },
        g: Math.random(),
      };
    }

    case "GET_USER_COVER_IMAGE": {
      const { payload } = action;
      console.log("nkPayload", payload);

      return {
        ...state,
        profileData: { ...state.profileData, coverImage: payload },
      };
    }

    case "PERSONALITY_TYPE": {
      const { payload } = action;
      return {
        ...state,
        profileData: {
          ...state.profileData,
          personalityType: payload.personalityType,
        },
      };
    }

    case "BIO_DATA": {
      const { payload } = action;
      console.log("payload2", payload);
      let newProfileData = {
        ...state.portfolioData,
        bio: payload,
      };
      console.log("Profile Data > ", newProfileData, " payload ", payload);
      let newState = {
        ...state,
        profileData: {
          ...state.profileData,
          bio: payload,
        },
      };
      console.log("New State > ", newState);
      return newState;
    }

    case "SET_USER_PROFILE_DATA": {
      const { profileData } = action.payload;
      return {
        ...state,
        profileData: { ...state.profileData, ...profileData },
      };
    }

    default:
      return state;
  }
};

/******************************** USER_PROFILE_DATA ***********************************/

const userProfileDataInitialState = {
  data: {},
};

const userProfileDataReducer = (
  state = userProfileDataInitialState,
  action
) => {
  switch (action.type) {
    case USER.USER_PROFILE_DATA:
      return { ...state, data: action.payLoad };
    default:
      return state;
  }
};

/******************************** USER_PROFILE_DATA_BY_ID ***********************************/
const userProfileDataByIdInitialState = {
  data: {},
};
const userProfileDataByIdReducer = (
  state = userProfileDataByIdInitialState,
  action
) => {
  switch (action.type) {
    case USER.USER_PROFILE_DATA_BY_ID:
      return { ...state, data: action.payLoad };
    default:
      return state;
  }
};

// =====================USER_RESET_PASSWORD_EMAIL==================
const userResetPasswordEmailInitialState = {
  data: {},
};
const userResetPasswordEmailReducer = (
  state = userResetPasswordEmailInitialState,
  action
) => {
  switch (action.type) {
    case USER.USER_RESET_PASSWORD_EMAIL:
      return { ...state, data: action.payLoad };
    default:
      return state;
  }
};

export {
  userReducer,
  userProfileDataReducer,
  userResetPasswordEmailReducer,
  userProfileDataByIdReducer,
};
