import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Link } from "react-router-dom";
import { Form, Button, Col, Row, Checkbox } from "antd";
import InfoNavbar from "../components/studentInformation/InfoNavbar";
import StudentInfoFormContainer from "../components/studentInformation/StudentInfoFormContainer";
import { LeftOutlined } from "@ant-design/icons";
import GetUserIdFromUrl from "../GetUserIdFromUrl";

const StudentInfo = () => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <div style={{ position: "absolute", top: 101, left: "126px" }}>
        <Link
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          to={`/${GetUserIdFromUrl()}/steps`}
        >
          <LeftOutlined /> GO BACK TO MAIN PAGE
        </Link>
      </div>
      {/* <Row key='a' justify="center" align="middle"> */}
      <Col style={{ width: 850 }}>
        <SignInCard>
          <div style={{ padding: "30px 30px", textAlign: "center" }}>
            <h3 style={{ margin: "0" }}>Your Information</h3>
          </div>
          <InfoNavbar step={step} setStep={setStep} />
          <StudentInfoFormContainer
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        </SignInCard>
      </Col>
      {/* </Row> */}
    </Row>
  );
};

export default StudentInfo;

const SignInCard = styled.div`
  padding: 0 30px 30px 30px;
  ${"" /* smargin-top: 50px; */}
  background-color: white;
  width: 100%;
  // height: 450px;
  //   justify-content:center;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 6px -2px black;
  align-items: center;
  border-radius: 5px;
  min-height: 540px;

  @media (max-width: 360px) {
    // height: 600px;
  }
`;
