import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Divider,
  Row,
  Col,
  Badge,
  Skeleton,
  Tooltip,
} from "antd";

import { SettingOutlined, CheckOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { getLinkPreview } from "link-preview-js";
import ActivityCardLoading from "./ActivityCardLoading";
import moment from "moment";
import ReactPlayer from "react-player";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";

const RecentActivityCard = (props) => {
  const {
    createdAt,
    recentActivityTitle,
    recentActivityType,
    recentActivityUrl,
    description,
    tags,
  } = props.data;
  const { recentActivityCache, saveRecentActivityData } = props;

  const [loading, setLoading] = useState(false);
  const [articleData, setArticleData] = useState("");

  const created = moment(createdAt).format("Do MMM YYYY");

  const fetchArticleData = async (evt) => {
    setLoading(true);
    await getLinkPreview(
      `https://secret-ocean-49799.herokuapp.com/${recentActivityUrl}`
    ).then((data) => {
      setArticleData(data);
      saveRecentActivityData({ name: recentActivityUrl, data });
    });
    setLoading(false);
  };

  useEffect(() => {
    recentActivityCache[recentActivityUrl]
      ? setArticleData(recentActivityCache[recentActivityUrl])
      : fetchArticleData();
  }, [recentActivityUrl]);

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading ? (
        <ActivityCardLoading />
      ) : (
        <CardWrapper bodyStyle={{ padding: 10 }} hoverable>
          <Row justify="space-between">
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              {recentActivityType == "videos" ? (
                <ReactPlayer
                  controls
                  url={recentActivityUrl}
                  width="100%"
                  height="100%"
                  style={{ borderTopLeftRadius: 5 }}
                />
              ) : (
                <img
                  alt="example"
                  width="100%"
                  src={articleData && articleData.images[0]}
                  height="180px"
                  style={{ borderTopLeftRadius: 5 }}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
              <div style={{ padding: 10 }}>
                <h4
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open(`${recentActivityUrl}`, "_blank")}
                >
                  {recentActivityTitle}
                </h4>
                <p
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "5",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {description}
                </p>
              </div>
            </Col>
          </Row>
          <Divider style={{ margin: 0 }} />
          <Row
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-around",
            }}
          >
            <TagsWrapper>Tags :</TagsWrapper>
            <Col
              xl={18}
              lg={18}
              md={24}
              sm={24}
              xs={24}
              style={{ display: "block", alignItems: "baseline" }}
            >
              <StyledPara></StyledPara>
              {tags.map((item, index) => {
                return (
                  <>
                    <Tooltip title={item} placement="bottom">
                      <StyledTags
                        onClick={() => {
                          // console.log("Tag name --->", item);
                          props.setTextbox(item);
                        }}
                      >
                        {item.length >= 45 ? `${item.slice(0, 45)}...` : item}
                      </StyledTags>
                    </Tooltip>
                  </>
                );
              })}
            </Col>
            <Col xl={3} lg={3} md={24} sm={24} xs={24}>
              <div style={{ fontSize: 12 }}>{created}</div>
            </Col>
          </Row>
        </CardWrapper>
      )}
    </Container>
  );
};
const mapDispatch = (dispatch) => ({
  saveRecentActivityData: (data) =>
    dispatch({ type: "SAVE_RECENT_ACTIVITY_DATA", payload: data }),
});

const mapState = (state) => ({
  recentActivityCache: state.recentAtivityReducer,
});

export default connect(mapState, mapDispatch)(RecentActivityCard);

const CardWrapper = styled(Card)`
  box-shadow: 0 0 5px 0 #ccc;
  border-radius: 5px;
  //   max-width: 800px;
  width: 80%;
  margin-bottom: 40px;
  div div video {
    position: static !important;
  }
`;
const BadgeWrapper = styled(Badge)`
  .ant-badge-status-text {
    font-size: 10px;
  }
`;

const StyledTags = styled.button`
  margin-right: 6px;
  padding: 4px 14px;
  border-radius: 60px;
  border: none;
  background-color: lightgray;
  color: darkslategray;
  height: 35px;
  margin-top: 4px;
  margin-bottom: 4px;
  &:hover {
    opacity: 0.8;
  }
`;
const StyledPara = styled.p`
  margin-right: 5px;
`;

const TagsWrapper = styled.div`
  @media (max-width: 991px) {
    float: left;
    width: 100%;
  }
`;
