import React, { useState, useEffect } from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { message, Button, Space } from "antd";
import AboutInfo from "../studentInformation/AboutInfo";
import ContactInfo from "../studentInformation/ContactInfo";
import ParentsInfo from "../studentInformation/ParentsInfo";
import SchoolInfo from "../studentInformation/SchoolInfo";
import HearAbout from "../studentInformation/HearAbout";
import { client } from "../../appollo_client/client";
import Mutations from "../../appollo_client/Mutations";
import Queries from "../../appollo_client/Queries";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import GetUserIdFromUrl from "../../GetUserIdFromUrl";
import { validate } from "graphql";
import "./StudentInfoFormContainer.css";

const StudentInfoFormContainer = (props) => {
  let history = useHistory();
  const profileLink = `${
    process.env.REACT_APP_PUBLIC_URL
  }/${GetUserIdFromUrl()}/dashboard`;

  const { data, loading, error } = useQuery(Queries.GET_USER_PROFILE, {
    client: client,
    onCompleted: () => {
      // console.log(data.getUserProfile, "get userProfile");
      setInfoState(data.getUserProfile);
    },
    onError: () => console.log(error),
  });
  function validateEmail(elementValue) {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(elementValue);
  }
  const phoneData = data === undefined ? "" : data.getUserProfile.phone;

  const isContainNumber = (value) => {
    return (
      (value && value.toLowerCase().includes("1".toLowerCase())) ||
      (value && value.toLowerCase().includes("2".toLowerCase())) ||
      (value && value.toLowerCase().includes("3".toLowerCase())) ||
      (value && value.toLowerCase().includes("4".toLowerCase())) ||
      (value && value.toLowerCase().includes("5".toLowerCase())) ||
      (value && value.toLowerCase().includes("6".toLowerCase())) ||
      (value && value.toLowerCase().includes("7".toLowerCase())) ||
      (value && value.toLowerCase().includes("8".toLowerCase())) ||
      (value && value.toLowerCase().includes("9".toLowerCase()))
    );
  };

  const [infoState, setInfoState] = useState({
    firstName: "",
    lastName: "",
    city: "",
    gender: "",
    birthdayDate: "",
    email: "",
    phone: "",
    isJob: false,
    isMaster: false,
    isInter: false,
    priGuardianFirstName: "",
    priGuardianLastName: "",
    priGuardianEmail: "",
    secGuardianFirstName: null,
    secGuardianLastName: null,
    secGuardianEmail: null,
    studentGrade: "",
    schoolName: "",
    hearAbout: [],
    status: 1,
  });
  const isNumber = (value) => {
    const numbers = new RegExp(/^\d+$/);
    return numbers.test(value);
  };

  const isSubmittable = () => {
    if (
      infoState.firstName !== "" &&
      infoState !== undefined &&
      infoState.firstName.length > 2 &&
      infoState.lastName !== "" &&
      infoState.lastName !== undefined &&
      infoState.lastName.length > 2 &&
      infoState.city !== "" &&
      infoState.gender !== null &&
      infoState.birthdayDate !== null &&
      infoState.phone !== "" &&
      isNumber(infoState.phone) &&
      infoState.priGuardianFirstName !== "" &&
      infoState.priGuardianFirstName !== undefined &&
      infoState.priGuardianFirstName.length > 2 &&
      infoState.priGuardianLastName !== "" &&
      infoState.priGuardianLastName !== undefined &&
      infoState.priGuardianLastName.length > 2 &&
      infoState.priGuardianEmail !== "" &&
      validateEmail(infoState.priGuardianEmail) &&
      infoState.studentGrade !== "" &&
      infoState.schoolName !== "" &&
      infoState.hearAbout !== undefined &&
      infoState.hearAbout.length !== 0 &&
      !isContainNumber(infoState.firstName) &&
      !isContainNumber(infoState.lastName) &&
      !isContainNumber(infoState.priGuardianFirstName) &&
      !isContainNumber(infoState.priGuardianLastName) &&
      !isContainNumber(infoState.secGuardianFirstName) &&
      !isContainNumber(infoState.secGuardianLastName)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const birthday = moment(
    infoState.birthdayDate && infoState.birthdayDate._d
  ).format("Do MMM YYYY");

  const [
    updateUserInfo,
    { loading: mutationLoad },
  ] = useMutation(Mutations.UPDATE_USER_INFO, { client: client });

  const handleSubmit = async () => {
    if (
      infoState.firstName !== "" &&
      infoState.lastName !== "" &&
      infoState.city !== "" &&
      infoState.gender !== null &&
      infoState.birthdayDate !== null &&
      infoState.phone !== "" &&
      infoState.priGuardianFirstName !== "" &&
      infoState.priGuardianLastName !== "" &&
      infoState.priGuardianEmail !== "" &&
      infoState.studentGrade !== "" &&
      infoState.schoolName !== "" &&
      infoState.hearAbout !== undefined
    ) {
      try {
        const res = await updateUserInfo({
          variables: {
            firstName: infoState.firstName,
            lastName: infoState.lastName,
            city: infoState.city,
            gender: infoState.gender,
            birthday: birthday,
            phone: parseFloat(infoState.phone),
            primeGuardianFirstName: infoState.priGuardianFirstName,
            primeGuardianLastName: infoState.priGuardianLastName,
            primeGuardianEmail: infoState.priGuardianEmail,
            secGuardianFirstName: infoState.secGuardianFirstName,
            secGuardianLastName: infoState.secGuardianLastName,
            secGuardianEmail: infoState.secGuardianEmail,
            grade: infoState.studentGrade,
            institute: infoState.schoolName,
            isInter: infoState.status == 1 ? true : false,
            isJob: infoState.status == 2 ? true : false,
            isMaster: infoState.status == 3 ? true : false,
            knowThrough: infoState.hearAbout,
            profileLink: profileLink,
          },
        });
        message.success("Submitted successfully!");
        history.push(`/${GetUserIdFromUrl()}/steps`);
        console.log(res, "res handle submit");
      } catch (error) {
        console.log("Error >>", error.message);
      }
    } else {
      alert("Please fill the full form");
    }
  };
  // console.log("Info State >>>> ", infoState);

  switch (props.step) {
    case 1:
      return (
        <AboutInfo
          nextStep={props.nextStep}
          infoState={infoState}
          setInfoState={setInfoState}
          data={data && data.getUserProfile}
          isContainNumber={isContainNumber}
        />
      );
    // case 2:
    //   return (
    //     <ContactInfo
    //       nextStep={props.nextStep}
    //       prevStep={props.prevStep}
    //       infoState={infoState}
    //       setInfoState={setInfoState}
    //       data={data && data.getUserProfile}
    //     />
    //   );
    case 2:
      return (
        <ParentsInfo
          nextStep={props.nextStep}
          prevStep={props.prevStep}
          infoState={infoState}
          setInfoState={setInfoState}
          data={data && data.getUserProfile}
          isContainNumber={isContainNumber}
        />
      );
    case 3:
      return (
        <SchoolInfo
          nextStep={props.nextStep}
          prevStep={props.prevStep}
          infoState={infoState}
          setInfoState={setInfoState}
          data={data && data.getUserProfile}
        />
      );
    default:
      return (
        <HearAbout
          infoState={infoState}
          setInfoState={setInfoState}
          handleSubmit={handleSubmit}
          prevStep={props.prevStep}
          loading={mutationLoad}
          data={data && data.getUserProfile}
          isSubmittable={isSubmittable}
        />
      );
  }
};

export default StudentInfoFormContainer;
