import React, { useState, useEffect } from "react";
import { Divider, Button, Modal, Result } from "antd";
import styled from "styled-components";
import { SmileOutlined } from "@ant-design/icons";
import "./TestPassModal.css";
const TestPassModal = (props) => {
  // console.log(props.modal.data, "test modal >>");
  const { firstName } = props.modal.data && props.modal.data.userId;
  const { status, week, percent, obtMarks, result, totalMarks } =
    props.modal.data;

  // console.log(percent, "percent");
  return (
    <>
      <TestModalWrapper
        style={{ height: "500px" }}
        visible={props.modal.modalBolean}
        onOk={() => props.setModal({ ...props.modal, modalBolean: false })}
        onCancel={() => props.setModal({ ...props.modal, modalBolean: false })}
        footer={[
          <Button
            className="testPass-ok-button"
            type="primary"
            onClick={() =>
              props.setModal({ ...props.modal, modalBolean: false })
            }
          >
            Ok
          </Button>,
        ]}
      >
        <ResultWrapper
          icon={<SmileOutlined />}
          status="success"
          title={`CONGRATULATION ${firstName && firstName.toUpperCase()}!`}
          subTitle="You have successfully passed the test"
          extra={[
            <ResultDetailWrapper>
              <h4 style={{ margin: "1.5rem 0" }}>Result Detail:</h4>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Total Marks:</h6> <h5>{totalMarks}</h5>
              </div>
              <Divider style={{ margin: "8px 0" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Obtain Marks:</h6> <h5>{obtMarks}</h5>
              </div>
              <Divider style={{ margin: "8px 0" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Percentage:</h6>{" "}
                <h5>{`${parseFloat(percent).toFixed(1)} %`}</h5>
              </div>
              <Divider style={{ margin: "8px 0" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Status:</h6> <h5>{`${status}ed`}</h5>
              </div>
            </ResultDetailWrapper>,
          ]}
        />
      </TestModalWrapper>
    </>
  );
};

export default TestPassModal;

const TestModalWrapper = styled(Modal)`
  .ant-modal-content {
    width: 100% !important;
  }
  .ant-modal-body {
    padding: 25px;
  }
  .ant-modal-footer {
    padding: 17px 40px;
  }
  .ant-modal-close-x {
    width: 30px;
    height: 25px;
    line-height: 20px;
  }
`;
const ResultWrapper = styled(Result)`
  padding: 0 16px;
  .ant-result-extra {
    text-align: left !important;
  }
`;
const ResultDetailWrapper = styled.div``;

const okBtn = styled(Button)`
  // background-color: #4dae4e;
  // border: none;
  // border-radius: 12px;
  // height: 40px;
  // padding: 0 30px;
  // font-size: 18px;
  background-color: rgb(41, 41, 41);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  text-align: center;
  margin-right: 5px;
  box-shadow: 0 1px 4px 0px black;
  :hover {
    background-color: rgb(41, 41, 41);
    color: #efa718 !important;
  }
  :focus {
    background-color: rgb(41, 41, 41);
    color: white;
  }
`;
