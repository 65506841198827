import React from "react";
import styled from "styled-components";
import {ArrowLeftOutlined} from '@ant-design/icons';

const ArrowLeft = styled(ArrowLeftOutlined)`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: white;
    box-shadow: 0px 1px 6px -2px black;
    position: absolute;
    top: 15px;
    left: 15px;
`

const ArrowLeftWrapper = props => {
return <ArrowLeft {...props}>{props.children}</ArrowLeft>;
};

export default ArrowLeftWrapper;