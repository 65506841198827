import React, { useState, useEffect } from "react";
import {
  Tabs,
  Button,
  Modal,
  Select,
  Form,
  message,
  Radio,
  Input,
  Tooltip,
} from "antd";
import styled from "styled-components";
import Mutations from "../appollo_client/Mutations";
import { client } from "../appollo_client/client";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { getLinkPreview, getPreviewFromContent } from "link-preview-js";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { addNewActivity } from "../redux/actions/recentActivity";

const { Option } = Select;
const { TextArea } = Input;

const AddRecentActivityModal = (props) => {
  const [error, setError] = useState("");
  const [allCategories, setAllCategories] = useState("");
  const [linkData, setLinkData] = useState({});
  const [addCourse, setAddCourse] = useState({
    recentActivityTitle: "",
    description: "",
    recentActivityUrl: "",
    tags: [],
    recentActivityType: "",
  });
  const [wait, setWait] = useState(false);
  const [form] = useForm();
  const errorText =
    "The link you provided is invalid, the emerge data could not be generated. To enable this, please input a valid link that could provide with the sufficient data.";
  const categoryOptions = [
    { value: "Biometrics" },
    { value: "Startups" },
    { value: "Personalized Medicine" },
    { value: "Neurology" },
    { value: "Nanotechnology" },
  ];

  function handleChange(value) {
    setAddCourse({ ...addCourse, tags: value });
  }

  const [addUserRecentActivity, { data: addData, loading: addLinkLoading }] =
    useMutation(Mutations.ADD_USER_RECENT_ACTIVITY, { client: client });

  const handleSubmit = async () => {
    if (
      addCourse.recentActivityUrl !== null &&
      addCourse.recentActivityUrl.slice(0, 4) === "http"
    ) {
      try {
        const res = await addUserRecentActivity({
          variables: {
            recentActivityTitle: addCourse.recentActivityTitle,
            description: addCourse.description,
            recentActivityUrl: addCourse.recentActivityUrl,
            tags: addCourse.tags,
            recentActivityType: addCourse.recentActivityType,
          },
        });
        // props.dispatch({
        //   type: "ADD_NEW_ACTIVITY",
        //   payload: addCourse,
        // });
        props.dispatch(addNewActivity(addCourse));
        if (res.data) {
          message.success(
            `${addCourse.recentActivityType} recent activity has been added successfully!`
          );
          form.resetFields();
          setAddCourse({
            recentActivityTitle: "",
            description: "",
            recentActivityUrl: "",
            tags: [],
            recentActivityType: "",
          });
          props.setAddModal(false);
        }
      } catch (error) {
        form.resetFields();
        console.log(error.message, "error in video modal");
      }
    } else {
      setError("Please enter valid Url!");
    }
  };

  //Implementing link validation
  const [imageError, setImageError] = useState(false);
  const [isArticle, setIsArticle] = useState(false);
  const handleOnLoad = () => {
    setImageError(false);
  };
  const handleOnError = () => {
    setImageError(true);
  };
  const isImage = () => {
    return !imageError && linkData !== undefined && linkData.images?.length > 0;
  };
  const isLink = () => {
    if (
      addCourse.recentActivityUrl &&
      (addCourse.recentActivityUrl.slice(0, 8) === "https://" ||
        addCourse.recentActivityUrl.slice(0, 8) === "https:\\\\")
    ) {
      return true;
    }
    return false;
  };
  const isVideo = () => {
    if (
      addCourse.recentActivityUrl &&
      addCourse.recentActivityUrl.slice(0, 32) ===
        "https://www.youtube.com/watch?v="
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isDisplayble = () => {
    if (isImage() && linkData && linkData.description !== undefined) {
      return true;
    } else {
      return false;
    }
  };
  const isSubmittable = () => {
    if (addCourse.recentActivityType === "article") {
      return isDisplayble() && isLink() && !isVideo();
    } else if (addCourse.recentActivityType === "videos") {
      // return isDisplayble() && isLink() && isVideo();
      return isVideo();
    }
    return false;
  };
  // console.log("Recent Activity Type >>>> ", addCourse.recentActivityType);
  // console.log("is Image >>> ", isImage());
  // console.log("is displayble >>> ", isDisplayble());
  // console.log("is Video >>> ", isVideo());
  // console.log("is link >>> ", isLink());
  // console.log("is submittable >>> ", isSubmittable());
  // console.log("link data >>> ", linkData);
  // console.log(
  //   "Recent activity URL Length >>> ",
  //   addCourse.recentActivityUrl.length
  // );

  return (
    <>
      <ModalWrapper
        width={800}
        visible={props.addModal}
        onCancel={() => {
          props.setAddModal(false);
        }}
        // footer={[
        //   <AddBtn
        //     disabled={!isSubmittable() || !isLink()}
        //     loading={
        //       addCourse.recentActivityUrl !== "" &&
        //       isLink() &&
        //       (addLinkLoading || wait)
        //     }
        //     onClick={handleSubmit}
        //     key="submit"
        //     type="primary"
        //     // loading={addLinkLoading}
        //     style={{ margin: "10px 10px" }}
        //   >
        //     Add
        //   </AddBtn>,
        // ]}
        footer={null}
      >
        <div>
          <img
            src={
              linkData && linkData.images?.length > 0 ? linkData.images[0] : ""
            }
            alt="checkImage"
            onLoad={handleOnLoad}
            onError={handleOnError}
            width="2px"
            height="2px"
            style={{ display: "none" }}
          />
        </div>
        <div style={{ padding: "50px 50px 20px 50px " }}>
          <Form onFinish={handleSubmit} form={form}>
            <h5 style={{ marginBottom: 0 }}>Title:</h5>
            <Form.Item
              // initialValue={addCourse.recentActivityTitle}
              name="recentActivityTitle"
              rules={[
                { required: true, message: "Please input title of Topic!" },
              ]}
            >
              <Input
                onChange={(e) =>
                  setAddCourse({
                    ...addCourse,
                    recentActivityTitle: e.target.value,
                  })
                }
                placeholder="Title of Topic"
              />
            </Form.Item>
            <h5 style={{ marginBottom: 0 }}>Description:</h5>
            <Form.Item
              // initialValue={addCourse.description}
              name="Description"
              rules={[{ required: true, message: "Please input Description!" }]}
            >
              <TextArea
                showCount
                maxLength={500}
                rows={4}
                onChange={(e) =>
                  setAddCourse({ ...addCourse, description: e.target.value })
                }
                placeholder="Description"
              />
            </Form.Item>
            <h5 style={{ marginBottom: 0 }}>#Tags:</h5>
            <Form.Item
              // initialValue={addCourse.week}
              name="tags"
              rules={[{ required: true, message: "Please input any #Tags!" }]}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Tags Mode"
                onChange={handleChange}
              >
                {categoryOptions.map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>
                      {item.value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <h5 style={{ marginBottom: 0 }}>Material type:</h5>
            <Form.Item
              name="type"
              rules={[
                { required: true, message: "Please select material type!" },
              ]}
            >
              <Radio.Group
                value={addCourse.recentActivityType}
                onChange={(e) =>
                  setAddCourse({
                    ...addCourse,
                    recentActivityType: e.target.value,
                  })
                }
              >
                <Radio
                  defaultChecked={true}
                  style={{ marginRight: "25px" }}
                  value={"article"}
                  onClick={() => {
                    setIsArticle(false);
                  }}
                >
                  Article
                </Radio>
                <Radio
                  onClick={() => {
                    setIsArticle(true);
                  }}
                  value={"videos"}
                >
                  Video
                </Radio>
              </Radio.Group>
            </Form.Item>
            <h5 style={{ marginBottom: 0 }}>Link:</h5>
            <Form.Item
              // initialValue={addCourse.recentActivityUrl}
              name="link"
              rules={[{ required: true, message: "Please input Link!" }]}
            >
              <Input
                onChange={async (e) => {
                  setWait(true);
                  setAddCourse({
                    ...addCourse,
                    recentActivityUrl: e.target.value,
                  });
                  await getLinkPreview(
                    `https://secret-ocean-49799.herokuapp.com/${e.target.value}`
                  ).then((data) => {
                    setLinkData(data);
                    setWait(false);
                  });
                }}
                // placeholder="https://www.google.com/"
                placeholder={
                  isArticle == false
                    ? "https://www.google.com/"
                    : "https://www.youtube.com/"
                }
              />
            </Form.Item>
            <p style={{ color: "red", marginBottom: 0 }}>{error}</p>
            {/* {addCourse.recentActivityUrl.length > 0 &&
            isSubmittable === false ? (
              <p style={{ marginTop: "3px", color: "#f00" }}>
                Please input valid link!
                <Tooltip title={errorText}>
                  <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                </Tooltip>
              </p>
            ) : (
              ""
            )} */}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <AddBtn
                disabled={!isSubmittable() || !isLink()}
                loading={
                  addCourse.recentActivityUrl !== "" &&
                  isLink() &&
                  (addLinkLoading || wait)
                }
                htmlType="submit"
                key="submit"
                type="primary"
                // loading={addLinkLoading}
                style={{ margin: "10px 10px" }}
              >
                Add
              </AddBtn>
            </div>
          </Form>
        </div>
      </ModalWrapper>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    allActivities: state.getAllRecentActivity.allActivities,
    garbage: state.getAllRecentActivity.garbage,
  };
};

export default connect(mapStateToProps, null)(AddRecentActivityModal);

const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 0;
    border-radius: 12px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-footer {
    padding: 17px 4%;
  }
`;
const TabsNav = styled(Tabs)`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab {
    padding: 20px 109px;
    font-size: 15px;
    margin: 0;
  }
  .ant-tabs-ink-bar {
    background: #efa718 !important;
    height: 3px !important;
  }
`;
const AddBtn = styled(Button)`
  // background-color: #4dae4e;
  // border: none;
  // border-radius: 12px;
  // height: 40px;
  // padding: 0 30px;
  // font-size: 18px;
  background-color: rgb(41, 41, 41);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  text-align: center;
  margin-right: 5px;
  box-shadow: 0 1px 4px 0px black;
  :hover {
    background-color: rgb(41, 41, 41);
    color: #efa718 !important;
  }
  :focus {
    background-color: rgb(41, 41, 41);
    color: white;
  }
`;
const InputWrapper = styled.div`
  padding: 20px;
  .ant-input {
    padding: 12px;
  }
`;
