import React, { useState, useEffect } from "react";
import EmergeLogo from "../../assets/emergeLogo.png";
import "./Header.css";
import { AlignRightOutlined, CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Avatar, Menu, Dropdown, Spin, Row, Col, Divider } from "antd";
import GetUserIdFromUrl from "../../GetUserIdFromUrl";
import { useMutation, useQuery } from "@apollo/client";
import { client } from "../../appollo_client/client";
import Mutations from "../../appollo_client/Mutations";
import Queries from "../../appollo_client/Queries";
import { userNotAuthenticatedUpdate } from "../../redux/actions/authenticate";
import { ReactComponent as EmergeNewLogo } from "../../assets/logo for Youth-white-01.svg";
import { MdHome } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import { getCookie, removeCookie } from "../../helpers";

const Header = (props) => {
  const history = useHistory();
  const { profileData, userProfileDataByIdReducer } = props;
  const userId = profileData._id;

  // console.log("props", props);

  const [active, setActive] = useState("");
  const [click, setClick] = useState(false);
  const [fifthArray, setFifthArray] = useState();
  const [userProfileImage, setUserProfileImage] = useState([]);

  const userData = userProfileDataByIdReducer;
  // console.log("User Profile Data By IdReducer", userData);
  useEffect(() => {
    setUserProfileImage(userData);
  }, [userData]);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = (index) => {
    setActive(index);
    setClick(false);
  };

  // ==================Log out Mutation============
  const [logout, { data, loading, error }] = useMutation(Mutations.LOG_OUT, {
    client: client,
  });

  const handleLogOut = async () => {
    // const deviceId = localStorage.getItem("deviceId");
    const deviceId = getCookie("deviceId");
    try {
      const res = await logout({
        variables: {
          deviceId: deviceId,
        },
      });
      // localStorage.removeItem("email");
      removeCookie("email");
      // removeCookie("token");
      // localStorage.removeItem("countryCode");
      removeCookie("countryCode");
      props.logOut(res.data, history);
    } catch (error) {
      console.log("Error >>", error.message);
    }
    // history.push("/sign-in");
  };

  const menu = (
    <div
      style={{
        background: "#fff",
        borderRadius: "6px",
        width: "200px",
        padding: "10px 0 0px 0",
        boxShadow: "0px 0px 3px 0px #949492",
        wordBreak: "break-all",
        // position: "fixed",
      }}
    >
      <Row>
        <Col span={24}>
          <div
            style={{
              margin: "5px 10px 5px 15px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <span style={{ fontSize: "1.05rem", fontWeight: "600" }}>
              {`${profileData.firstName
                ?.slice(0, 1)
                .toUpperCase()}${profileData.firstName?.slice(
                1,
                profileData.firstName.length
              )} `}
            </span>
            <span style={{ fontSize: "0.8rem" }}>{profileData.email}</span>
          </div>
        </Col>
        <Col
          style={{
            background: "#ccc",
            height: "1px",
            opacity: 0.3,
            margin: "5px 0",
            boxShadow: "0px 0px 2px 0px #ccc",
          }}
          span={24}
        ></Col>
        <Col span={24}>
          <Menu className="avatar-dropdown-menu">
            <Menu.Item
              icon={<MdHome style={{ fontSize: 18, marginRight: "3px" }} />}
            >
              <Link
                to={`/${userId}/dashboard`}
                onClick={() => closeMobileMenu(0)}
              >
                <span rel="noopener noreferrer">Home</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              icon={<IoSettings style={{ fontSize: 18, marginRight: "3px" }} />}
            >
              <Link to={`/${userId}/settings`} onClick={handleClick}>
                <span rel="noopener noreferrer">Settings</span>
              </Link>
            </Menu.Item>
          </Menu>
        </Col>
        <Col span={24}>
          <div
            onClick={handleLogOut}
            style={{
              margin: "10px 0",
              width: "100%",
              height: "40px",
            }}
          >
            <div className="logout-container">
              <span>Logout</span> {loading ? <Spin size="small" /> : ""}{" "}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  const navLinks = [
    { name: "About", to: `/${userId}/dashboard` },
    { name: "Portfolio", to: `/${userId}/portfolio` },
    { name: "Library", to: `/${userId}/library` },
    { name: "Courses", to: `/${userId}/courses` },
    { name: "Recent Activity", to: `/${userId}/recent-activity` },
    { name: "Settings", to: `/${userId}/settings` },
  ];

  const firstLatter =
    profileData.firstName && profileData.firstName.slice(0, 1).toUpperCase();
  const lastLatter =
    profileData.lastName && profileData.lastName.slice(0, 1).toUpperCase();

  const ProfileImage = profileData.profileImage;
  // console.log("ProfileImage", ProfileImage);

  useEffect(() => {}, [ProfileImage]);

  return (
    <>
      {/* {auth === true ? ( */}
      <div
        style={{
          boxShadow: "0 0 2px 2px lightgray",
          backgroundColor: "#292929",
        }}
      >
        <div className="header">
          {/* <div className="logo-nav"> */}
          <div className="logo-container">
            <Link to="/">
              <EmergeNewLogo style={{ width: "120px" }} />
            </Link>
          </div>
          <div className={click ? "nav-options active" : "nav-options"}>
            <AvatarRow align="middle" style={{ justifyContent: "center" }}>
              <Col xs={4} sm={4} md={4} style={{ textAlign: "center" }}>
                <Avatar size={50} src={profileData.profileImage} />
              </Col>
              <Col offset={2} xs={8} sm={7} md={7}>
                <div style={{ display: "grid" }}>
                  <Col span={24}>
                    <StyledParagraphs>
                      {profileData.firstName} {profileData.lastName}
                    </StyledParagraphs>
                  </Col>
                  <Col span={24}>
                    <StyledParagraphs>{profileData.email}</StyledParagraphs>
                  </Col>
                </div>
              </Col>
            </AvatarRow>
            <Row align="middle">
              <Col span={24}>
                <MobileMenuDivider />
              </Col>
            </Row>
            {navLinks.map((item, index) => {
              const activeColor =
                item.to === history.location.pathname ? "#EFA718" : "#fff";
              return (
                <>
                  <MobileDesktopViewDiv>
                    <NavLinks
                      className={`nav-item ${item.name}`}
                      activecolor={activeColor}
                      onClick={() => closeMobileMenu(index)}
                      to={item.to}
                      key={index}
                    >
                      {item.name}
                    </NavLinks>
                  </MobileDesktopViewDiv>
                </>
              );
            })}
            <Row align="middle">
              <Col span={24} className="logout-btn">
                <div
                  onClick={handleLogOut}
                  style={{
                    margin: "10px 0 30px",
                    width: "100%",
                    height: "40px",
                  }}
                >
                  <div className="logout-container">
                    <span>Logout</span> {loading ? <Spin size="small" /> : ""}{" "}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* </div> */}
          <div
            className="profile-image-container"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* <UserIcon className='px-5'> */}
            <Dropdown
              trigger={["click"]}
              overlay={menu}
              placement="bottomRight"
              arrow
            >
              <AvatarWrapper className="header-avatar">
                {firstLatter}
                {lastLatter}
                {/* <img src={profileData.profileImage} alt="img.png" /> */}
              </AvatarWrapper>
            </Dropdown>
            {/* </UserIcon> */}
          </div>
          <div className="mobile-menu" onClick={handleClick}>
            {click ? (
              <CloseOutlined className="menu-icon mb" />
            ) : (
              <AlignRightOutlined className="menu-icon mb" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: (data, history) =>
      dispatch(userNotAuthenticatedUpdate(data, history)),
  };
};

const mapStateToProps = (state) => ({
  profileData: state.userReducer.profileData,
  userProfileDataByIdReducer: state.userProfileDataByIdReducer,
  // .userProfileDataByIdReducer.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

const NavLinks = styled(Link)`
  height: 80px;
  align-items: center;
  display: flex;
  color: ${(props) => props.activecolor} !important;
  :hover {
    text-decoration: none;
    color: #efa718 !important;
  }
`;
const AvatarWrapper = styled(Avatar)`
  :hover {
    cursor: pointer;
  }
`;

const MobileMenuDivider = styled(Divider)`
  margin: 0px;
  @media (max-width: 960px) {
    background-color: #efa718;
  }
  @media (min-width: 961px) {
    display: none;
  }
`;

const AvatarRow = styled(Row)`
  margin: 24px;
  @media (min-width: 961px) {
    display: none;
  }
`;

const StyledParagraphs = styled.p`
  margin-bottom: 0px;
  margin-top: 3px;
  color: white;
`;

const MobileDesktopViewDiv = styled.div`
  .nav-item {
    justify-content: center;
  }
`;
