import React from "react";
import { Pagination } from "antd";
import styled from "styled-components";

const StyledPagination = styled(Pagination)`
  .ant-pagination-item-active {
    border-color: #f6a017 !important;
    background-color: #f6a017 !important;
    color: white;
  }

  .ant-pagination-item {
    border-radius: 50px;
    // padding: 9px;
    // height: 50px;
    // width: 40px;
  }

  .ant-pagination-item-link {
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-item:hover {
    border-color: #efa718 !important;
  }
  .ant-pagination-item-link:hover {
    border: 1px solid #f6a017 !important;
  }
  .anticon-right {
    color: #efa718 !important;
  }
  .anticon-left {
    color: #efa718 !important;
  }
`;

const PaginationComp = (props) => {
  return <StyledPagination {...props} />;
};

export default PaginationComp;
