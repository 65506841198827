// import { SIGNUP_SUCCESS, SIGNIN_SUCCESS, SIGNOUT_SUCCESS} from './constants';
import { USER_AUTHENTICATED } from "../constants/userConstants";

/******************************** Auth Reducer ***********************************/

const initialState = {
  isLogin: false,
  data: {},
};

const userAuthenticateReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_AUTHENTICATED.AUTHENTICATED:
      return Object.assign({}, state, {
        isLogin: action.isLogin,
        data: action.payLoad,
      });

    case USER_AUTHENTICATED.NOT_AUTHENTICATED:
      // return Object.assign({}, state, {isLogin:action.isLogin , data: action.payLoad})
      return {};
    default:
      return state;
  }
};

export default userAuthenticateReducer;
