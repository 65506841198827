const articleReducer = (state = [], action) => {
  switch (action.type) {
    case "SAVE_ARTICLE_DATA": {
      const { name, data } = action.payload;
      return { ...state, [name]: data };
    }
    default:
      return state;
  }
};

export default articleReducer;
