const initialState = {
  error: "",
};

const verifyLoggedUser = (state = initialState, action) => {
  switch (action.type) {
    case "VERIFY_LOGGED_USER":
      console.log("VERIFY_LOGGED_USER_FROM_REDUCER", action);
      const { message } = action.payload;
      return {
        ...state,
        error: { ...state.error, message },
        // error: {...state,action.payload.message},
      };
    default:
      return state;
  }
};

export default verifyLoggedUser;
