const initialState = {
  allCourses: [],
};

const getAllCourses = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_COURSES": {
      const { payload } = action;
      return { ...state, allCourses: payload.reverse() };
    }

    default:
      return state;
  }
};

export default getAllCourses;
