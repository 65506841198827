import React, { useState } from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Form, Button, Input, InputNumber, Tooltip } from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";
import PhoneInput from "react-phone-input-2";
import InputWrapper from "../input";
import { rewriteURIForGET } from "@apollo/client";
const ContactInfo = (props) => {
  // console.log("Props Contact >>>>", props);

  const previous = (e) => {
    props.prevStep();
  };

  const next = (e) => {
    props.nextStep();
  };

  const isNumber = (value) => {
    const numbers = new RegExp(/^\d+$/);
    return numbers.test(value);
  };

  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={0} className="queue-simple">
        <Form key="a" onFinish={next}>
          <h4 style={{ textAlign: "center", margin: 20 }}>
            In case we need to contact you
          </h4>
          <Form.Item
            initialValue={props.data && props.data.email}
            name="email"
            rules={[{ required: true, message: "Please enter your Email!" }]}
          >
            <InputWrapper
              disabled
              value={props.data && props.data.email}
              prefix={<UserOutlined style={{ marginRight: 5 }} />}
              placeholder="your@example.com*"
              onChange={(e) =>
                props.setInfoState({
                  ...props.infoState,
                  email: e.target.value,
                })
              }
            />
          </Form.Item>

          {/* <Form.Item name="phone" initialValue={props.data && props.data.phone}>
            <Input
              type="number"
              value={props.data && props.data.phone}
              prefix={<UserOutlined style={{ marginRight: 5 }} />}
              placeholder="Your Mobile Number*"
              onChange={(e) =>
                props.setInfoState({
                  ...props.infoState,
                  phone: props.data.phone,
                })
              }
            />
          </Form.Item>  */}
          <Form.Item
            name="step_phone"
            // initialValue={props.data && props.data.phone}
            initialValue={props.infoState && props.infoState.phone}
          >
            <Input
              maxLength="12"
              value={props.data && parseInt(props.data.phone)}
              prefix={<PhoneOutlined style={{ marginRight: 5 }} />}
              placeholder="Your Mobile Number*"
              onChange={(e) => {
                props.setInfoState({
                  ...props.infoState,
                  phone: e.target.value,
                });
              }}
            />
          </Form.Item>
          {!isNumber(props.infoState.phone) ||
          props.infoState.phone.length < 12 ? (
            <p style={{ marginTop: "3px", color: "#f00" }}>
              Incorrect phone number
              <Tooltip title="Phone number should not have alphabets and special character">
                <InfoCircleOutlined style={{ marginLeft: "2px" }} />
              </Tooltip>
            </p>
          ) : (
            <></>
          )}
          {/* <PhoneInput
            country={"pk"}
            value={phone}
            containerStyle={{ marginTop: 10 }}
            inputStyle={{ width: "100%", borderRadius: 0, height: 38 }}
            searchStyle={{ width: "366px" }}
            dropdownStyle={{ width: "430px" }}
            regions={["america", "europe", "asia", "oceania", "africa"]}
            onChange={(value, country, e, formattedValue) =>
              phoneHandle(value, country, e, formattedValue)
            }
            enableSearch={true}
            placeholder="Phone number"
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 50,
            }}
          >
            <Button
              size="large"
              onClick={previous}
              style={{ backgroundColor: "lightgray" }}
            >
              {" "}
              Previous
            </Button>
            <Button size="large" htmlType="submit" type="primary">
              Next{" "}
            </Button>
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default ContactInfo;
