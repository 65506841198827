import React from "react";
import { Link } from "react-router-dom";
import "../App.css";
import { getCookie } from "../helpers";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container" id="home">
      <div
        style={{
          backgroundImage: `url(/videos/heroSection.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "-webkit-fill-available",
          height: "100%",
        }}
        // src="/videos/heroSection.jpg" className="hero-section-image"
      />
      {/* <h1>Emerge Youth Program</h1> */}
      {/* <p style={{ fontFamily: "PT Sans" }}>What are you waiting for?</p> */}
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          to="/sign-up"
        >
          GET REGISTERED
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
