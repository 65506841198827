import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import InputWrapper from "../components/input/index";
import { Form, Alert, Typography } from "antd";
import {
  UserOutlined,
  MailFilled,
  LockFilled,
  RightOutlined,
} from "@ant-design/icons";

const RememberPasswordInput = (props) => {
  return (
    <div>
      <p style={{ textAlign: "center" }}>
        Please enter your email address. We will <br /> send you an email to
        reset your password.
      </p>
      <FormItemWrapper
        name="resetPasswordEmail"
        rules={[{ required: true, message: "Please input your Email!",pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ }]}

      >
        <InputWrapper
          style={{ width: "300px" }}
          type="email"
          size={"large"}
          prefix={<MailFilled style={{ marginRight: 5 }} />}
          placeholder="Yours@example.com"
          onChange={(e) => props.setResetPasswordEmail(e.target.value)}
        />
      </FormItemWrapper>
      {props.error ? <Alert message={props.error} type="error" showIcon /> : ""}
      {/* {props.responseSuccefully ? 
                <>
                <Alert message='Please check your Email.' type="success" showIcon  />
                <Typography.Link style={{marginTop: 10, marginBottom: 0, cursor: 'pointer'}}
                    onClick={() => history.push('/reset-password')}
                >Reset your password!</Typography.Link>
                </>
                : ''
            } */}
    </div>
  );
};

export default forwardRef(RememberPasswordInput);
const FormItemWrapper = styled(Form.Item)`
  margin-bottom: 10px;
`;
const SignInContainer = styled.div`
  background: rgba(0, 0, 10, 0.7);
  // width: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
