import React from "react";
import { Skeleton, Divider } from "antd";

const CardSkeletonLoading = () => {
  return (
    <div style={{ width: 350, margin: "0 8px" }}>
      <Skeleton.Image style={{ width: 350, height: 180 }} />
      <Divider />
      <Skeleton active></Skeleton>
    </div>
  );
};

export default CardSkeletonLoading;
