import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Spin, Tabs, Empty } from "antd";
import QueueAnim from "rc-queue-anim";
import CourseTopicCard from "../components/CourseTopicCard";
import { client } from "../appollo_client/client";
import { useQuery } from "@apollo/client";
import Queries from "../appollo_client/Queries";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import "./WeeklyTopics.css";
const { TabPane } = Tabs;

const WeeklyTopics = (props) => {
  const [courseDetailData, setCourseDetailData] = useState(null);
  console.log(courseDetailData, "data11");
  const weekOne = [];
  const weekTwo = [];
  const weekThree = [];
  const weekFour = [];
  const weekFive = [];
  const weekSix = [];

  const { data, loading, error } = useQuery(Queries.GET_COURSE_DETAILS, {
    variables: { courseId: props.location.state.courseId },
    client: client,
    nextFetchPolicy: "cache-first",
    fetchPolicy: "network-only",
    onCompleted: () => {
      setCourseDetailData(data && data.getCourseDetails);
    },
    onError: () => {
      console.log(error.message, "error in week topics");
    },
  });

  courseDetailData &&
    courseDetailData.map((item, index) => {
      if (item.week === 1) {
        weekOne.push(item);
      } else if (item.week === 2) {
        weekTwo.push(item);
      } else if (item.week === 3) {
        weekThree.push(item);
      } else if (item.week === 4) {
        weekFour.push(item);
      } else if (item.week === 5) {
        weekFive.push(item);
      } else if (item.week === 6) {
        weekSix.push(item);
      }
    });
  return (
    <Container style={{ paddingTop: 10 }}>
      {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '50px 30px', flexDirection: 'column' }}> */}
      <BackLink>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 25,
            position: "relative",
            right: "10px",
          }}
          onClick={() => props.history.goBack()}
        >
          <LeftOutlined /> Back to courses
        </p>
      </BackLink>
      <h5 style={{ fontSize: 28 }} className="my-3">
        Course: {props.location.state.title}
      </h5>
      <TabsNav defaultActiveKey="1" centered>
        <TabPane tab="Week 1" key="1">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Spinwrapper size="large" />
            </div>
          ) : (
            <div>
              {weekOne.length < 1 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <Empty description="" align="center">
                    <h3>No Topics Found</h3>
                  </Empty>
                </div>
              ) : (
                <CardsContainer>
                  {weekOne &&
                    weekOne.map((item, index) => {
                      return <CourseTopicCard key={index} data={item} />;
                    })}
                </CardsContainer>
              )}
            </div>
          )}
        </TabPane>
        <TabPane tab="Week 2" key="2">
          <div>
            {weekTwo.length < 1 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Empty description="" align="center">
                  <h3>No Topics Found</h3>
                </Empty>
              </div>
            ) : (
              <CardsContainer>
                {weekTwo &&
                  weekTwo.map((item, index) => {
                    return <CourseTopicCard key={index} data={item} />;
                  })}
              </CardsContainer>
            )}
          </div>
        </TabPane>
        <TabPane tab="Week 3" key="3">
          <div>
            {weekThree.length < 1 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Empty description="" align="center">
                  <h3>No Topics Found</h3>
                </Empty>
              </div>
            ) : (
              <CardsContainer>
                {weekThree &&
                  weekThree.map((item, index) => {
                    return <CourseTopicCard key={index} data={item} />;
                  })}
              </CardsContainer>
            )}
          </div>
        </TabPane>
        <TabPane tab="Week 4" key="4">
          <div>
            {weekFour.length < 1 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Empty description="" align="center">
                  <h3>No Topics Found</h3>
                </Empty>
              </div>
            ) : (
              <CardsContainer>
                {weekFour &&
                  weekFour.map((item, index) => {
                    return <CourseTopicCard key={index} data={item} />;
                  })}
              </CardsContainer>
            )}
          </div>
        </TabPane>
        <TabPane tab="Week 5" key="5">
          <div>
            {weekFive.length < 1 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Empty description="" align="center">
                  <h3>No Topics Found</h3>
                </Empty>
              </div>
            ) : (
              <CardsContainer>
                {weekFive &&
                  weekFive.map((item, index) => {
                    return <CourseTopicCard key={index} data={item} />;
                  })}
              </CardsContainer>
            )}
          </div>
        </TabPane>
        <TabPane tab="Week 6" key="6">
          <div>
            {weekSix.length < 1 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Empty description="" align="center">
                  <h3>No Topics Found</h3>
                </Empty>
              </div>
            ) : (
              <CardsContainer>
                {weekSix &&
                  weekSix.map((item, index) => {
                    return <CourseTopicCard key={index} data={item} />;
                  })}
              </CardsContainer>
            )}
          </div>
        </TabPane>
      </TabsNav>
      {/* </div> */}
    </Container>
  );
};

export default WeeklyTopics;
const Spinwrapper = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #fa8c16;
  }
`;
const BackLink = styled.div`
  p:hover {
    color: #eea720;
    cursor: pointer;
  }
`;

const CardsContainer = styled.div`
  padding: 20px 20px;
  // height: 600px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-item: center;

  @media (max-width: 999px) {
    justify-content: center !important;
  }

  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
`;

const TabsNav = styled(Tabs)`
  .ant-tabs-tab {
    padding: 12px 68px;
    margin: 0;
  }
  .ant-tabs-ink-bar {
    background: #efa718 !important;
    height: 3px !important;
  }
`;
