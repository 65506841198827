const recentActivityReducer = (state = [], action) => {
    switch (action.type) {
      case "SAVE_RECENT_ACTIVITY_DATA": {
        const { name, data } = action.payload;
        return { ...state, [name]: data };
      }
      default:
        return state;
    }
  };
  
  export default recentActivityReducer;
  