// import { SIGNIN_SUCCESS , SIGNUP_SUCCESS, SIGNOUT_SUCCESS } from './constants'
// import USER_AUTHENTICATED from "./constants";

import { USER_AUTHENTICATED } from "../constants/userConstants";

import { getCookie, removeCookie, setCookie } from "../../helpers";

/******************************* SignIn & SignUp****************************************/
const userAuthenticatedUpdate = (data, deviceId, history) => {
  // console.log(data, "data in action");
  // localStorage.setItem("token", data.jwtToken.token);
  // localStorage.setItem("deviceId", deviceId);
  // localStorage.setItem("userId", data._id);
  // localStorage.setItem("isLogin", true);
  setCookie("token", data.jwtToken.token);
  setCookie("deviceId", deviceId);
  setCookie("userId", data._id);
  setCookie("isLogin", true);

  // const applicationStatus = localStorage.getItem("appStatus");
  const applicationStatus = getCookie("appStatus");

  if (applicationStatus !== "approved") {
    history.push(`/${data._id}/steps`);
  }
  if (applicationStatus === "approved") {
    history.push(`/${data._id}/dashboard`);
  }

  return {
    type: USER_AUTHENTICATED.AUTHENTICATED,
    payLoad: data,
    isLogin: true,
  };
};

/******************************* SignOut ****************************************/
const userNotAuthenticatedUpdate = (data, history) => {
  // localStorage.removeItem("token");
  // localStorage.removeItem("deviceId");
  // localStorage.removeItem("appStatus");
  // localStorage.removeItem("userId");

  removeCookie("token");
  removeCookie("deviceId");
  removeCookie("appStatus");
  removeCookie("userId");

  // localStorage.setItem("isLogin", false);
  setCookie("isLogin", false);

  history.push("/sign-in");
  window.location.reload();
  return {
    type: USER_AUTHENTICATED.NOT_AUTHENTICATED,
    payLoad: data,
    isLogin: null,
  };
};

export { userAuthenticatedUpdate, userNotAuthenticatedUpdate };
