import React, { useState, useEffect } from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Menu, Avatar, Dropdown, Spin, Row, Col, Button, Divider } from "antd";
import { AlignRightOutlined, CloseOutlined } from "@ant-design/icons";
import { Media, Nav } from "react-bootstrap";
import styled from "styled-components";
import { mail, LockFilled, UserOutlined } from "@ant-design/icons";
import { ReactComponent as EmergeLogo } from "../assets/logo for Youth.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userNotAuthenticatedUpdate } from "../redux/actions/authenticate";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { client } from "../appollo_client/client";
import Mutations from "../appollo_client/Mutations";
import Queries from "../appollo_client/Queries";

import { useHistory } from "react-router-dom";
import "./StudentRegisteNavbar.css";
import { MdHome } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import { getCookie, setCookie, removeCookie } from "../helpers";

const StudentRegisterNavbar = (props) => {
  const [userProfileData, setUserProfileData] = useState(null);
  const {
    data: userData,
    loading: userLoading,
    error: useError,
  } = useQuery(Queries.GET_USER_PROFILE, {
    client: client,
    onCompleted: () => {
      setUserProfileData(userData && userData.getUserProfile);
    },
    onError: () => {
      console.log(error);
    },
  });

  console.log("UserProfileData", userProfileData?.email);

  // useEffect(() => {
  //   userProfile()
  // }, [])

  const firstLatter =
    userProfileData && userProfileData.firstName.slice(0, 1).toUpperCase();
  const lastLatter =
    userProfileData && userProfileData.lastName.slice(0, 1).toUpperCase();

  const FirstName = userProfileData && userProfileData.firstName;
  const LastName = userProfileData && userProfileData.lastName;

  const [logout, { data, loading, error }] = useMutation(Mutations.LOG_OUT, {
    client: client,
  });
  const history = useHistory();

  const handleLogOut = async () => {
    // const deviceId = localStorage.getItem("deviceId");
    const deviceId = getCookie("deviceId");
    try {
      const res = await logout({
        variables: {
          deviceId: deviceId,
        },
      });
      // localStorage.removeItem("email");
      removeCookie("email");

      // localStorage.removeItem("countryCode");
      removeCookie("countryCode");

      props.logOut(res.data, history);
    } catch (error) {
      // setError(error.message.slice(15))
      console.log("Error >>", error.message);
    }
  };

  const menu = (
    <div
      style={{
        background: "#fff",
        borderRadius: "6px",
        width: "200px",
        padding: "10px 0 0px 0",
        boxShadow: "0px 0px 3px 0px #949492",
        wordBreak: "break-all",
      }}
    >
      <Row>
        <Col span={24}>
          <div
            style={{
              margin: "5px 10px 5px 30px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <span style={{ fontSize: "1.05rem", fontWeight: "600" }}>
              {`${userProfileData?.firstName
                ?.slice(0, 1)
                .toUpperCase()}${userProfileData?.firstName?.slice(
                1,
                userProfileData?.firstName.length
              )} `}
            </span>
            <span style={{ fontSize: "0.8rem" }}>{userProfileData?.email}</span>
          </div>
        </Col>
        <Col
          style={{
            background: "#ccc",
            height: "1px",
            opacity: 0.3,
            margin: "5px 0",
            boxShadow: "0px 0px 2px 0px #ccc",
          }}
          span={24}
        ></Col>
        <Col span={24}>
          <div
            onClick={handleLogOut}
            style={{
              margin: "10px 0",
              width: "100%",
              height: "40px",
            }}
          >
            <div className="logout-container">
              <span style={{ color: "#000" }}>Logout</span>{" "}
              {loading ? <Spin size="small" /> : ""}{" "}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
  const [click, setClick] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = (index) => {
    setClick(false);
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <Navbar style={{ padding: "10px 30px 10px 30px" }}>
      <div>
        <span>
          <Link to="/">
            <EmergeLogo style={{ width: "120px", height: "62px" }} />
          </Link>
        </span>
      </div>
      <DesktopView>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {firstLatter}
          {userProfileData && userProfileData.firstName.slice(1)} {lastLatter}
          {userProfileData && userProfileData.lastName.slice(1)}
          <UserIcon className="px-2">
            <Dropdown
              trigger={["click"]}
              overlay={menu}
              placement="bottomRight"
              arrow
            >
              <Avatar className="student-register-avatar">
                {firstLatter}
                {lastLatter}
              </Avatar>
            </Dropdown>
          </UserIcon>
        </span>
      </DesktopView>
      <MobileView>
        <div className="mobile-menu" onClick={handleClick}>
          {click ? (
            <CloseOutlined style={{ fontSize: 40, color: "#efa718" }} />
          ) : (
            <AlignRightOutlined style={{ fontSize: 40, color: "#efa718" }} />
          )}
        </div>
      </MobileView>
      <MenuDiv className={click ? "nav-options active" : "nav-options"}>
        <Row style={{ justifyContent: "center" }}>
          <div
            onClick={handleLogOut}
            style={{
              margin: "10px 0",
              width: "100%",
              height: "40px",
            }}
          >
            <AvatarRow>
              <Col span={10} style={{ textAlign: "center" }}>
                <AvatarWrapper size={50}>
                  <Initials>
                    {firstLatter}
                    {lastLatter}
                  </Initials>
                </AvatarWrapper>
              </Col>
              <Col span={10}>
                <Col span={24}>
                  <StyledParagraphs>
                    {/* {profileData.firstName} {profileData.lastName} */}
                    {FirstName} {LastName}
                  </StyledParagraphs>
                </Col>
                <Col span={24}>
                  {/* <StyledParagraphs>{profileData.email}</StyledParagraphs> */}
                  <StyledParagraphs>{userProfileData?.email}</StyledParagraphs>
                </Col>
              </Col>
            </AvatarRow>
            <Row align="middle">
              <Col span={24}>
                <MobileMenuDivider />
              </Col>
            </Row>
            <div className="logout-container" style={{ marginTop: 20 }}>
              <span style={{ color: "white" }}>Logout</span>
              {loading ? <Spin size="small" /> : ""}
            </div>
          </div>
        </Row>
      </MenuDiv>
    </Navbar>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    logOut: (data, history) =>
      dispatch(userNotAuthenticatedUpdate(data, history)),
  };
};

export default connect(null, mapDispatchToProps)(StudentRegisterNavbar);

const UserIcon = styled(Nav)`
  @media (max-width: 990px) {
    &.navbar-nav {
      position: absolute;
      top: 23px;
      left: 45%;
    }
  }
`;

const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border-bottom: 0.5px solid lightgray;

  .ant-avatar {
    box-shadow: 0 0 4px 0 black;
    backgroundcolor: #87d068;
    padding: 10px;
    width: 42px;
    height: 42px;
  }
`;

const DesktopView = styled.div`
  @media (max-width: 960px) {
    display: none;
  }
`;

const MobileView = styled.div`
  @media (min-width: 961px) {
    display: none;
  }
`;

const MenuDiv = styled.div`
  @media (min-width: 961px) {
    display: none;
  }
  background-color: #292929;
  height: 190px;
`;

const AvatarRow = styled(Row)`
  margin: 24px;
  @media (min-width: 961px) {
    display: none;
  }
`;

const StyledParagraphs = styled.p`
  margin-bottom: 0px;
  margin-top: 3px;
  color: white;
`;

const MobileMenuDivider = styled(Divider)`
  margin: 0px;
  @media (max-width: 960px) {
    background-color: #efa718;
  }
  @media (min-width: 961px) {
    display: none;
  }
`;

const AvatarWrapper = styled(Avatar)`
  background-color: white;
`;

const Initials = styled.p`
  color: black;
  transform: scale(1) translateX(0%) translateY(-17%);
  font-weight: 800;
  font-size: 25px;
`;
