import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Link } from "react-router-dom";
import { Form, Button, Col, Row, Spin } from "antd";
import ApplicationNavbar from "../components/applicationQuestions/ApplicaitonNavbar";
import StudentApplicationFormContainer from "../components/applicationQuestions/StudentApplicationFormContainer";
import { LeftOutlined } from "@ant-design/icons";
import { client } from "../appollo_client/client";
import Mutations from "../appollo_client/Mutations";
import Queries from "../appollo_client/Queries";
import { useQuery } from "@apollo/client";
import GetUserIdFromUrl from "../GetUserIdFromUrl";

const ApplicationQuestions = () => {
  const [step, setStep] = useState(1);
  const [appQuestions, setAppQuestions] = useState(null);

  const { data, loading, error } = useQuery(Queries.GET_APP_QUESTIONS, {
    client: client,
    onCompleted: () => {
      setAppQuestions(data.appQuestions);
    },
    onError: () => {
      console.log("Error in applicaitonQuestions");
    },
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <div style={{ position: "absolute", top: 100, left: "125px" }}>
        <Link
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          to={`/${GetUserIdFromUrl()}/steps`}
        >
          <LeftOutlined /> GO BACK TO MAIN PAGE
        </Link>
      </div>
      <Col style={{ width: 900 }}>
        <SignInCard>
          <div
            style={{
              padding: "10px 30px",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            <h2 className="mb-0">Application Questions</h2>
            <p style={{ marginBottom: 0 }}>
              Be as honest and genuine as possible.
            </p>
          </div>
          <ApplicationNavbar step={step} setStep={setStep} />
          {loading ? (
            <Spin style={{ marginTop: 150 }} size="large" />
          ) : (
            <StudentApplicationFormContainer
              data={appQuestions}
              step={step}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          )}
        </SignInCard>
      </Col>
    </Row>
  );
};

export default ApplicationQuestions;

const SignInCard = styled.div`
  padding: 30px;
  background-color: white;
  // width: 100%;
  height: unset;
  //   justify-content:center;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 6px -2px black;
  align-items: center;
  border-radius: 5px;
  min-height: 540px;

  @media (max-width: 360px) {
    height: 500px;
  }
`;
