import React from "react";
import styled from "styled-components";
import { Button } from "antd";

const StyledButton = styled(Button)`

  font-size: 1.1em;
  border-radius: 0.2em
`;

const ButtonWrapper = props => {
return <StyledButton {...props}>{props.children}</StyledButton>;
};

export default ButtonWrapper;
