import React from "react";
import { Card, Button, Typography } from "antd";
import styled from "styled-components";

const ResultStatus = (props) => {
  console.log("Result props", props);
  const data = {
    topicData: props.topicData,
    resultData: props.resultData[0],
    setTakeTestModal: props.setTakeTestModal,
  };
  const status = () => {
    if (
      props.resultData &&
      props.resultData.find((item) => item.status == "pass")
    ) {
      // const temp = props.resultData.find(item => item._id === props.topicId);
      // console.log(temp, 'temp')
      return (
        <StatusBtn
          loading={props.resultLoading}
          style={{ background: "#0DB14B" }}
          onClick={() =>
            props.setModal({
              ...props.Modal,
              modalBolean: true,
              data: props.resultData[0],
            })
          }
        >
          Passed
        </StatusBtn>
      );
    } else if (
      props.resultData &&
      props.resultData.find((item) => item.status == "fail")
    ) {
      return (
        <StatusBtn
          onClick={() =>
            props.setFailModal({
              ...props.failModal,
              modalBolean: true,
              data: data,
            })
          }
          loading={props.resultLoading}
          style={{ background: "rgb(255, 72, 66)" }}
        >
          Failed
        </StatusBtn>
      );
    } else if (props.resultError == "GraphQL error: Data not found") {
      return (
        <StatusBtn
          loading={props.resultLoading}
          style={{ background: "#EEA71F" }}
          onClick={() =>
            props.setTakeTestModal({ modalBolean: true, data: props.topicData })
          }
        >
          Take Test
        </StatusBtn>
      );
    }
  };
  return (
    <p>
      {status() || (
        <StatusBtn
          loading={props.resultLoading}
          style={{ background: "#EEA71F" }}
          onClick={() =>
            props.setTakeTestModal({ modalBolean: true, data: props.topicData })
          }
        >
          Take Test
        </StatusBtn>
      )}
    </p>
  );
};

export default ResultStatus;

const StatusBtn = styled(Button)`
  letter-spacing: 1px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  // border-color: #EFA718;
  border-radius: 20px !important;
  transition: ease 1s;
  background-color: #efa718;
  color: white;
  // box-shadow: 0 0 3px 0.4px black;
  :hover {
    color: white;
    background-color: #efa718;
    transition: ease 1s;
    border-color: black;
  }
  :focus {
    color: white;
    background-color: #efa718;
    transition: ease 1s;
    border-color: black;
  }
`;
