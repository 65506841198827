import gql from "graphql-tag";

const Queries = {
  GET_USER_PROFILE: gql`
    {
      getUserProfile {
        _id
        email
        firstName
        lastName
        gender
        country
        city
        applicationStatus
        profileStatus
        isJob
        isMaster
        isInter
        birthday
        bio
        phone
        coverImage
        profileImage
        personalityType
      }
    }
  `,
  GET_APP_QUESTIONS: gql`
    {
      appQuestions {
        _id
        question
        discription
        status
      }
    }
  `,

  GET_ALL_LIBRARY: gql`
    {
      getAllLibrary {
        _id
        topicName
        description
        topicUrl
        topicType
        category
      }
    }
  `,

  GET_USER_PORTFOLIO: gql`
    query getUserPortfolio($userId: ID) {
      getUserPortfolio(userId: $userId) {
        _id
        userId {
          firstName
        }
        description
        portfolioUrl
        portfolioType
        portfolioTitle
      }
    }
  `,

  // GET_ALL_COURSES: gql`
  //   {
  //     getAllCourses {
  //       createdAt
  //       _id
  //       courseName
  //       description
  //       courseImageURL
  //     }
  //   }
  // `,

  GET_COURSE_DETAILS: gql`
    query getCourseDetails($courseId: String!) {
      getCourseDetails(courseId: $courseId) {
        _id
        courseId {
          courseName
        }
        description
        materialUrl
        materialType
        week
        topicName
      }
    }
  `,

  GET_TEST_RESULT: gql`
    query getTestResult($courseId: ID!, $week: Float) {
      getTestResult(courseId: $courseId, week: $week) {
        _id
        userId {
          firstName
        }
        courseId {
          topicName
        }
        status
        totalMarks
        obtMarks
        result
        percent
      }
    }
  `,

  GET_TEST_QUESTIONS: gql`
    query getTestQuestions($courseId: ID!, $week: Float) {
      getTestQuestions(courseId: $courseId, week: $week) {
        _id
        question
        courseId {
          topicName
          courseId {
            courseName
          }
        }
        week
        choices
        correctAnswer
        status
      }
    }
  `,

  GET_ALL_CATEGORIES: gql`
    {
      getAllCategories {
        category
      }
    }
  `,

  GET_LIBRARY_BY_CATEGORY: gql`
    query getLibraryByCategory($category: String!) {
      getLibraryByCategory(category: $category) {
        _id
        topicName
        description
        topicUrl
        topicType
        category
        createdAt
      }
    }
  `,

  GET_USER_RECENT_ACTIVITY: gql`
    query getUserRecentActivity($userId: ID) {
      getUserRecentActivity(userId: $userId) {
        _id
        userId {
          firstName
        }
        description
        recentActivityUrl
        recentActivityType
        recentActivityTitle
        tags
        createdAt
      }
    }
  `,

  GET_USER_BY_ID: gql`
    query getUserById($userId: ID) {
      getUserById(userId: $userId) {
        _id
        email
        firstName
        lastName
        gender
        city
        applicationStatus
        profileStatus
        isJob
        isMaster
        isInter
        birthday
        bio
        phone
        country
        profileImage
        coverImage
        personalityType
      }
    }
  `,

  VERIFY_LOGGED_USER: gql`
    query verifyLoggedUser($userId: String!, $token: String!) {
      verifyLoggedUser(id: $userId, jwtToken: $token) {
        _id
        email
        firstName
        lastName
        gender
        city
        applicationStatus
        profileStatus
        isJob
        isMaster
        isInter
        birthday
        bio
        phone
        country
        profileImage
        coverImage
        personalityType
      }
    }
  `,

  GET_REGISTERED_COURSES: gql`
    {
      getRegisteredCourses {
        _id
        userId {
          _id
          firstName
        }
        courseId {
          createdAt
          _id
          courseName
          description
          courseImageURL
        }
        status
        completionStatus
      }
    }
  `,

  GET_ALL_USER_COURSES: gql`
    {
      getAllUserCourses {
        courseId {
          createdAt
          _id
          courseName
          description
          courseImageURL
        }
        status
      }
    }
  `,

  GET_USER_APPROVED_COURSES: gql`
    {
      getUserApprovedCourses {
        _id
        userId {
          firstName
        }
        courseId {
          createdAt
          _id
          courseName
          description
          courseImageURL
        }
        status
        completionStatus
      }
    }
  `,
};

export default Queries;
