import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Form, Button, Col, Row, Checkbox, Input } from "antd";
import { MailOutlined, UserOutlined, SettingOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";

const HearAbout = (props) => {
  const options = [
    { label: "School", value: "School" },
    { label: "Friends", value: "Freinds" },
    { label: "My Parents", value: "My Parents" },
    { label: "Other", value: "Other" },
    { label: "Emerge Alumni", value: "Emerge Alumni" },
    { label: "Facebook", value: "Facebook" },
    { label: "Youtube", value: "Youtube" },
    { label: "Emerge Referral", value: "Emerge Referral" },
  ];

  function onChange(checkedValues) {
    props.setInfoState({ ...props.infoState, hearAbout: checkedValues });
  }

  const previous = (e) => {
    props.prevStep();
  };
  const finish = (e) => {
    props.handleSubmit();
  };
  // console.log("Props Is submittable >>> ", props.isSubmittable());
  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={0} className="queue-simple">
        <Form
          key="a"
          onFinish={finish}
          // style={{ background: "#E6D720", height: "100%" }}
        >
          <h4 style={{ textAlign: "center", margin: 20 }}>
            How did you hear about Emerge?
          </h4>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <CheckboxGroupWrapper
              options={options}
              onChange={onChange}
              value={props.infoState.hearAbout}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              size="large"
              onClick={previous}
              style={{ backgroundColor: "lightgray" }}
            >
              Previous
            </Button>
            <Button
              disabled={!props.isSubmittable()}
              size="large"
              htmlType="submit"
              type="primary"
              loading={props.loading}
            >
              Finish
            </Button>
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default HearAbout;

const CheckboxGroupWrapper = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
    display: flex;
  }
`;
