const GetUserIdFromUrl = () => {
  const link = window.location.href;
  const startIndex = link.indexOf("/", 10);
  const lastIndex = link.indexOf("/", startIndex + 1);
  const userId = link.slice(startIndex + 1, lastIndex);

  return userId;
};

export default GetUserIdFromUrl;
