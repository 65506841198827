import React from "react";
import { Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";
import { Modal, Row, Col } from "antd";
import "antd/dist/antd.less";
import "./AboutItem.css";
// import 'antd/lib/modal/style/css'
import { getCookie, setCookie } from "../helpers";

import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const AboutItem = (props) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <Modal
        // visible={true}
        visible={showModal}
        footer={false}
        onCancel={() => {
          props.setModalOpen();
          // localStorage.setItem("modalOpen", "false");
          setCookie("modalOpen", "false");
          setShowModal(false);
        }}
      // wrapClassName='modal'
      >
        {/* <h2 className="modalHeading">{props.heading}</h2> */}
        {/* <p className="modalText">{props.text}</p> */}
        <Row style={{ padding: "2% 3%" }}>
          <Col span={24}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Row>
                <Col
                  style={{
                    margin: "50px auto 10px auto",
                  }}
                >
                  <div className="modal_item_img">
                    {props.svgImg ? (
                      <img src={props.svgImg} alt={props.heading} />
                    ) : (
                      <props.cardImg
                        style={{ width: "100%", height: "100%" }}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Col span={24} style={{ margin: "10px auto 0px auto" }}>
                  <h2 className="modalHeading">{props.heading}</h2>
                </Col>
              </Row>
              <Row
                style={{
                  width: "80%",
                  height: "1px",
                  opacity: "0.3",
                  background: "#a8a8a8",
                  margin: "8px 0",
                  rowGap: "0px",
                }}
              >
                <Col>
                  {/* <div style={{ background: "#ebbd34", height: "1px" }}> </div> */}
                </Col>
              </Row>

              <Row>
                <Col style={{ margin: "7px auto", width: "80%" }}>
                  <p className="modalText">{props.text}</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal>
      <li className="cards__item">
        <div
          className="about__item__link"
          onClick={() => {
            props.setModalOpen();
            // localStorage.setItem("modalOpen", "true");
            setCookie("modalOpen", "true");
            setShowModal(true);
          }}
        >
          {/* <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.src}
            />
          </figure> */}

          <div className="cards__item__info">
            <div className="card_item_flex">
              <div className="card_item_img">
                {props.svgImg ? (
                  <img src={props.svgImg} />
                ) : (
                  <props.cardImg style={{ width: "100%", height: "100%" }} />
                )}
              </div>
              <div className="card_item_text_content">
                <h5 className="cards__item__text">
                  <span>
                    {/* <p>test</p> */}
                    {props.heading}
                    {/* <img draggable="false" style={{marginLeft:'6px'}}role="img" width='20px' height='20px' class="emoji" alt="🏆" src={props.src}></img> */}
                  </span>
                </h5>
                <ResponsiveEllipsis
                  text={props.text}
                  maxLine="3"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                  tagname="p"
                  style={{
                    marginTop: "0px",
                    fontSize: "15px",
                    color: "#252e48",
                  }}
                />
              </div>
              {/* <p style={{marginTop:'15px',color:'#252e48'}}>
                  {props.text}
              </p> */}
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default AboutItem;
