import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Row } from "antd";
import { ReadMoreBtn } from "../components/button/readMoreButton";
import { getLinkPreview } from "link-preview-js";
import CardSkeletonLoading from "./CardSkeletonLoading";
import "./ArticleCard.css";
import { connect } from "react-redux";

const ArticleCard = (props) => {
  const { articleCache, saveArticleData } = props;
  const { portfolioUrl, topicUrl } = props.data;

  const [loading, setLoading] = useState(false);
  const [articleData, setArticleData] = useState("");
  const articleRef = useRef();

  const fetchArticleData = async (evt) => {
    setLoading(true);
    await getLinkPreview(
      `https://secret-ocean-49799.herokuapp.com/${portfolioUrl || topicUrl}`
    ).then((data) => {
      setArticleData(data);
      saveArticleData({ name: portfolioUrl || topicUrl, data });
    });
    setLoading(false);
  };

  useEffect(() => {
    articleCache[portfolioUrl || topicUrl]
      ? setArticleData(articleCache[portfolioUrl || topicUrl])
      : fetchArticleData();
  }, [portfolioUrl, topicUrl]);

  return (
    <>
      {articleData ? (
        <div>
          {loading ? (
            <CardSkeletonLoading />
          ) : (
            <Card
              className="article-cards my-3"
              hoverable
              style={{
                width: 340,
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                margin: "0 11px",
              }}
              cover={
                <img
                  alt="example"
                  src={articleData && articleData.images[0]}
                  height="180px"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                />
              }
              actions={[
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 10px",
                    flexDirection: "row-reverse",
                  }}
                >
                  <ReadMoreBtn target="_blank" href={portfolioUrl || topicUrl}>
                    Read More
                  </ReadMoreBtn>
                </div>,
              ]}
            >
              <Card.Meta
                title={articleData && articleData?.title}
                description={`${articleData && articleData.description}...`}
              />
            </Card>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

const mapDispatch = (dispatch) => ({
  saveArticleData: (data) =>
    dispatch({ type: "SAVE_ARTICLE_DATA", payload: data }),
});

const mapState = (state) => ({
  articleCache: state.articleReducer,
});

export default connect(mapState, mapDispatch)(ArticleCard);
