import React from "react";
import Navbar from "../landing-page-components/Navbar";
import LandingPage from "../pages/LandingPage";

const LandingPageRoute =() => {
  return (
    <>
      <Navbar />
      <LandingPage />
    </>
  );
}


export default LandingPageRoute;
