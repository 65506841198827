import React from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Menu, Row, Col } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  UsergroupAddOutlined,
  BankOutlined,
  SoundOutlined,
} from "@ant-design/icons";

const InfoNavbar = (props) => {
  // console.log(props.step);

  const navStyle = props.step
    ? {
        borderColor: "#03a1fc",
        color: "#03a1fc",
      }
    : "";

  return (
    <Row style={{ width: "100%" }} justify="center">
      <Col span={24}>
        <MenuWrapper mode="horizontal" selectedKeys={`${props.step}`}>
          <Menu.Item
            key="1"
            disabled={!(props.step >= 1)}
            style={props.step === 1 ? navStyle : ""}
            onClick={() => props.setStep(1)}
            icon={
              <MailOutlined style={{ position: "relative", top: "-3px" }} />
            }
          >
            ABOUT
          </Menu.Item>
          {/* <Menu.Item
            key="2"
            disabled={!(props.step >= 2)}
            style={props.step === 2 ? navStyle : ""}
            onClick={() => props.setStep(2)}
            icon={
              <PhoneOutlined style={{ position: "relative", top: "-3px" }} />
            }
          >
            CONTACT
          </Menu.Item> */}
          <Menu.Item
            key="2"
            disabled={!(props.step >= 2)}
            style={props.step === 2 ? navStyle : ""}
            onClick={() => props.setStep(2)}
            icon={
              <UsergroupAddOutlined
                style={{ position: "relative", top: "-3px" }}
              />
            }
          >
            PARENT/GUARDIAN
          </Menu.Item>
          <Menu.Item
            key="3"
            disabled={!(props.step >= 3)}
            style={props.step === 3 ? navStyle : ""}
            onClick={() => props.setStep(3)}
            icon={
              <BankOutlined style={{ position: "relative", top: "-3px" }} />
            }
          >
            SCHOOL
          </Menu.Item>
          <Menu.Item
            key="4"
            disabled={!(props.step >= 4)}
            style={props.step === 4 ? navStyle : ""}
            onClick={() => props.setStep(4)}
            icon={
              <SoundOutlined style={{ position: "relative", top: "-3px" }} />
            }
          >
            HEAR ABOUT
          </Menu.Item>
        </MenuWrapper>
      </Col>
    </Row>
  );
};

export default InfoNavbar;

const MenuWrapper = styled(Menu)`
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  width: 100%;
  // margin: 20px 0;
  // .ant-menu-item:hover {
  //   background-color: #03a1fc;
  //   color: white !important;
  //   box-shadow: 0 0 4px 1px #076aa3 !important;
  //   transition: all 0.5s ease;
  // }

  .ant-menu-item {
    margin: 0 10px;
    padding: 0 10px !important;
    border-radius: 3px;
    transition: all 0.5s ease;
    // font-size: 20px;
    // @media (max-width: 785px) {
    //   padding: 0 5px !important;
    // }
    // @media (max-width: 850px) {
    //   font-size: 12px;
    //   padding: 0 0 !important;
    // }
    // @media (max-width: 550px) {
    //   padding: 0 5px !important;
    // }
    // @media (max-width: 360px) {
    //   padding: 0 10px !important;
    // }
  }
`;
