import React, { useState } from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Menu, Row, Col } from "antd";
import { DeviceBreakePoints } from "../deviceBreakPoints/DeviceBreakePoints";

const ApplicationNavbar = (props) => {
  // console.log("1 props", props.step);

  const navStyle = props.step
    ? {
        borderColor: "#03a1fc",
        color: "#03a1fc",
      }
    : "";

  return (
    <Row style={{ width: "100%" }} justify="center">
      <Col span={24}>
        <MenuWrapper mode="horizontal" selectedKeys={`${props.step}`}>
          <Menu.Item
            key="1"
            disabled={!(props.step >= 1)}
            style={props.step === 1 ? navStyle : ""}
            onClick={() => props.setStep(1)}
          >
            1
          </Menu.Item>
          <Menu.Item
            key="2"
            disabled={!(props.step >= 2)}
            style={props.step === 2 ? navStyle : ""}
            onClick={() => props.setStep(2)}
          >
            2
          </Menu.Item>
          <Menu.Item
            key="3"
            disabled={!(props.step >= 3)}
            style={props.step === 3 ? navStyle : ""}
            onClick={() => props.setStep(3)}
          >
            3
          </Menu.Item>
          <Menu.Item
            key="4"
            disabled={!(props.step >= 4)}
            style={props.step === 4 ? navStyle : ""}
            onClick={() => props.setStep(4)}
          >
            4
          </Menu.Item>
          <Menu.Item
            key="5"
            disabled={!(props.step >= 5)}
            style={props.step === 5 ? navStyle : ""}
            onClick={() => props.setStep(5)}
          >
            5
          </Menu.Item>
        </MenuWrapper>
      </Col>
    </Row>
  );
};

export default ApplicationNavbar;

const MenuWrapper = styled(Menu)`
  display: flex;
  justify-content: center;
  background: #f2f2f2;
  width: "100%";

  // .ant-menu-item:hover {
  //   background-color: #03a1fc;
  //   color: white !important;
  //   box-shadow: 0 0 4px 1px #076aa3 !important;
  //   transition: all 0.5s ease;
  // }
  .ant-menu-item {
    margin: 0 20px;
    padding: 0 60px !important;
    border-radius: 3px;
    transition: all 0.5s ease;
    @media (max-width: 850px) {
      padding: 0 50px !important;
    }
    @media (max-width: 750px) {
      padding: 0 40px !important;
    }
    @media (max-width: 650px) {
      padding: 0 30px !important;
    }
    @media (max-width: 550px) {
      padding: 0 15px !important;
    }
    @media (max-width: 460px) {
      padding: 0 10px !important;
    }
    @media (max-width: 360px) {
      padding: 0 5px !important;
    }
  }
`;
