import React from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Form, Button, Input, Tooltip } from "antd";
import QueueAnim from "rc-queue-anim";
import Mutations from "../../appollo_client/Mutations";
import { useMutation } from "@apollo/client";
import { InfoCircleOutlined } from "@ant-design/icons";
import { client } from "../../appollo_client/client";
const { TextArea } = Input;

const QuestionFour = (props) => {
  const [submitApplicationAnswers, { data, loading, error }] = useMutation(
    Mutations.SUBMIT_APPLICATION_ANSWERS,
    { client: client }
  );

  const next = async (e) => {
    try {
      const res = await submitApplicationAnswers({
        variables: {
          answer: props.applicationState.questionFour,
          questionId: props.data && props.data._id,
        },
      });
      // console.log(res, "response in application submit");
      props.nextStep();
    } catch (error) {
      console.log(error.message, "Application submit error");
    }
  };
  const previous = (e) => {
    props.prevStep();
  };

  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={0} className="queue-simple">
        <Form key="a" onFinish={next}>
          <h5 style={{ margin: "20px 0" }}>
            {props.data && props.data.question}
          </h5>
          <h6 style={{ margin: "20px 0", color: "gray" }}>
            {props.data && props.data.discription}
          </h6>

          <Form.Item
            initialValue={props.applicationState.questionFour}
            name="firstName"
            rules={[
              {
                required: true,
                min: 100,
                max: 350,
                message: "Answer must be between 100 and 350 characters",
              },
            ]}
          >
            <TextArea
              showCount
              rows={5}
              style={{ minHeight: 100, maxHeight: 100 }}
              name="name"
              type="text"
              placeholder="Tesla, an inventer."
              onChange={(e) =>
                props.setApplicationState({
                  ...props.applicationState,
                  questionFour: e.target.value,
                })
              }
              maxLength={350}
            />
          </Form.Item>
          {/* {props.applicationState.questionFour.length !== 0 ? (
            <p style={{ color: "#f00" }}>
              Answer must be between 10 and 350 characters*
            </p>
          ) : (
            <></>
          )} */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <Button
              size="large"
              onClick={previous}
              style={{ backgroundColor: "lightgray" }}
            >
              {" "}
              Previous
            </Button>
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              loading={loading}
            >
              Next{" "}
            </Button>
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default QuestionFour;
