import React from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa";
// import {ReactComponent as EmergeLogoFooter} from './images/emgYpBlackBgLogo.svg';
import { ReactComponent as EmergeLogoFooter } from "../assets/logo for Youth-white-01.svg";
import moment from "moment";

function Footer() {
  let currentYear = moment().format("YYYY");

  return (
    <div className="footer-container" id="info">
      <section className="footer-subscription">
        <p
          style={{ fontFamily: "PT Sans" }}
          className="footer-subscription-heading"
        >
          Join the Emerge newsletter to receive updates
        </p>
        <p
          style={{ fontFamily: "PT Sans" }}
          className="footer-subscription-text"
        >
          You can unsubscribe at any time.
        </p>
        <div className="input-areas">
          <form>
            <input
              className="footer-input"
              name="email"
              type="email"
              placeholder="Your Email"
              required
            />
            <Button buttonStyle="btn--outline">Subscribe</Button>
          </form>
        </div>
      </section>
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About Us</h2>
            <Link className="footer-text-links" to="/">
              How it works
            </Link>
            <Link className="footer-text-links" to="/">
              Testimonials
            </Link>
            <Link className="footer-text-links" to="/">
              Careers
            </Link>
            <Link className="footer-text-links" to="/">
              Investors
            </Link>
            <Link className="footer-text-links" to="/">
              Terms of Service
            </Link>

            <a
              className="footer-text-links"
              href="https://www.emergeforward.com/"
              target="_blank"
            >
              Philosophy
            </a>
          </div>
          <div className="footer-link-items">
            <h2>Social Media</h2>
            <a
              className="footer-text-links"
              href="https://www.instagram.com/emergeforward/"
              target="_blank"
            >
              Instagram
            </a>
            <a
              className="footer-text-links"
              href="https://www.facebook.com/LetsEmergeForward"
              target="_blank"
            >
              Facebook
            </a>
            <a
              className="footer-text-links"
              href="https://www.youtube.com/channel/UCFkPtfpMV_z1QVxvpGheNIg/featured"
              target="_blank"
            >
              Youtube
            </a>
            <a
              className="footer-text-links"
              href="https://twitter.com/EmergeForward"
              target="_blank"
            >
              Twitter
            </a>
          </div>
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <p style={{ fontSize: "0.9rem", color: "#8A8A8A" }}>
              Mezzanine floor, Cornish Building,Comm. Lane 3, Clifton Block 2,
              Karachi 75600
            </p>
            <a className="footer-text-links">+92 213 5863176</a>
            <a
              className="footer-text-links"
              href="mailto:info@emergeforward.com"
            >
              info@emergeforward.com
            </a>
          </div>
        </div>
        {/* <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="/">
              Mezzanine floor, Cornish Building,Comm. Lane 3, Clifton Block 2,
              Karachi 75600
            </Link>
            <Link to="/">+92 213 5863176</Link>
            <a href="mailto:info@emergeforward.com">info@emergeforward.com</a>
          </div>
        </div> */}
      </div>
      {/* <div
        style={{
          width: "80%",
          height: 1,
          background: "#484848",
          marginTop: "10px",
        }}
      ></div> */}
      <section className="social-media">
        <div
          style={{
            width: "80%",
            height: 1,
            background: "#484848",
            position: "absolute",
            left: "10%",
          }}
        ></div>
        <div className="social-media-wrap">
          <div className="footer-logo">
            {/* <Link to='/' className='social-logo'> */}
            {/* Emerge */}
            {/* <i class='fab fa-typo3' /> */}
            {/* <img src='/images/logo-dark-90x66.png' width='45px' height='45px' style={{marginLeft:'-10px'}}/> */}
            {/* </Link> */}
            <div
              style={{ width: "100px", height: "70px", marginLeft: "-10px" }}
            >
              <EmergeLogoFooter
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
              {/* <img
                src={EmergeLogoFooter}
                style={{ width: "100%", height: "100%" }}
              /> */}
            </div>
          </div>
          <small className="website-rights">
            Copyright{" "}
            <a style={{ color: "#fff" }} className="footer-text-links">
              EmergeYouth®
            </a>{" "}
            | {currentYear} All Rights Reserved{" "}
          </small>
          <div className="social-icons">
            <a
              className="social-icon-link facebook"
              href="https://www.facebook.com/LetsEmergeForward"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebookF />
              {/* <i className="fab fa-facebook-f" /> */}
            </a>
            <a
              className="social-icon-link instagram"
              href="https://www.instagram.com/emergeforward/"
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
              {/* <i className="fab fa-instagram" /> */}
            </a>
            <a
              className="social-icon-link youtube"
              href="https://www.youtube.com/channel/UCFkPtfpMV_z1QVxvpGheNIg/featured"
              target="_blank"
              aria-label="Youtube"
            >
              <FaYoutube />
              {/* <i className="fab fa-youtube" /> */}
            </a>
            <a
              className="social-icon-link twitter"
              href="https://twitter.com/EmergeForward"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter />
              {/* <i className="fab fa-twitter" /> */}
            </a>
            {/* <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
