import React, { useState, useEffect } from "react";
import { Card, Button, Typography, Modal } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import Styled from "styled-components";
import TopicCardSkeletonLoading from "./TopicCardSkeletonLoading";
import ResultStatus from "./ResultStatus";
import { getLinkPreview } from "link-preview-js";
import Queries from "../appollo_client/Queries";
import { client } from "../appollo_client/client";
import { useQuery, useLazyQuery } from "@apollo/client";
import TestPassModal from "./TestPassModal";
import TakeTestModal from "./TakeTestModal";
import TestFailModal from "./TestFailModal";
import ReactPlayer from "react-player";

const CourseTopicCard = (props) => {
  const admin_description = props.data.description;
  const admin_title = props.data.topicName;

  const { materialUrl, _id, week } = props.data;
  const [loading, setLoading] = useState(false);
  const [articleData, setArticleData] = useState("");
  const [resultData, setResultData] = useState("");
  const [resultError, setResultError] = useState("");
  const [modal, setModal] = useState({ modalBolean: false, data: "" });
  const [takeTestModal, setTakeTestModal] = useState({
    modalBolean: false,
    data: "",
  });
  const [failModal, setFailModal] = useState({ modalBolean: false, data: "" });

  const { images, description, title } = articleData;
  // console.log("Material URL >>>", materialUrl);
  // =============get test result query===========
  const [getTestResult, { data, loading: resultLoading, error }] = useLazyQuery(
    Queries.GET_TEST_RESULT,
    {
      client: client,
      onCompleted: () => {
        setResultData(data.getTestResult);
      },
      onError: () => {
        setResultError(error.message);
        console.log(error, "error in get test result query");
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    fetchGetTestResult();
  }, [props.data]);

  const fetchGetTestResult = () => {
    getTestResult({ variables: { courseId: _id, week: week } });
  };

  useEffect(() => {
    fetchArticleData();
  }, [materialUrl]);

  const fetchArticleData = async (evt) => {
    setLoading(true);
    await getLinkPreview(
      `https://secret-ocean-49799.herokuapp.com/${materialUrl}`
    ).then((data) => {
      setArticleData(data);
    });
    setLoading(false);
  };
  // console.log("Article Data >>> ", articleData);
  // console.log("Material URL >>> ", materialUrl);
  // console.log("Props data >>> ", props.data);

  const isVideo = () => {
    if (
      materialUrl &&
      materialUrl.slice(0, 32) === "https://www.youtube.com/watch?v="
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <div>
        {loading ? (
          <TopicCardSkeletonLoading />
        ) : (
          <>
            <TestPassModal modal={modal} setModal={setModal} />
            <TakeTestModal
              takeTestModal={takeTestModal}
              setTakeTestModal={setTakeTestModal}
              fetchGetTestResult={fetchGetTestResult}
            />
            <TestFailModal failModal={failModal} setFailModal={setFailModal} />
            {!isVideo() ? (
              <CardWrapper
                bodyStyle={{ padding: 10 }}
                className="my-3"
                hoverable
                style={{
                  width: 250,
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  margin: "0 10px",
                }}
                cover={
                  <img
                    alt="example"
                    src={images && images[0]}
                    height="150px"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                }
                actions={[
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: "5px 10px",
                    }}
                  >
                    <h4 className="mb-0">
                      {/* <span className='mb-0'>status:</span> */}
                    </h4>
                    <ResultStatus
                      resultData={resultData}
                      resultError={resultError}
                      resultLoading={resultLoading}
                      setModal={setModal}
                      setTakeTestModal={setTakeTestModal}
                      setFailModal={setFailModal}
                      topicData={props.data}
                    />
                  </div>,
                ]}
              >
                <Card.Meta
                  title={title}
                  description={
                    <Typography.Link
                      className={"typography"}
                      href={materialUrl}
                      target="_blank"
                    >
                      {description}
                    </Typography.Link>
                  }
                />
              </CardWrapper>
            ) : (
              <CardWrapper
                bodyStyle={{ padding: 10 }}
                className="my-3"
                hoverable
                style={{
                  width: 250,
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  margin: "0 10px",
                }}
                cover={
                  <ReactPlayer
                    controls
                    url={materialUrl && materialUrl}
                    width="100%"
                    height="100%"
                  />
                }
                actions={[
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: "5px 10px",
                    }}
                  >
                    <h4 className="mb-0">
                      {/* <span className='mb-0'>status:</span> */}
                    </h4>
                    <ResultStatus
                      resultData={resultData}
                      resultError={resultError}
                      resultLoading={resultLoading}
                      setModal={setModal}
                      setTakeTestModal={setTakeTestModal}
                      setFailModal={setFailModal}
                      topicData={props.data}
                    />
                  </div>,
                ]}
              >
                <Card.Meta
                  title={admin_title}
                  description={
                    <Typography.Link
                      className={"typography"}
                      href={materialUrl}
                      target="_blank"
                    >
                      {admin_description}
                    </Typography.Link>
                  }
                />
              </CardWrapper>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CourseTopicCard;

const CardWrapper = Styled(Card)`
box-shadow: 1px 0 6px 1px #ccc;
.ant-card-body{
  height:110px;
}

.ant-card-cover{
  height: 150px;
}
.ant-card-actions{
    margin: 0px 0px !important;
}
.ant-card-actions li{
    margin: 0 !important;
}
.typography{
    color: gray;
}
.ant-card-cover div video {
  position: static !important;
}
`;
