const initialState = {
  allActivities: [],
};

const getAllRecentActivity = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_ACTIVITY":
      return { ...state, allActivities: action.payload.reverse() };

    case "ADD_NEW_ACTIVITY":
      return {
        ...state,
        allActivities: [
          ...state.allActivities.reverse(),
          action.payload,
        ].reverse(),
      };

    default:
      return state;
  }
};

export default getAllRecentActivity;
