const initialState = {
  portfolioData: [],
};

const getPortfolioData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PORTFOLIO_DATA":
      return { ...state, portfolioData: action.payload.reverse() };
    case "ADD_PORTFOLIO_DATA":
      console.log("action.payload", action.payload);
      return {
        ...state,
        portfolioData: [
          ...state.portfolioData.reverse(),
          action.payload,
        ].reverse(),
      };
    default:
      return state;
  }
};

export default getPortfolioData;
