import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Tabs, Spin, Select, Empty, Row } from "antd";
import ArticlesData from "../components/ArticlesData";
import ArticleCard from "../components/ArticleCard";
import VideoCard1 from "../components/VideoCard1";
import QueueAnim from "rc-queue-anim";
import AddArticleVideoModal from "../components/AddArticleVideoModal";
import { client } from "../appollo_client/client";
import { useQuery, useLazyQuery } from "@apollo/client";
import Queries from "../appollo_client/Queries";
import AboutHeader from "../components/AboutHeader";
import PaginationComp from "../components/PaginationComp";
import UserBanner from "../components/banner/userBanner";

const { TabPane } = Tabs;
const { Option } = Select;

const Library = () => {
  const [addModal, setAddModal] = useState(false);
  const [libraryData, setLibraryData] = useState(null);
  const [allCategories, setAllCategories] = useState(null);

  const articleData = [];
  const videoData = [];

  // =============Get all Categories===========
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useQuery(Queries.GET_ALL_CATEGORIES, {
    client: client,
    onCompleted: () => {
      setAllCategories(categoryData.getAllCategories);
    },
    onError() {
      console.log(error);
    },
  });

  // ===================Get all liberary==============
  const { data, loading, error } = useQuery(Queries.GET_ALL_LIBRARY, {
    client: client,
    onCompleted: () => {
      setLibraryData(data && data.getAllLibrary);
    },
    onError: () => {
      console.log(error);
    },
  });

  // ===============Seprate article and video data===========
  libraryData &&
    libraryData.map((item, index) => {
      if (item.topicType === "article") {
        articleData.push(item);
      } else if (item.topicType === "videos") {
        videoData.push(item);
      }
    });

  // ==============Category select handle==========

  function categoryHandle(value) {
    getLibraryByCategory({ variables: { category: value } });
  }

  const [
    getLibraryByCategory,
    {
      data: getLibraryData,
      loading: getLibraryLoading,
      error: getLibraryError,
    },
  ] = useLazyQuery(Queries.GET_LIBRARY_BY_CATEGORY, {
    client: client,
    // variables: {category: value},
    onCompleted: () => {
      setLibraryData(getLibraryData.getLibraryByCategory);
    },
    onError: (error) => {
      console.log(error, "error in get library by category");
    },
  });

  const operations = (
    <SelectWrapper
      bordered="false"
      showSearch={true}
      placeholder="Categories"
      onChange={categoryHandle}
      style={{ width: 200 }}
    >
      <Option value="all">View all</Option>
      {allCategories &&
        allCategories.category.map((item, index) => {
          if (item !== "all") {
            return (
              <Option key={index} value={item}>
                {item}
              </Option>
            );
          }
          return 0;
        })}
    </SelectWrapper>
  );

  const numEachArticleVideo = 9;
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(9);
  const [currentValue, setCurrentValue] = useState(1);

  const paginationHandleChange = (value) => {
    console.log("count", value);
    setCurrentValue(value);
    setMinValue((value - 1) * numEachArticleVideo);
    setMaxValue(value * numEachArticleVideo);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
    >
      <UserBanner />
      {/* <AboutHeader /> */}
      <Container style={{ marginTop: 80 }}>
        <TabsNav defaultActiveKey="1" tabBarExtraContent={operations}>
          <TabPane tab={<span>Articles</span>} key="1">
            <QueueAnim type="right" delay={300} className="queue-simple">
              <div key="a">
                {loading || getLibraryLoading ? (
                  <CardsContainer
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Spinwrapper size="large" />
                  </CardsContainer>
                ) : (
                  <CardsContainer>
                    {articleData.length === 0 ? (
                      <CardsContainer
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Empty align="center"></Empty>
                      </CardsContainer>
                    ) : (
                      <>
                        {articleData &&
                          articleData
                            ?.slice(minValue, maxValue)
                            .map((item, index) => {
                              return <ArticleCard key={index} data={item} />;
                            })}
                      </>
                    )}
                    <Row
                      style={{
                        margin: 25,
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <PaginationComp
                        defaultCurrent={currentValue}
                        current={currentValue}
                        total={articleData?.length}
                        defaultPageSize={numEachArticleVideo}
                        onChange={paginationHandleChange}
                      />
                    </Row>
                  </CardsContainer>
                )}
              </div>
            </QueueAnim>
          </TabPane>
          <TabPane tab={<span>Videos</span>} key="2">
            <QueueAnim type="left" delay={300} className="queue-simple">
              <div key="b">
                {loading || getLibraryLoading ? (
                  <CardsContainer
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Spinwrapper size="large" />
                  </CardsContainer>
                ) : (
                  <CardsContainer>
                    {videoData.length === 0 ? (
                      <CardsContainer
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Empty align="center"></Empty>
                      </CardsContainer>
                    ) : (
                      <>
                        {videoData
                          ?.slice(minValue, maxValue)
                          .map((item, index) => {
                            return <VideoCard1 key={index} data={item} />;
                          })}
                      </>
                    )}
                    <Row
                      style={{
                        margin: 25,
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <PaginationComp
                        defaultCurrent={currentValue}
                        current={currentValue}
                        total={articleData?.length}
                        defaultPageSize={numEachArticleVideo}
                        onChange={paginationHandleChange}
                      />
                    </Row>
                  </CardsContainer>
                )}
              </div>
            </QueueAnim>
          </TabPane>
        </TabsNav>
        {/* </div> */}
        {/* <AddArticleVideoModal addModal={addModal} setAddModal={setAddModal} /> */}
      </Container>
    </div>
  );
};

export default Library;
const Spinwrapper = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #fa8c16;
  }
`;
const CardsContainer = styled.div`
  padding: 20px 0px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-item: center;

  @media (max-width: 999px) {
    justify-content: center !important;
  }

  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
`;
const SelectWrapper = styled(Select)`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px !important;
    border: 1px solid #2e2d2d;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border: 1px solid #f6a017;
    box-shadow: none;
  }
`;

const TabsNav = styled(Tabs)`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab {
    padding: 12px 20px;
    font-size: 15px;
  }
  .ant-tabs-ink-bar {
    background: #efa718 !important;
    height: 3px !important;
  }
  .ant-tabs-nav-list {
    position: relative;
    left: 99px;
  }
  @media (max-width: 960px) {
    .ant-tabs-nav {
      display: grid;
    }
    .ant-tabs-nav-list {
      position: relative;
      left: 0px;
    }
  }
`;
