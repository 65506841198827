import React from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import {
  Form,
  Button,
  Select,
  Input,
  DatePicker,
  Tooltip,
  Row,
  Col,
} from "antd";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";
import moment from "moment";
import csc from "country-state-city";
import "./AboutInfo.css";
// import FormItem from 'antd/lib/form/FormItem';
import { getCookie } from "../../helpers";

const AboutInfo = (props) => {
  // console.log("Props About >>>>", props);
  // const cities = csc.getCitiesOfCountry(localStorage.getItem("countryCode"));
  const cities = csc.getCitiesOfCountry(getCookie("countryCode"));
  // console.log(cities)
  const currentDate = new Date();
  const date = moment(currentDate).format("Do MMM YYYY");

  function disabledDate(current) {
    return (
      // moment(current).day() === 0 || //sat
      // moment(current).day() === 6 || //sun
      current < moment().subtract(30, "years") || // before current == max age : 30 yrs
      // current > moment().add(2, "days") // after current
      !(current < moment().subtract(15, "years")) || // before current == min age : 15 yrs
      current > moment().add(0, "days") // after current
    );
  }

  const next = (e) => {
    props.nextStep();
  };

  const pkCitites = [
    "Karachi",
    "Lahore",
    "Faisalabad",
    "Rawalpindi",
    "Gujranwala",
    "Peshawar",
    "Multan",
    "Hyderabad",
    "Islamabad",
    "Quetta",
  ];

  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={0} className="queue-simple">
        <Form name="basic" key="a" onFinish={next}>
          <h4 style={{ textAlign: "center", margin: 20 }}>
            Let's start with the basic information
          </h4>
          <Col span={24}>
            <Form.Item
              initialValue={props.data && props.data.firstName}
              name="step_firstName"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid first name",
                  pattern: !/^[a-z][A-Z]*$/,
                },
              ]}
            >
              <Input
                maxLength={30}
                value={props.data && props.data.firstName}
                prefix={<UserOutlined style={{ marginRight: 5 }} />}
                placeholder="First Name*"
                onChange={(e) =>
                  props.setInfoState({
                    ...props.infoState,
                    firstName: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          {/* {(props.infoState.firstName &&
            props.isContainNumber(props.infoState.firstName) &&
            props.infoState?.firstName.length !== 0) ||
          props.infoState.firstName.length < 3 ? (
            // <span style={{ color: "#FE4D4D" }}>Please enter valid name</span>
            <p style={{ marginTop: "3px", color: "#f00" }}>
              Please enter valid name!
              <Tooltip title="Name should be at least 3 characters long and not have number">
                <InfoCircleOutlined style={{ marginLeft: "2px" }} />
              </Tooltip>
            </p>
          ) : (
            ""
          )} */}
          <Col span={24}>
            <Form.Item
              initialValue={props.infoState && props.infoState.lastName}
              name="step_lastName"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid last name",
                  pattern: !/^[a-z][A-Z]*$/,
                },
              ]}
            >
              <Input
                maxLength={30}
                value={props.data && props.data.lastName}
                type="text"
                prefix={<UserOutlined style={{ marginRight: 5 }} />}
                placeholder="Last Name*"
                onChange={(e) =>
                  props.setInfoState({
                    ...props.infoState,
                    lastName: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          {/* {(props.infoState.lastName &&
            props.isContainNumber(props.infoState.lastName)) ||
          props.infoState.lastName.length < 3 ? (
            // <span style={{ color: "#FE4D4D" }}>Please enter valid name</span>
            <p style={{ marginTop: "3px", color: "#f00" }}>
              Please enter valid name!
              <Tooltip title="Name should be at least 3 characters long and not have number">
                <InfoCircleOutlined style={{ marginLeft: "2px" }} />
              </Tooltip>
            </p>
          ) : (
            ""
          )} */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
              <Form.Item
                initialValue={props.data && props.data.city}
                name="step_city"
                rules={[{ required: true, message: "Please select the City!" }]}
              >
                <Select
                  showSearch
                  value={props.data && props.data.city}
                  onChange={(value) =>
                    props.setInfoState({ ...props.infoState, city: value })
                  }
                  placeholder="Which city are you applying for?*"
                  style={{ width: "100%", borderLeft: "none" }}
                >
                  {pkCitites.map((city) => (
                    <Select.Option value={city}>{city}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xxl={6} xl={6} md={8} sm={24} xs={24}>
              <Form.Item
                initialValue={props.infoState.gender}
                name="step_gender"
                rules={[
                  { required: true, message: "Please select the Gender!" },
                ]}
              >
                <Select
                  value={props.infoState.gender}
                  style={{ width: "100%" }}
                  placeholder="Gender"
                  onChange={(value) =>
                    props.setInfoState({ ...props.infoState, gender: value })
                  }
                >
                  <Select.Option value="male">Male</Select.Option>
                  <Select.Option value="female">Female</Select.Option>
                  <Select.Option value="other">Other</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </div>
          <Col span={24}>
            <Form.Item
              initialValue={props.infoState.birthdayDate}
              // valuePropName=''
              name="step_date"
              rules={[
                {
                  required: true,
                  message:
                    "Please make sure that you enter your correct date of birth!",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                defaultPickerValue={moment().subtract(15, "years")}
                // defaultValue={moment().subtract(15, "years")}
                disabledDate={(date) => disabledDate(date)}
                onChange={(value) =>
                  props.setInfoState({
                    ...props.infoState,
                    birthdayDate: value,
                  })
                }
                placeholder="Birth Date"
              />
            </Form.Item>
          </Col>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button size="large" htmlType="submit" type="primary">
              {" "}
              Next
            </Button>
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default AboutInfo;
