import React, { useState, useEffect } from "react";
import { Card, Button, Divider, Modal, Result } from "antd";
import styled from "styled-components";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";

const ShowResultAfterTest = (props) => {
  // console.log(props.data, "props in show result ");
  const { status, week, percent, obtMarks, result, totalMarks } = props.data;
  return (
    <>
      {status == "pass" ? (
        <ResultWrapper
          icon={<SmileOutlined />}
          status="success"
          title={`GREAT!`}
          subTitle="You have successfully passed the test."
          extra={[
            <ResultDetailWrapper>
              <h4 align="left">Result Detail:</h4>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Total Marks:</h6> <h5>{totalMarks}</h5>
              </div>
              <Divider style={{ margin: "8px 0" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Obtain Marks:</h6> <h5>{obtMarks}</h5>
              </div>
              <Divider style={{ margin: "8px 0" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Percentage:</h6>{" "}
                <h5>{`${parseFloat(percent).toFixed(1)} %`}</h5>
              </div>
              <Divider style={{ margin: "8px 0" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Status:</h6> <h5>{status.toUpperCase()}</h5>
              </div>
            </ResultDetailWrapper>,
          ]}
        />
      ) : (
        <ResultWrapper
          icon={<FrownOutlined />}
          status="error"
          title="UH OH!"
          subTitle="You have failed the test."
          extra={[
            <ResultDetailWrapper>
              <h4 align="left">Result Detail:</h4>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Total Marks:</h6> <h5>{totalMarks}</h5>
              </div>
              <Divider style={{ margin: "8px 0" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Obtain Marks:</h6> <h5>{obtMarks}</h5>
              </div>
              <Divider style={{ margin: "8px 0" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Percentage:</h6>{" "}
                <h5>{`${parseFloat(percent).toFixed(1)} %`}</h5>
              </div>
              <Divider style={{ margin: "8px 0" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <h6> Status:</h6> <h5>{status.toUpperCase()}</h5>
              </div>
            </ResultDetailWrapper>,
          ]}
        />
      )}
    </>
  );
};

export default ShowResultAfterTest;

const ResultDetailWrapper = styled.div``;
const ResultWrapper = styled(Result)`
  .ant-result {
    padding: 10px 32px;
  }
  .ant-result-extra {
    text-align: left !important;
  }
`;
