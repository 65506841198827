import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";
import ComunicationLeadership from "../../src/assets/ComunicationLeadership.jpg";
import BuildingCreative from "../../src/assets/BuildingCreative.jpg";
import ProfessionalNetwork from "../../src/assets/ProfessionalNetwork.jpg";
import OnlineStudentPortal from "../../src/assets/OnlineStudentPortal.jpg";
import CorporatePlacement from "../../src/assets/CorporatePlacement.jpg";

const Cards = () => {
  return (
    <div className="cards" id="about">
      <div className="cards-text-container">
        <h1 className="card-section-heading">
          Are you ready for a fun filled, life-changing training program? Then
          get enrolled into our Youth Empowerment Boot Camp!
        </h1>
        <p className="card-section-paragraph">
          Emerge Youth Empowerment Boot camp is a 5 day program that is designed
          to be a fun and engaging experience for students with several learning
          takeaways.
          <br />
          This unique learning expedition is helpful in both personal and
          professional growth . Each session includes training, group
          discussions, individual & group exercises, followed by mentoring and
          support.
          <br />
          This boot camp is designed and launched by <b>EmergeForward</b>, in
          relation to its vision and aim of bridging the gap between different
          knowledge verticals and social stratification.
        </p>
      </div>
      <h1 className="card-section-sub-heading">
        Key takeaways of Emerge Youth Boot Camp
      </h1>
      <div className="cards__container">
        <div className="cards___wrapper">
          <ul className="cards__items">
            <CardItem
              src={ComunicationLeadership}
              text={`Communication & Leadership`}
              label="Adventure"
              path="/services"
            />
            <CardItem
              src={BuildingCreative}
              text={`Building a Creative Mindset`}
              label="Luxury"
              path="/services"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={ProfessionalNetwork}
              text={`Professional Network`}
              label="Mystery"
              path="/services"
            />
            <CardItem
              src={OnlineStudentPortal}
              text={`Online Students Portal`}
              label="Adventure"
              path="/products"
            />
            <CardItem
              src={CorporatePlacement}
              text={"Corporate placement "}
              label="Adrenaline"
              path="/sign-up"
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Cards;
