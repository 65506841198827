import React, { useState, useEffect } from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import QuestionOne from "../applicationQuestions/QuestionOne";
import QuestionTwo from "../applicationQuestions/QuestionTwo";
import QuestionThree from "../applicationQuestions/QuestionThree";
import QuestionFour from "../applicationQuestions/QuestionFour";
import QuestionFive from "../applicationQuestions/QuestionFive";
import "./StudentApplicationFormContainer.css";

const StudentApplicationFormContainer = (props) => {
  // console.log("Props Data >>>", props.data);
  const [applicationState, setApplicationState] = useState({
    questionOne: "",
    questionTwo: "",
    questionThree: "",
    questionFour: "",
    questionFive: "",
  });

  // console.log("applicationState", applicationState);

  const isSubmittable = () => {
    return (
      applicationState.questionOne !== "" &&
      applicationState.questionOne?.length > 100 &&
      applicationState.questionTwo !== "" &&
      applicationState.questionTwo?.length > 100 &&
      applicationState.questionThree !== "" &&
      applicationState.questionThree?.length > 100 &&
      applicationState.questionFour !== "" &&
      applicationState.questionFour?.length > 100 &&
      applicationState.questionFive !== "" &&
      applicationState.questionFive?.length > 100
    );
  };

  switch (props.step) {
    case 1:
      return (
        <QuestionOne
          data={props.data && props.data[0]}
          nextStep={props.nextStep}
          applicationState={applicationState}
          setApplicationState={setApplicationState}
        />
      );
    case 2:
      return (
        <QuestionTwo
          data={props.data && props.data[1]}
          nextStep={props.nextStep}
          prevStep={props.prevStep}
          applicationState={applicationState}
          setApplicationState={setApplicationState}
        />
      );
    case 3:
      return (
        <QuestionThree
          data={props.data && props.data[2]}
          nextStep={props.nextStep}
          prevStep={props.prevStep}
          applicationState={applicationState}
          setApplicationState={setApplicationState}
        />
      );
    case 4:
      return (
        <QuestionFour
          data={props.data && props.data[3]}
          nextStep={props.nextStep}
          prevStep={props.prevStep}
          applicationState={applicationState}
          setApplicationState={setApplicationState}
        />
      );
    default:
      return (
        <QuestionFive
          data={props.data && props.data[4]}
          applicationState={applicationState}
          isSubmittable={isSubmittable}
          setApplicationState={setApplicationState}
          // handleSubmit={handleSubmit}
          // loading={loading}
          prevStep={props.prevStep}
        />
      );
  }
};

export default StudentApplicationFormContainer;
