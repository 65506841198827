import React from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Form, Button, Col, Row, Checkbox, Input, Tooltip } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";

const Styledh6 = styled.h6`
  @media (min-width: 793px) {
    margin-bottom: 28px;
  }
`;

const ParentsInfo = (props) => {
  const previous = (e) => {
    props.prevStep();
  };

  const next = (e) => {
    props.nextStep();
  };

  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={0} className="queue-simple">
        <Form key="a" onFinish={next}>
          <h4 style={{ textAlign: "center", margin: 20 }}>
            In case we need to contact your guardians
          </h4>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "47%" }}>
              <h6 style={{ textAlign: "center", marginBottom: 28 }}>
                Primary Guardian(s) details
              </h6>
              <Form.Item
                initialValue={props.infoState.priGuardianFirstName}
                name="priGuardianFirstName"
                rules={[
                  {
                    min: 3,
                    max: 16,
                    required: true,
                    // type: "string",
                    pattern: /^[A-Za-z]+$/,
                    message: "Please enter a valid Guardian's First Name!",
                  },
                ]}
              >
                <Input
                  maxLength={16}
                  minLength={3}
                  value={props.infoState.priGuardianFirstName}
                  prefix={<UserOutlined style={{ marginRight: 5 }} />}
                  placeholder="Guardians First Name*"
                  onChange={(e) =>
                    props.setInfoState({
                      ...props.infoState,
                      priGuardianFirstName: e.target.value,
                    })
                  }
                />
              </Form.Item>
              {/* {(props.infoState.priGuardianFirstName &&
                props.isContainNumber(props.infoState.priGuardianFirstName)) ||
              (props.infoState.priGuardianFirstName !== undefined &&
                props.infoState.priGuardianFirstName.length < 3) ? (
                // <span style={{ color: "#FE4D4D" }}>
                //   Please enter valid name
                // </span>
                <p style={{ marginTop: "3px", color: "#f00" }}>
                  Please enter valid name!
                  <Tooltip title="Name should be at least 3 characters long and not have number">
                    <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                  </Tooltip>
                </p>
              ) : (
                ""
              )} */}

              <Form.Item
                initialValue={props.infoState.priGuardianLastName}
                name="priGuardianLastName"
                rules={[
                  {
                    min: 3,
                    max: 16,
                    required: true,
                    // type: "string",
                    pattern: /^[A-Za-z]+$/,
                    message: "Please enter a valid Guardian's Last Name!",
                  },
                ]}
              >
                <Input
                  minLength={3}
                  maxLength={16}
                  value={props.infoState.priGuardianLastName}
                  prefix={<UserOutlined style={{ marginRight: 5 }} />}
                  placeholder="Guardians Last Name*"
                  onChange={(e) =>
                    props.setInfoState({
                      ...props.infoState,
                      priGuardianLastName: e.target.value,
                    })
                  }
                />
              </Form.Item>
              {/* {(props.infoState.priGuardianLastName &&
                props.isContainNumber(props.infoState.priGuardianLastName)) ||
              (props.infoState.priGuardianLastName !== undefined &&
                props.infoState.priGuardianLastName.length < 3) ? (
                // <span style={{ color: "#FE4D4D" }}>
                //   Please enter valid name
                // </span>
                <p style={{ marginTop: "3px", color: "#f00" }}>
                  Please enter valid name!
                  <Tooltip title="Name should be at least 3 characters long and not have number">
                    <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                  </Tooltip>
                </p>
              ) : (
                ""
              )} */}
              <Form.Item
                initialValue={props.infoState.priGuardianEmail}
                name="priGuardianEmail"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please enter your Guardians Email!",
                  },
                ]}
              >
                <Input
                  type="email"
                  maxLength={50}
                  value={props.infoState.priGuardianEmail}
                  prefix={<MailOutlined style={{ marginRight: 5 }} />}
                  placeholder="Guardians Email*"
                  onChange={(e) =>
                    props.setInfoState({
                      ...props.infoState,
                      priGuardianEmail: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>

            <div style={{ width: "47%" }}>
              <Styledh6 style={{ textAlign: "center" }}>
                Secondary Guardian(s) details{" "}
                <span style={{ fontSize: 12 }}>(if applicable)</span>
              </Styledh6>
              <Form.Item
                name="secGuardianFirstName"
                rules={[
                  {
                    min: 3,
                    max: 16,
                    required: false,
                    // type: "string",
                    pattern: !/^[a-z][A-Z]*$/,
                    message: "Please enter a valid guardian's first name!",
                  },
                ]}
                initialValue={props.infoState.secGuardianFirstName}
              >
                <Input
                  maxLength={16}
                  style={{ width: "100%" }}
                  value={props.infoState.secGuardianFirstName}
                  prefix={<UserOutlined style={{ marginRight: 5 }} />}
                  placeholder="Guardians First Name"
                  onChange={(e) =>
                    props.setInfoState({
                      ...props.infoState,
                      secGuardianFirstName: e.target.value,
                    })
                  }
                />
              </Form.Item>

              {/* {props.infoState.secGuardianFirstName &&
              props.isContainNumber(props.infoState.secGuardianFirstName) ? (
                // <span style={{ color: "#FE4D4D" }}>
                //   Please enter valid name
                // </span>
                <p style={{ marginTop: "3px", color: "#f00" }}>
                  Please enter valid name!
                  <Tooltip title="Name should not have number">
                    <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                  </Tooltip>
                </p>
              ) : (
                ""
              )} */}

              <Form.Item
                name="secGuardianLastName"
                rules={[
                  {
                    min: 3,
                    max: 16,
                    required: false,
                    // type: "string",
                    pattern: !/^[a-z][A-Z]*$/,
                    message: "Please enter a valid guardian's last name!",
                  },
                ]}
                initialValue={props.infoState.secGuardianLastName}
              >
                <Input
                  maxLength={16}
                  style={{ width: "100%" }}
                  value={props.infoState.secGuardianLastName}
                  prefix={<UserOutlined style={{ marginRight: 5 }} />}
                  placeholder="Guardians Last Name"
                  onChange={(e) =>
                    props.setInfoState({
                      ...props.infoState,
                      secGuardianLastName: e.target.value,
                    })
                  }
                />
              </Form.Item>
              {/* {props.infoState.secGuardianLastName &&
              props.isContainNumber(props.infoState.secGuardianLastName) ? (
                // <span style={{ color: "#FE4D4D" }}>
                //   Please enter valid name
                // </span>
                <p style={{ marginTop: "3px", color: "#f00" }}>
                  Please enter valid name!
                  <Tooltip title="Name should not have number">
                    <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                  </Tooltip>
                </p>
              ) : (
                ""
              )} */}
              <Form.Item
                name="secGuardianEmail"
                rules={[
                  {
                    type: "email",
                    required: false,
                    message: "Please enter your secondary guardian's email",
                  },
                ]}
                initialValue={props.infoState.secGuardianEmail}
              >
                <Input
                  maxLength={50}
                  type="email"
                  style={{ width: "100%" }}
                  value={props.infoState.secGuardianEmail}
                  prefix={<MailOutlined style={{ marginRight: 5 }} />}
                  placeholder="Guardians Email"
                  onChange={(e) =>
                    props.setInfoState({
                      ...props.infoState,
                      secGuardianEmail: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              size="large"
              onClick={previous}
              style={{ backgroundColor: "lightgray" }}
            >
              {" "}
              Previous
            </Button>
            <Button size="large" htmlType="submit" type="primary">
              Next{" "}
            </Button>
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default ParentsInfo;
