import React, { useEffect, useState } from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import {
  Form,
  Button,
  Col,
  Row,
  Select,
  Input,
  Tooltip,
  Skeleton,
  message,
} from "antd";
import QueueAnim from "rc-queue-anim";
import { client } from "../../appollo_client/client";
import Mutations from "../../appollo_client/Mutations";
import { useMutation } from "@apollo/client";
import { InfoCircleOutlined } from "@ant-design/icons";
const { TextArea } = Input;

const QuestionOne = ({
  data,
  nextStep,
  applicationState,
  setApplicationState,
}) => {
  const [submitApplicationAnswers, { loading, error }] = useMutation(
    Mutations.SUBMIT_APPLICATION_ANSWERS,
    { client: client }
  );

  const next = async (e) => {
    try {
      const res = await submitApplicationAnswers({
        variables: {
          answer: applicationState.questionOne,
          questionId: data && data._id,
        },
      });
      // console.log(res, "response in application submit");
      nextStep();
    } catch (error) {
      console.log(error, "Application submit error");
    }
  };

  var myString = "string test";
  const stringLength = myString.length;

  const handleChange = (e) => {
    // const array = [];
    // const errey = array.push(e);
    // console.log(errey);

    // console.log(e.length);

    return setApplicationState({
      // ...props.applicationState,
      questionOne: e.target.value,
    });
  };

  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={300} className="queue-simple">
        <Form key="a" onFinish={next}>
          <h5 style={{ margin: "20px 0" }}>{data && data.question}</h5>
          <h6 style={{ margin: "20px 0", color: "gray" }}>
            {data && data.discription}
          </h6>

          <Form.Item
            initialValue={applicationState.questionOne}
            name="Answer"
            rules={[
              {
                required: true,
                min: 100,
                max: 350,
                message: "Answer must be between 100 and 350 characters",
              },
            ]}
          >
            <TextArea
              showCount
              rows={5}
              style={{ minHeight: 100, maxHeight: 100 }}
              value={applicationState.questionOne}
              name="name"
              type="text"
              placeholder="Tesla, because I love the technology they are about."
              onChange={handleChange}
              // onChange={(e) =>
              //   props.setApplicationState({
              //     ...props.applicationState,
              //     questionOne: e.target.value,
              //   })
              // }
              maxLength={350}
            />
          </Form.Item>
          {/* {props.applicationState.questionOne.length !== 0 ? (
            <p style={{ color: "#f00" }}>
              Answer must be between 10 and 350 characters*
            </p>
          ) : (
            <></>
          )} */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              loading={loading}
            >
              {" "}
              Next
            </Button>
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default QuestionOne;
